/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Card from 'src/components/Card';
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";

import NetServices from 'src/utils/netservices';

import { IAskAboutKid } from 'src/models/smarttypes';
import LoadingWaitSpace from 'src/components/LoadingWaitSpace';
import moment from 'moment';

const languages = getLanguages();
const appFxs = getFxs();

export default function ContactMessageAskPreview() {
  document.title = `${languages.previewMessage} | ${languages.management} - ${languages.schoolName}`;
  const [isLoading, setLoading] = useState(true);
  const [contactMessage, setContactMessage] = useState<IAskAboutKid | null>(
    null
  );

  const bLoaded = useRef(false);

  const navigate = useNavigate();
  const { messageKey } = useParams();

  const loadMessage = async () => {
    setLoading(true);

    const oper = await NetServices.requestGet(
      `managt/getoneaskkidsmessage/${messageKey}`
    );
    if (!oper.bReturn) {
      await appFxs.showAlert(oper.msgTitle, oper.msgBody);
      return;
    }

    setContactMessage(oper.askKidsMessage!);

    setLoading(false);
  };

  useEffect(() => {
    if (bLoaded.current) return;
    loadMessage();
    bLoaded.current = true;
  }, []);

  return (
    <div className="flex flex-col gap-3">
      <Card>
        <div className="flex items-center">
          <div className="grow">
            <div className="flex">
              <h3 className="flex-1 text-lg font-bold text-gray-800">
                {languages.previewMessage}
              </h3>
              <div className="flex gap-1">
                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={() => navigate("/app/main/contactmessages")}
                  title={languages.goback}
                >
                  <i className="fa fa-list"></i>
                </button>
                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={() => loadMessage()}
                  title={languages.refresh}
                >
                  <i className="fa fa-history"></i>
                </button>
              </div>
            </div>
            <div className="mt-1 text-zinc-700 dark:text-gray-400">
              {languages.askKidsMessageMsg}
            </div>
          </div>
          <div className="grow-0 flex items-center gap-2"></div>
        </div>
      </Card>

      <Card>
        <div className="flex flex-col">
          {isLoading && <LoadingWaitSpace height={150} />}
          {!isLoading && contactMessage && (
            <MessageDisplayer message={contactMessage!} />
          )}
        </div>
      </Card>
    </div>
  );
}


const MessageDisplayer = ({message}:{message: IAskAboutKid}) => {
    const sender = `${message.userName}`;

    return (
      <div className='flex w-full flex-wrap'>
        <div className='flex items-center gap-2 mb-2 basis-full md:basis-[49%] '>
          <span className='font-semibold'>{languages.sender}:</span>
          <span>{sender}</span>
        </div>
  
        <div className='flex items-center gap-2 mb-2 basis-full md:basis-[49%]'>
          <span className='font-semibold'>{languages.date}:</span>
          <span>{moment(message.messageDate).format('DD/MM/YYYY HH:mm')}</span>
        </div>
  
          
      <div className='flex items-center gap-2 mb-2 basis-full md:basis-[49%]'>
        <span className='font-semibold'>{languages.email}:</span>
        <a className='text-sky-600 hover:text-sky-900' href={`mailto:${message.emailAddress}`}>
            {message.emailAddress}
          </a>
      </div>
  
      <div className='flex items-center gap-2 mb-2 basis-full md:basis-[49%]'>
        <span className='font-semibold'>{languages.phoneNbr}:</span>
        <span>
          <a className='text-sky-600 hover:text-sky-900' href={`tel:${message.telNumber}`}>
            {message.telNumber}
          </a>
        </span>
      </div>
  
      {/* <div className='flex items-center gap-2 mb-2 basis-full md:basis-[49%]'>
        <span className='font-semibold'>{languages.lookingFor}:</span>
        <span>{message.studies}</span>
      </div> */}
  
      <div className='mt-2 basis-full'>
        {message.message}
      </div>
  
  
      </div>
    );
  }