//const pathUrl = 'http://localhost:7373';

const pathUrl = 'https://api.lacolombiere.ac.rw';

const appDeclarations = {
    connectedUser: 'connected_____use235',
    currentLang: 'current_language',
    connectionStatus: 'connect_status',
    apiRootTest: `${pathUrl}/api/`,
    apiUrlTest: pathUrl,
    apiPath: pathUrl,

    userAccessKey: 'user_access_key',

    buyerAccessKey: 'buyer___access___key',

    company_code: 'WBTISC8885-LOLWC',
    currentYearbook: 'current_year_book',


    currentDefaultSmsAcct: 'current_def_sms_acct',
    itemsPerPage: 'items_per_page',
    currentDevise: 'current_devise',
    currentBranch: 'current_branch',
    userEmail: 'user_email',
    userPassword: 'userPassword',
    cartZoneArea: 'cart___zone_area____2363',
    mainPhoneNbr: '250 788 510 589',
    waitingEmailForPw: 'awaiting_____email_for_pw____x25'
}
export default appDeclarations;