import React from 'react';

import { Routes, Route } from "react-router-dom";
import { HomePage } from './pages/HomePage';
import AcctLoginPage from './pages/account/AcctLoginPage';
import AcctSetNewPwPage from './pages/account/AcctSetNewPwPage';
import AcctForgotPage from './pages/account/AcctForgotPage';
import AcctWaitForReset from './pages/account/AcctWaitForReset';
import AcctResetPage from './pages/account/AcctResetPage';
import AcctNewPassword from './pages/account/AcctNewPassword';
import AccountLayout from './pages/layouts/AccountLayout';
import AppLayout from './pages/layouts/AppLayout';
import LoadingApp from './pages/app/LoadingApp';
import AppMainLayout from './pages/layouts/AppMainLayout';
import DashboardPage from './pages/app/DashboardPage';
import MyProfilePage from './pages/app/MyProfilePage';
import AppSettings from './pages/app/AppSettings';
import UsersPage from './pages/app/UsersPage';
import AcctJoinTeamPage from './pages/account/AcctJoinTeamPage';
import ListBlogPage from './pages/app/ListBlogPage';
import ListAlumniMembers from './pages/app/ListAlumniMembers';
import AlumniDetailPage from './pages/app/AlumniDetailPage';
import MailingListPage from './pages/app/MailingListPage';
import MailingListNewPage from './pages/app/MailingListNewPage';
import MailingListPreviewPage from './pages/app/MailingListPreviewPage';
import ContactMessagePage from './pages/app/ContactMessagePage';
import ContactMessagePreviewPage from './pages/app/ContactMessagePreviewPage';
import ListProductsPage from './pages/app/ListProductsPage';
import ProductsCreateNew from './pages/app/ProductsCreateNew';
import ProductPreviewPage from './pages/app/ProductPreview';
import ShopOpersPage from './pages/app/ShopOpersPage';
import ContactMessageAskPreview from './pages/app/ContactMessageAskPreview';


function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/account" element={<AccountLayout />}>        
        <Route index element={<AcctLoginPage />} />
        <Route path="login" element={<AcctLoginPage />} />
        <Route path="setnewpw/:setkey" element={<AcctSetNewPwPage />} />

        <Route path="forgot" element={<AcctForgotPage />} />
        <Route path="wait" element={<AcctWaitForReset />} />
        <Route path="reset/:resetKey" element={<AcctResetPage />} />
        <Route path="newpw" element={<AcctNewPassword />} />
        <Route path="jointeam/:joinKey" element={<AcctJoinTeamPage />} />
      </Route>
      <Route path="/app" element={<AppLayout />}>
        <Route index element={<LoadingApp />} />
        <Route path="/app/main" element={<AppMainLayout />}>
          <Route index element={<DashboardPage />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="blog" element={<ListBlogPage />} />
          <Route path="alumni" element={<ListAlumniMembers />} />
          <Route path="alumni/:encodedEmail" element={<AlumniDetailPage />} />
          <Route path="mailinglist" element={<MailingListPage />} />
          <Route path="mailinglist/new" element={<MailingListNewPage />} />
          <Route path="mailinglist/preview/:mailKey" element={<MailingListPreviewPage />} />
          <Route path="contactmessages" element={<ContactMessagePage />} />
          <Route path="contactmessages/preview/:messageKey" element={<ContactMessagePreviewPage />} />
          <Route path="contactmessages/askpreview/:messageKey" element={<ContactMessageAskPreview />} />
          <Route path="products" element={<ListProductsPage />} />
          <Route path="products/newproduct" element={<ProductsCreateNew />} />
          <Route path="products/preview/:productKey" element={<ProductPreviewPage />} />
          <Route path="shopopers" element={<ShopOpersPage />} />

          
          <Route path="myprofile" element={<MyProfilePage />} />
          <Route path="appsettings" element={<AppSettings />} />
          <Route path="users" element={<UsersPage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
