/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import appLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import NetServices from 'src/utils/netservices';
import { LinkAchorRound } from 'src/components/linkanchorround/LinkAchorRound';
import appDeclarations from 'src/utils/declarations';

const languages = appLanguages();
const appFxs = generalFxs();

export default function LoadingApp() {
  document.title = `${languages.loadingData} | ${languages.management} | ${languages.schoolName}`;

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('Hello');

  const navigate = useNavigate();

  const checkActivityAndLoad = async () => {
    const oRes = await NetServices.requestGet('managt/loadingdataforapp');

    if(!oRes.bReturn){
      setErrorMessage(oRes.msgBody);
      setIsLoading(false);
      return;
    }

    const conntectedUser = oRes.conntectedUser!;
    appFxs.setLocalStorageByKey(appDeclarations.connectedUser, JSON.stringify(conntectedUser));

    navigate('/app/main/dashboard');
  }
  const goTohome = () => {
    appFxs.setLocalStorageByKey(appDeclarations.connectionStatus, '0');
    appFxs.setLocalStorageByKey(appDeclarations.userAccessKey, '');
    navigate('/');
  }

  useEffect(() => {
    checkActivityAndLoad();
  }, []);
  
  if(!isLoading){
    return (
      <div className='h-screen flex items-center justify-center'>
        <div className='max-w-full w-[650px] mx-auto border border-solid border-[#b1f168] text-center rounded-lg p-4'>
          <span className='inline-block px-3'>
            {errorMessage}
          </span>
          <div className='mt-4'>
            <LinkAchorRound 
              type='button' 
              text={languages.home}
              borderRadius={8}
              onClick={goTohome} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='h-screen flex items-center justify-center'>
      <div className='flex flex-col items-center'>
        <i className='fa fa-spinner fa-spin fa-3x'></i>
        <h3 className='font-semibold text-xl mt-3'>{languages.loadingData}</h3>
      </div>
    </div>
  );
}
