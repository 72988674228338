/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';



import Card from 'src/components/Card';
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";

import { IAlumniUser} from 'src/models/smarttypes';
import NetServices from 'src/utils/netservices';
import moment from 'moment';
import { Link } from 'react-router-dom';

const languages = getLanguages();
const appFxs = getFxs();



export default function ListAlumniMembers() {
  document.title = `${languages.alumni} | ${languages.management} - ${languages.schoolName}`;

  const [isLoading, setLoading] = useState(true);
  const [lstAlumniMembers, setLstAlumniMembers] = useState<IAlumniUser[]>([]);
  const [messageDisplay, setMessageDisplay] = useState('');
  const [hastNetError, setHastNetError] = useState(false);

  const bLoaded = useRef(false);

  const onActivateAcct = async (e: IAlumniUser, iStatus: number) => {
    const bAsk = await appFxs.showConfirm(
      iStatus === 0 ? languages.deactivate : languages.activate,
      iStatus === 0
        ? languages.deActivateAlumniMember
        : languages.activateAlumniMember
    );
    if (!bAsk.isConfirmed) return;

    const oper = await NetServices.requestPost('managt/setactivealumnimember', {
        encryptedEmail: window.btoa(e.emailAddress),
        operationStatus: iStatus,
    }, true);

    if(!oper.bReturn){
        appFxs.showAlert(oper.msgTitle, oper.msgBody);
        return;
    }

    setLstAlumniMembers(oper.lstAlumniUsers!);
  }

  const refreshListAlumniMembers = async (bAjax?: boolean) => {
    setLoading(true);
    setMessageDisplay('')
    setHastNetError(false);

    const oper = await NetServices.requestGet('managt/getlistalmembers', bAjax);
    if(!oper.bReturn){
        setMessageDisplay(oper.msgBody);
        setHastNetError(true);
        setLoading(false);
        return;
    }


    setLstAlumniMembers(oper.lstAlumniUsers!)
    setMessageDisplay('');
    setHastNetError(false);
    setLoading(false);
  };

  useEffect(() => {
    if(bLoaded.current)return;
    refreshListAlumniMembers();
    bLoaded.current = true;
  }, []);

  return (
    <>
      <div className="flex flex-col gap-3">
        <Card>
          <div className="flex items-center">
            <div className="grow">
              <div className="flex">
                <h3 className="flex-1 text-lg font-bold text-gray-800">
                  {languages.alumniMembers}
                </h3>
                <div className="flex gap-1">
                  <button
                    type="button"
                    className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                    onClick={() => refreshListAlumniMembers()}
                  >
                    <i className="fa fa-history"></i>
                  </button>
                </div>
              </div>
              <div className="mt-1 text-zinc-700 dark:text-gray-400">
                {languages.alumniMembersMsg}
              </div>
            </div>
            <div className="grow-0 flex items-center gap-2"></div>
          </div>
        </Card>

        {/* details */}
        <Card>
          <div className="flex flex-col space-y-2">
            {/* table */}
            <table className="table table-zebra">
              <thead>
                <tr>
                  <th>{languages.name}</th>
                  <th className="hidden md:table-cell">{languages.dob}</th>
                  <th className="hidden lg:table-cell">{languages.email}</th>
                  <th className="hidden lg:table-cell">{languages.phoneNbr}</th>
                  <th className="hidden lg:table-cell">{languages.status}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {/* Loading */}
                {isLoading && (
                  <tr>
                    <td colSpan={6}>
                      <div className="h-auto py-2 flex flex-col justify-center items-center space-y-2">
                        <span className="loading loading-spinner loading-lg"></span>
                        <h3 className="font-semibold text-lg">
                          {languages.loading}
                        </h3>
                      </div>
                    </td>
                  </tr>
                )}

                {/* No Record */}
                {!isLoading && lstAlumniMembers.length === 0 && (
                  <tr>
                    <td colSpan={5}>
                      <div className="h-auto py-2 flex flex-col justify-center items-center space-y-2">
                        <h3>{messageDisplay}</h3>
                        {!hastNetError && (
                          <button
                            type="button"
                            className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                            onClick={() => refreshListAlumniMembers()}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                        )}

                        {hastNetError && (
                          <button
                            type="button"
                            className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                            onClick={() => refreshListAlumniMembers(true)}
                          >
                            <i className="fa fa-history"></i>
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                )}

                {!isLoading &&
                  !hastNetError &&
                  lstAlumniMembers.length > 0 &&
                  lstAlumniMembers.map((oUser, idx) => (
                    <AlumniRowComp
                      alUser={oUser}
                      key={idx}
                      onActivate={(o, i) => onActivateAcct(o, i)}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </Card>
      </div>
    </>
  );
}

const AlumniRowComp = ({alUser, onActivate}:{
    alUser: IAlumniUser,
    onActivate: (e: IAlumniUser, iState: number) => void,
}) => {
    const emailEncrypted = window.btoa(alUser.emailAddress)
    return (
      <tr>
        {/* nmae */}
        <td>
          <div className="flex flex-col gap-1">
            <Link className='text-blue-500 hover:text-blue-700' to={`/app/main/alumni/${emailEncrypted}`}>
              {`${alUser.firstName} ${alUser.lastName}`}
            </Link>





            <span className="sm:hidden text-slate-600">
              <strong className="flex items-center gap-1">
                <i className="fa fa-calendar"></i>
                {moment(alUser.dateOfBirth).format("DD/MM/YY")}
              </strong>
            </span>
            <span className="md:hidden text-sm text-slate-600">
              <strong className="flex items-center gap-1">
                <i className="fa fa-envelope"></i>
                <a
                  className="text-blue-600 hover:text-blue-900"
                  href={`mailto:${alUser.emailAddress}`}
                >
                  {alUser.emailAddress}
                </a>
              </strong>
            </span>
            <span className="lg:hidden text-sm text-slate-600">
              <strong className="flex items-center gap-1">
                <i className="fa fa-phone"></i>
                <a
                  className="text-blue-600 hover:text-blue-900"
                  href={`tel:${alUser.phoneNbr}`}
                >
                  {alUser.phoneNbr}
                </a>
              </strong>
            </span>
          </div>
        </td>

        {/* Date */}
        <td className="hidden sm:table-cell">
          {moment(alUser.dateOfBirth).format("DD/MM/YY")}
        </td>

        {/* email */}
        <td className="hidden md:table-cell">
          <a
            className="text-blue-600 hover:text-blue-900"
            href={`mailto:${alUser.emailAddress}`}
          >
            {alUser.emailAddress}
          </a>
        </td>

        {/* phone */}
        <td className="hidden lg:table-cell">
          <a
            className="text-blue-600 hover:text-blue-900"
            href={`tel:${alUser.phoneNbr}`}
          >
            {alUser.phoneNbr}
          </a>
        </td>

        {/* status */}
        <td className="hidden lg:table-cell">
          {alUser.userActive && (
            <div className="badge badge-accent">{languages.active}</div>
          )}
          {!alUser.userActive && (
            <div className="badge badge-neutral">{languages.inactive}</div>
          )}
        </td>
        <td>
          <div className="flex items-center gap-1 justify-end">
            <Link
              className="border border-solid border-sky-500 rounded-md py-1 px-2 inline-block"
              title={languages.preview}
              to={`/app/main/alumni/${emailEncrypted}`}
            >
              <i className="fa fa-eye text-sky-500"></i>
            </Link>

            {alUser.userActive && (
              <button
                className="border border-red-500 rounded-md py-1 px-2"
                onClick={() => onActivate(alUser, 0)}
                title={languages.deactivate}
              >
                <i className="fa fa-arrow-down text-red-500"></i>
              </button>
            )}

            {!alUser.userActive && (
              <button
                className="border border-green-500 rounded-md py-1 px-2"
                onClick={() => onActivate(alUser, 1)}
                title={languages.activate}
              >
                <i className="fa fa-arrow-up text-green-500"></i>
              </button>
            )}
          </div>
        </td>
      </tr>
    );
}