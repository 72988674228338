/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';


import Card from 'src/components/Card';
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";

import { IAlumniUser, IMailSubscription, IMailingList, IManagtUser} from 'src/models/smarttypes';
import NetServices from 'src/utils/netservices';

import {ReactComponent as UserSvg} from 'src/assets/images/svg/users.svg';
import {ReactComponent as MailSvg} from 'src/assets/images/svg/mail.svg';
import { ErrorTableRow, LoadingTableRow, NoRecordTableRow } from 'src/components/majorsmallcomps';

const languages = getLanguages();
const appFxs = getFxs();



export default function MailingListPage() {
  document.title = `${languages.mailingList} | ${languages.management} - ${languages.schoolName}`;
  const bLoaded = useRef(false);

  const [isLoading, setLoading] = useState(true);

  const [lstMailingLists, setLstMailingLists] = useState<IMailingList[]>([]);
  const [lstMailingMembers, setLstMailingMembers] = useState<IMailSubscription[]>([]);
  const [lstAlumniUsers, setLstAlumniUsers] = useState<IAlumniUser[]>([]);

  const [messageDisplay, setMessageDisplay] = useState("");
  const [hastNetError, setHastNetError] = useState(false);
  const [typeDisplay, setTypeDisplay] = useState(0);

  const [currentUser, setCurrentUser] = useState<IManagtUser | null>(null);

  const navigate = useNavigate();

  const createNewMail = () => {
    navigate('/app/main/mailinglist/new');
  };
  const onDeleteMail = async (mailKey: string) => {
    const bAsk = await appFxs.showConfirm(languages.delete, languages.deleteRecordQst);
    if(!bAsk.isConfirmed)return;

    const oper = await NetServices.requestPost('managt/deletemaillistmail', {
        mailingMailKey: mailKey,
    }, true);

    if(!oper.bReturn){
        appFxs.showAlert(oper.msgTitle, oper.msgBody);
        return;
    }


    setLstMailingLists(oper.lstMailingLists!);
  }

  const populateData = async () => {
    setLoading(true);
    setHastNetError(false);
    setMessageDisplay("");

    const oper = await NetServices.requestGet(
      "managt/getlistmailandmembers",
      false
    );

    if (!oper.bReturn) {
      setHastNetError(true);
      setMessageDisplay(oper.msgBody);
      setLoading(false);
      return;
    }

    setLstMailingMembers(oper.lstMailingMembers!);
    setLstMailingLists(oper.lstMailingLists!);
    setLstAlumniUsers(oper.lstAlumniUsers!);
    setCurrentUser(oper.connectedUser!)

    setHastNetError(false);
    setMessageDisplay("");
    setLoading(false);
  };

  useEffect(() => {
    if (bLoaded.current) return;
    populateData();
    bLoaded.current = true;
  }, []);

  return (
    <>
      <div className="flex flex-col gap-3">
        <Card>
          <div className="flex items-center">
            <div className="grow">
              <div className="flex">
                <h3 className="flex-1 text-lg font-bold text-gray-800">
                  {languages.mailingList}
                </h3>
                <div className="flex gap-1">
                  <button
                    type="button"
                    className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                    onClick={() => populateData()}
                  >
                    <i className="fa fa-history"></i>
                  </button>

                  <button
                    type="button"
                    className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                    onClick={() => navigate("/app/main/mailinglist/new")}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div className="mt-1 text-zinc-700 dark:text-gray-400">
                {languages.mailingListMsg}
              </div>
            </div>
            <div className="grow-0 flex items-center gap-2"></div>
          </div>
        </Card>

        <Card>
          <div className="flex flex-col">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold">
                {typeDisplay === 0 ? languages.mailingList : languages.members}
              </h3>
              <ul className="menu menu-horizontal bg-base-200 rounded-box">
                <li>
                  <button
                    className="tooltip"
                    data-tip={languages.mailingList}
                    onClick={() => setTypeDisplay(0)}
                  >
                    <MailSvg width={20} height={20} />
                  </button>
                </li>
                <li>
                  <button
                    className="tooltip"
                    data-tip={languages.members}
                    onClick={() => setTypeDisplay(1)}
                  >
                    <UserSvg width={20} height={20} />
                  </button>
                </li>
              </ul>
            </div>
            {/* table mailing list*/}
            {typeDisplay === 0 && (
              <table className="table table-zebra">
                <thead>
                  <tr>
                    <th>{languages.subject}</th>
                    <th className="hidden sm:table-cell">{languages.sender}</th>
                    <th className="hidden md:table-cell">{languages.date}</th>
                    <th className="hidden lg:table-cell">
                      {languages.recipients}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* Loading */}
                  {isLoading && <LoadingTableRow colSpan={5} />}

                  {/* error */}
                  {!isLoading && hastNetError && (
                    <ErrorTableRow
                      colSpan={5}
                      messageDisplay={messageDisplay}
                      actionToTake={populateData}
                    />
                  )}

                  {/* No Record */}
                  {!isLoading &&
                    !hastNetError &&
                    lstMailingLists.length === 0 && (
                      <NoRecordTableRow
                        colSpan={5}
                        messageDisplay={languages.noMailListFnd}
                        buttonIconCss="fa fa-plus"
                        actionToTake={createNewMail}
                      />
                    )}

                  {/* list */}
                  {!isLoading &&
                    !hastNetError &&
                    lstMailingLists.length > 0 &&
                    lstMailingLists.map((oMail, idx) => (
                      <MailingTableRow
                        mail={oMail}
                        users={lstAlumniUsers}
                        key={idx}
                        meUser={currentUser!}
                        onDeleteMail={onDeleteMail}
                      />
                    ))}
                </tbody>
              </table>
            )}

            {/* table mailing list*/}
            {typeDisplay === 1 && (
              <table className="table table-zebra">
                <thead>
                  <tr>
                    <th>{languages.email}</th>
                    <th>{languages.subscrDate}</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Loading */}
                  {isLoading && <LoadingTableRow colSpan={2} />}

                  {/* error */}
                  {!isLoading && hastNetError && (
                    <ErrorTableRow
                      colSpan={2}
                      messageDisplay={messageDisplay}
                      buttonIconCss="fa fa-history"
                      actionToTake={() => populateData()}
                    />
                  )}

                  {/* No Record */}
                  {!isLoading &&
                    !hastNetError &&
                    lstMailingMembers.length === 0 && (
                      <NoRecordTableRow
                        colSpan={2}
                        messageDisplay={languages.noMailListMembersFnd}
                        actionToTake={() => populateData()}
                        buttonIconCss="fa fa-history"
                      />
                    )}

                  {/* list */}
                  {!isLoading &&
                    !hastNetError &&
                    lstMailingMembers.length > 0 &&
                    lstMailingMembers.map((subsc, idx) => (
                      <MailingMemberRow member={subsc} key={idx} />
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </Card>
      </div>
    </>
  );
}

const MailingTableRow = ({
  mail,
  users,
  meUser,
  onDeleteMail,
}: {
  mail: IMailingList;
  users: IAlumniUser[];
  meUser: IManagtUser,
  onDeleteMail: (key:string) => void,
}) => {
  const sender = users.find((o) => o.emailAddress === mail.senderEmail);
  const finalSender = sender
    ? `${sender.firstName} ${sender.lastName}`
    : languages.notSpecified;

  return (
    <tr>
      {/* Subject */}
      <td>
        <div className="flex flex-col gap-1">
          <Link
            className="text-blue-500 hover:text-blue-700"
            to={`/app/main/mailinglist/preview/${mail.mailKey}`}
          >
            {mail.mailSubject}
          </Link>
          <span className="sm:hidden text-slate-600">
            <strong className="flex items-center gap-1">
              <i className="fa fa-user"></i>
              {finalSender}
            </strong>
          </span>
          <span className="md:hidden text-slate-600">
            <strong className="flex items-center gap-1">
              <i className="fa fa-calendar"></i>
              {moment(mail.mailDate).format("DD/MM/YY")}
            </strong>
          </span>
          <span className="lg:hidden text-sm text-slate-600">
            <strong className="flex items-center gap-1">
              <i className="fa fa-envelope"></i>
              {mail.mailReceivers.length} {languages.subscribers.toLowerCase()}
            </strong>
          </span>
        </div>
      </td>

      {/* sender */}
      <td className="hidden sm:table-cell">
        <span className="flex items-center gap-1">
          <i className="fa fa-user"></i>
          {finalSender}
        </span>
      </td>

      {/* Date */}
      <td className="hidden md:table-cell">
        {moment(mail.mailDate).format("DD/MM/YY")}
      </td>

      {/* Date */}
      <td className="hidden lg:table-cell">
        <span className="flex items-center gap-1">
          <i className="fa fa-envelope"></i>
          {mail.mailReceivers.length} {languages.subscribers.toLowerCase()}
        </span>
      </td>

      {/* actions */}
      <td>
        <div className="flex items-center gap-1 justify-end">
          <Link
            className="border border-solid border-sky-500 rounded-md py-1 px-2 inline-block"
            title={languages.preview}
            to={`/app/main/mailinglist/preview/${mail.mailKey}`}
          >
            <i className="fa fa-eye text-sky-500"></i>
          </Link>

          {meUser.mainAdmin && (
              <button
                className="border border-red-500 rounded-md py-1 px-2"
                onClick={() => onDeleteMail(mail.mailKey)}
                title={languages.delete}
              >
                <i className="fa fa-trash-o text-red-500"></i>
              </button>
            )}
        </div>
      </td>
    </tr>
  );
};

const MailingMemberRow = ({member}:{member: IMailSubscription}) => {
    return (
        <tr>
            <td>
                <a className='text-blue-500 hover:text-blue-700' href={`mailto:${member.email}`}>{member.email}</a>
            </td>
            <td>
                {moment(member.subscriptionDate).format('DD/MM/YYYY')}
            </td>
        </tr>
    );
}