import { useEffect, useRef, useState } from 'react';
import WiseModal from '../modalportal/WiseModal';

import getLang from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import appDecl from 'src/utils/declarations';

import { ICompMultimediaFile, IMultimediaFile } from 'src/models/smarttypes';
import NetServices from 'src/utils/netservices';


const languages = getLang();
const appFxs = generalFxs();

interface IMediaOrganizerFounderProps {
    isOpen:boolean, 
    dialogWidth?: number,
    onClose: (path?: string) => void,
}

const MediaOrganizerFounder = ({isOpen, dialogWidth, onClose}: IMediaOrganizerFounderProps) => {
    if(!isOpen) return null;
    return (
        <WiseModal 
            isOpen={isOpen} 
            onClose={() => onClose()} 
            titleBox={languages.mediaFiles} 
            width={dialogWidth ?? 450}
            hasFooter={false}>
            <MediaOrganizeComponent afterSelect={onClose} />
        </WiseModal>
    )
}

export default MediaOrganizerFounder;

interface MediaOrganizeComponentProps {
    afterSelect: (path?: string) => void
}

const MediaOrganizeComponent = ({afterSelect}:MediaOrganizeComponentProps ) => {
    const [mediaFiles, setMediaFiles] = useState<ICompMultimediaFile[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [flexStart, setFlexStart] = useState<string>('');

    const isLoaded = useRef(false);
    
    const fileUploader = useRef<HTMLInputElement>(null);
    const onSelectFiles = () => {
        fileUploader.current?.click();
    }
    const onFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!e.target.files){
            appFxs.showAlert(languages.files, languages.noFilesSelected);
            return;
        }
        const files = [...Array.from(e.target.files!)];
        const formDataFiles = new FormData();

        files.forEach(oF => {
            const fKey = appFxs.generateUUID();
            formDataFiles.append(fKey, oF);
        });


        const operLoad = await NetServices.requestPostWithFile(
            'managt/savemultimediafilesbyuser',
            formDataFiles, 
            true);
        
        if(!operLoad.bReturn){
            await appFxs.showAlert(operLoad.msgTitle, operLoad.msgBody);
            return;
        }

        setMediaFiles(operLoad.listOfAllCompMultimedia!);
    }

    const loadFilesFromServer = async (bAjax: boolean = false) => {
        const operLoad = await NetServices.requestGet('managt/getcompanymultimedia', bAjax);
        
        if(!operLoad.bReturn){
            await appFxs.showAlert(operLoad.msgTitle, operLoad.msgBody);
            setLoading(false);
            return;
        }
        setMediaFiles(operLoad.listOfAllCompMultimedia!);
        setLoading(false);
    }

    const onSelectFile = (path: string) => {
        afterSelect(path);
    }


    useEffect(() => {
        if(isLoaded.current)return;
        loadFilesFromServer();
        isLoaded.current = true;
    }, [])

    useEffect(() => {
        const f = (isLoading || mediaFiles.length === 0) ? 'flex justify-center items-center':'';
        setFlexStart(f);
    }, [isLoading, mediaFiles])


    return (
        <div className='flex flex-col gap-2'>
            <div className={`images-area mb-4 min-h-[300px] overflow-y-auto ${flexStart}`}>
                {/* Loading files */}
                {isLoading && <div className='flex items-center justify-center py-4'>
                    <div className='flex flex-col gap-2  items-center'>
                        <span>
                        <i className='fa fa-spin fa-spinner fa-3x'></i>
                        </span>
                        <span className='font-semibold'>{languages.loadingFiles}...</span>
                    </div>
                </div>}

                {/* No files found */}
                {(!isLoading && mediaFiles.length === 0) && <div className='flex items-center justify-center py-4'>
                    <div className='flex flex-col gap-2 items-center'>
                        <span className='block max-w-[250px] text-center'>{languages.noMediaFiles}</span>
                    </div>
                </div>}

                {/* list of files */}
                {(!isLoading && mediaFiles.length > 0) && 
                    <div className='flex flex-wrap'>
                        {mediaFiles.map((oFile, idx) => 
                            <MediaFileWrapper 
                                fileRec={oFile} 
                                key={idx} 
                                onSelectFile={onSelectFile}/>)}
                    </div>
                }
            </div>
            <div className='upload-area flex flex-wrap pt-4 mt-2 justify-end border-t border-t-slate-400 border-solid'>
                <input type='file'
                   ref={fileUploader}
                   className='hidden'
                   multiple={true} 
                   accept="image/*,audio/*,video/*"
                   onChange={onFileSelected} />
                <button className='flex gap-1 items-center border border-solid border-slate-500 p-2 rounded-[4px] hover:bg-slate-300'
                    onClick={onSelectFiles}>
                    <i className='fa fa-plus-circle'></i>
                    <span>{languages.uploadFiles}</span>
                </button>
            </div>
        </div>
    );
}

const MediaFileWrapper = ({fileRec,onSelectFile}: {fileRec: IMultimediaFile, onSelectFile: (path: string) => void}) => {
    const resFileName = fileRec.resourceFileName;
    const isImage = appFxs.isFileImageExt(fileRec.fileExtension);
    const isVideo = appFxs.isFileVideoExt(fileRec.fileExtension);

    const filePath = `${appDecl.apiUrlTest}/cloud_data/company_multimedia_files/${resFileName}`;
    const urlLink = `url(${filePath})`;

    return (
        <>
            {isImage && 
                <div className='cursor-pointer multimedia-elt-img w-[130px] aspect-video mb-2 mr-2 border border-slate-400 border-solid rounded-[4px] relative before:block before:absolute before:w-full before:h-full before:top-0 before:left-0 z-[1] before:bg-black before:bg-opacity-30'
                    style={{
                        backgroundImage: urlLink,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}
                    onClick={() => onSelectFile(filePath)}>
                </div>}
            {isVideo && 
                <div className='cursor-pointer multimedia-elt-video w-[130px] aspect-video mb-2 mr-2 border border-slate-400 border-solid rounded-[4px] relative before:block before:absolute before:w-full before:h-full before:top-0 before:left-0 z-[1] before:bg-black before:bg-opacity-30'
                    style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}
                    onClick={() => onSelectFile(filePath)}>
                        <video src={filePath} 
                            className={`absolute w-full h-full top-0 left-0 -z-[1]`}
                            autoPlay={true}
                            muted={true}></video>

                        <div className='absolute w-[40px] h-[40px] top-0 left-0 z-[1] flex items-center justify-center'>
                            <i className='fa fa-play-circle fa-2x text-white'></i>
                        </div>
                </div>}
        </>
    );
}