const LoadingWaitSpace = ({height}: {height?: number}) => {
  const vHeight = height ? `${height}px`:'350px'
  return (
    <div className='flex items-center justify-center'
    style={{height: vHeight}}>
        <i className='fa fa-spin fa-spinner fa-3x'></i>
    </div>
  )
}

export default LoadingWaitSpace