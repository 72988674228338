import React from 'react'

import WiseModal from '../modalportal/WiseModal';

import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";
import { ISalesComplex } from 'src/models/smarttypes';

const languages = getLanguages();
const appFxs = getFxs();

type linkTypeProps = 'none'|'phone'|'email';

interface SalesDetailDlgProps {
    typeDisplay: 'contact'|'details',
    isOpen: boolean,
    onClose: () => void,
    dialogWidth?: number,
    dialogTitle: string,
    salesData: ISalesComplex,
}

export default function SalesDetailDlg({
    dialogTitle,
    isOpen,
    onClose,
    typeDisplay,
    dialogWidth,
    salesData,
}: SalesDetailDlgProps) {

    if (!isOpen) return null;
  return (
    <WiseModal
        isOpen={isOpen}
        onClose={() => onClose()}
        titleBox={dialogTitle}
        width={dialogWidth ?? 450}
        hasFooter={true}>
    {
        typeDisplay==='contact' ? 
            <SalesContactWindow salesData={salesData} /> : 
            <SalesDetailWindow salesData={salesData} />
    }
    </WiseModal>
  )
}


const SalesDetailWindow = ({salesData}: {salesData: ISalesComplex}) => {
    const details = salesData.details;
    const oper = salesData.operation;

    return (
      <div className="flex flex-col divide-y divide-slate-600 divide-solid  max-h-[350px] overflow-y-auto">
        {details.map((oDet, idx) => (
          <div className="flex flex-col gap-1" key={idx}>
            <span className='flex items-center gap-2'>
                <span className='w-4 h-4 rounded-full border border-solid border-slate-500 flex items-center justify-center'>{idx + 1}</span>            
                <span className='flex-1 font-semibold'>{oDet.productName}</span>
            </span>
            <span className='flex justify-end items-center gap-2'>
                <span className='text-[12px]'>RWF</span>
                <span className='font-semibold'>{appFxs.formatNumbers(oDet.subtotal)}</span>
            </span>
          </div>
        ))}
        <div className="flex justify-between pt-3">
            <span className='flex items-center gap-2'>
                <span className='flex-1 font-semibold'>{languages.total}</span>
            </span>
            <span className='flex justify-end items-center gap-2 badge badge-primary'>
                <span className='text-[12px]'>RWF</span>
                <span className='font-semibold'>{appFxs.formatNumbers(oper.total)}</span>
            </span>
          </div>
      </div>
    );
}
const SalesContactWindow = ({salesData}: {salesData: ISalesComplex}) => {
    const contact = salesData.contact;

    return (
        <div className='flex flex-col gap-1 max-h-[350px] overflow-y-auto'>
            <FieldDisplayer 
                label={languages.firstName}
                value={contact.firstName}
            />
            <FieldDisplayer 
                label={languages.lastName}
                value={contact.lastName}
            />
            <FieldDisplayer 
                label={languages.city}
                value={contact.city}
            />
            <FieldDisplayer 
                label={languages.phoneNbr}
                value={contact.phone}
                displyayType='phone'
            />
            <FieldDisplayer 
                label={languages.email}
                value={contact.email}
                displyayType='email'
            />
        </div>
    );
}

interface FieldDisplayerProps{
    label: string,
    value: string,
    displyayType?: linkTypeProps,
}
const FieldDisplayer = ({label, value, displyayType}:FieldDisplayerProps) => {
    const linkType:linkTypeProps = displyayType ?? 'none';

    return (
        <div className='flex flex-col'>
            <span className='px-2 font-semibold block'>
                {label}
            </span>
            <div className='py-2 px-2 flex items-center border border-solid border-slate-300 rounded-lg'>
                {linkType === 'none' && 
                    <span>{value}</span>
                }
                {linkType === 'email' && 
                    <a className='text-sky-500 hover:text-sky-700' href={`mailto:${value}`}>{value}</a>
                }
                {linkType === 'phone' && 
                    <a className='text-sky-500 hover:text-sky-700' href={`tel:${value}`}>{value}</a>
                }
            </div>
        </div>
    );
}