import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import alLogo from 'src/assets/images/alumniimg.png';
import appLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import appDeclarations from "src/utils/declarations";

const languages = appLanguages();
const appFxs = generalFxs();


export default function AcctWaitForReset() {
  document.title = `${languages.waitPwSet} | ${languages.management} | ${languages.schoolName}`;
  
  const [userEmail, setUserEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  

  useEffect(()=> {
    const e = appFxs.getLocalStorageByKey(appDeclarations.waitingEmailForPw, '');
    setUserEmail(e);
    setIsLoading(false);
  }, []);

  if(isLoading) return <div></div>

  return (
    <div className="flex flex-col">
      <div className="l-hearder mb-2">
        <Link to="/" className="flex items-center">
          <img src={alLogo} alt="" className="logo mr-3" />
          <span className="stockix">La Colombière</span>
        </Link>
      </div>
      

      <form className="login-form mt-3">
        <div className="flex flex-col mt-3">
          <h1 className="tiny_bottom_margin size-17rem">
            {languages.checkUrEmail}
          </h1>
          <p className="py-4">managt.lacolombiere.ac.rw</p>
          <p className="mb-4 font-semibold">{languages.checkUrEmailPasswordReset}</p>
          <div className="p-7 text-center font-semibold mt-6 border border-solid border-stone-500 rounded-lg bg-slate-200">
            <span>{userEmail}</span>
          </div>
          
          
            <Link
              className="font-semibold text-blue-500 mt-6 hover:text-blue-700"
              to={"/"}
            >
              {languages.home}
            </Link>
        </div>
      </form>
    </div>
  )
}
