/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';


import WiseDropDown from "src/components/wsedropdown/WiseDropDown";
import NetServices from "src/utils/netservices";

import getLanguages from 'src/utils/language';
import generalFx from 'src/utils/general_fx';

import Card from "src/components/Card";
import { IStatMngtResult } from 'src/models/smarttypes';

import {ReactComponent as DashSalesSvg} from 'src/assets/images/svg/dashboard/dash-sales.svg';
import {ReactComponent as DashProductsSvg} from 'src/assets/images/svg/dashboard/dash-product.svg';
import {ReactComponent as DashMailingSvg} from 'src/assets/images/svg/dashboard/dash-mailing.svg';
import {ReactComponent as DashAlumniSvg} from 'src/assets/images/svg/dashboard/dash-alumni.svg';
import {ReactComponent as DashBlogSvg} from 'src/assets/images/svg/dashboard/dash-blog.svg';
import {ReactComponent as DashMessagesSvg} from 'src/assets/images/svg/dashboard/dash-messages.svg';

import {ReactComponent as ServerErrorIconSvg} from 'src/assets/images/svg/server-error.svg'


const languages = getLanguages();
const appFxs = generalFx();

const months: { id: string; label: string }[] = appFxs.wdMonthsShrt.map(
  (o, i) => {
    return { id: (i + 1).toString(), label: o };
  }
);
const startMonth = (new Date()).getMonth() + 1;



export default function DashboardPage() {
  document.title = `${languages.dashboard} | ${languages.management} - ${languages.schoolName}`;
  const [month, setMonth] = useState<number>(startMonth)
  const [monthName, setMonthName] = useState<string>(languages.loading);

  useEffect(() => {
    setMonthName(appFxs.wdMonthsShrt[month-1])
  }, [month]);
  

  
  return (
    <div className="flex flex-col gap-3">
      {/* Header */}
      <Card>
        <div className="flex items-center">
          <div className="grow">
            <h3 className="text-lg font-bold text-gray-800">
              {languages.dashboard}
            </h3>
            <p className="mt-1 text-gray-800 dark:text-gray-400">
              {languages.dashboardUserMsg}
            </p>
          </div>
          
        </div>
      </Card>

      <Card>
        <div className="flex flex-col gap-3">
        <div className="flex justify-between items-center  mb-2">
            <p className="text-xl font-bold">{languages.details}</p>
            <WiseDropDown
              label={monthName}
              hasDropdown={false}
              options={months}
              position="right"
              onSelected={(e) => {
                setMonth(Number(e.id));
              }}
            >
              <span className="flex items-center justify-center px-3 py-0 border border-solid rounded-2xl min-w-[120px]">
                <div className="flex items-center w-full gap-1">
                  <i className="fa fa-calendar"></i>
                  <span className="flex-1">{monthName}</span>
                  <i className="fa fa-caret-down"></i>
                </div>
              </span>
            </WiseDropDown>
          </div>


          <div className="flex flex-wrap border border-solid rounded-[1rem] border-slate-200 shadow">
            <DashStatCom statType="blog" month={month} classes="basis-full sm:basis-1/2 md:basis-1/4" />
            <DashStatCom statType="alumni" month={month} classes="basis-full sm:basis-1/2 md:basis-1/4" />
            <DashStatCom statType="mailing" month={month} classes="basis-full sm:basis-1/2 md:basis-1/4" />
            <DashStatCom statType="message" month={month} classes="basis-full sm:basis-1/2 md:basis-1/4" />
            <DashStatCom statType="products" month={month} classes="basis-full sm:basis-1/2 md:basis-1/4" />
            <DashStatCom statType="sales" month={month} classes="basis-full sm:basis-1/2 md:basis-1/4" />
          </div>


        </div>
      </Card>
    </div>
  )
}

interface DashStatComProps {
  statType: 'blog'|'alumni'|'mailing'|'message'|'products'|'sales',
  classes: string,
  month: number,
}
const DashStatCom = ({statType, classes, month}: DashStatComProps) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isServerError, setServerError] = useState<boolean>(false);

  const [statRec, setStatRec] = useState<IStatMngtResult | null>(null);
//'blog'|'alumni'|'mailing'|'message'|'products'|'sales',
  const textType = 
    statType === 'blog' ? languages.blog:
    statType === 'alumni' ? languages.alumni:
    statType === 'mailing' ? languages.mailingList:
    statType === 'message' ? languages.messages:
    statType === 'products' ? languages.products:
    statType === 'sales' ? languages.salesOpers:
    languages.blogs;

  const SVGIcon = 
    statType === 'blog' ? DashSalesSvg:
    statType === 'alumni' ? DashAlumniSvg:
    statType === 'mailing' ? DashMailingSvg:
    statType === 'message' ? DashMessagesSvg:
    statType === 'products' ? DashProductsSvg:
    statType === 'sales' ? DashSalesSvg:
    DashBlogSvg;

  const getDataFromNetwork = async () => {
    setServerError(false);
    setLoading(true);
    const oPerData = await NetServices.requestGet(`managt/getdashboardstats/${statType}/${month}`)
    if(!oPerData.bReturn){
      setServerError(true);
      setLoading(false);
      return;
    }

    setStatRec(oPerData.statisticsMgtRecord!);

    setServerError(false);
    setLoading(false);
  }


  useEffect(() => {
    getDataFromNetwork();
  }, [month]);

  if(isLoading) {
    return (
      <div className={`w-[192px] h-[104px] flex items-center justify-center ${classes && classes}`}>
        <div className="flex flex-col items-center justify-center">
          <i className="fa fa-spinner fa-spin fa-3x"></i>
          <span className="text-gray-600 text-sm">{textType}</span>
        </div>
      </div>
    );
  }

  if(!isLoading && isServerError){
    return (
      <div className="w-[192px] h-[104px] flex items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <ServerErrorIconSvg width={32} height={32} />
          <span className="text-gray-600 text-sm">{languages.serverError}</span>
        </div>
      </div>
    );
  }



  return (
    <div
      className={`w-fit flex gap-3 py-[1rem] px-[1.5rem] items-center mb-3 ${
        classes && classes
      } `}
    >
        <div className="flex-1 w-[100px] flex flex-col justify-center">
          <div className="w-stat-desc whitespace-nowrap leading-[1rem] text-[1rem] text-gray-400">
            {textType}
          </div>
          <div className="w-stat-value whitespace-nowrap leading-[2.5rem] text-[2.25rem] font-extrabold">
            {statRec?.value}
          </div>
          <div className="w-stat-desc whitespace-nowrap leading-[1rem] text-[0.75rem] text-gray-400 flex items-center gap-1">
            <StatVarComp varType={statRec?.arrowStyle!} />
            <span>{statRec?.prevValue}</span>
            <span>({statRec?.percentValue!})</span>
          </div>
        </div>
        <div className="text-secondary">
          <SVGIcon width={32} height={32} stroke="green" />
        </div>
    </div>
  );
}

type StatVarCompProps = 'up' | 'down' | 'stable';
const StatVarComp = ({varType}: {varType: StatVarCompProps}) => {
  return(
    <>
      {varType === "up" && <span>&#8599;</span>}
      {varType === "down" && <span>&#8600;</span>}
      {varType === "stable" && <span>&#8605;</span>}
    </>
  );
}












