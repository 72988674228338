import React, { useEffect, useRef, useState } from 'react'


import Card from 'src/components/Card';


import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";
import { IManagtUser } from 'src/models/smarttypes';
import NetServices from 'src/utils/netservices';
import moment from 'moment';
import ManagtUserForm from 'src/components/managtuserform/ManagtUserForm';


const languages = getLanguages();
const appFxs = getFxs();

const defUserData:IManagtUser = {
  addressLine: '--',
  admin: false,
  dateOfBirth: new Date(),
  emailAddress: '',
  firstName: '',
  lastName: '',
  mainAdmin: false,
  phoneNbr: '',
  userActive: false,
  password: '',
  profilePicturePath: '',
  sex: '0',
}


export default function UsersPage() {
  document.title = `${languages.users} | ${languages.management} - ${languages.schoolName}`;
  const [isLoading, setLoading] = useState(true);
  const [listUsers, setListUsers] = useState<IManagtUser[]>([]);
  const [hastNetError, setNetError] = useState(false);
  const [selectedLang, setSelectedLang] = useState('en');
  const [messageDisplay, setMessageDisplay] = useState(languages.noRecFnd); 
  const [connectedUser, setConnectedUser] = useState<IManagtUser|null>(null);

  const [formTitle, setFormTitle] = useState('');
  const [isFormOpen, setFormOpen] = useState<boolean>(false);
  const [currentRec, setCurrentRec] = useState<IManagtUser>({...defUserData});
  const [recStatus, setRecStatus] = useState(0);

  const bLoaded = useRef(false);

  const addEdditRecord = (e?: IManagtUser) => {
    setRecStatus(e == null ? 0: 1);
    setCurrentRec(e ?? {...defUserData})
    setFormTitle(e == null ? languages.addNewUser: languages.editUser);
    setFormOpen(true);
  }

  const resendActiveKey = async (e: IManagtUser) => {
    const oper = await NetServices.requestPost('managt/resendconfirmkey', {
      resendUserEmail: e.emailAddress,
    }, true);

    if(!oper.bReturn){
      appFxs.showAlert(oper.msgTitle, oper.msgBody);
      return;
    }

    setListUsers(oper.listManagtUsers!);
  }
  const deleteUserRecord = async (e: IManagtUser) => {
    const bAsk = await appFxs.showConfirm(languages.delete, languages.deleteRecordQst);
    if(!bAsk.isConfirmed) return;
    const oper = await NetServices.requestPost('managt/setuserdeleted', {
      userDeleteEmail: e.emailAddress,
    }, true);

    if(!oper.bReturn){
      appFxs.showAlert(oper.msgTitle, oper.msgBody);
      return;
    }

    setListUsers(oper.listManagtUsers!);
  }
  const onActivateUser = async (e: IManagtUser, i: number) => {
    const bAsk = await appFxs.showConfirm(
      i===0?  languages.disableUser : languages.enableUser, 
      i===0?  languages.disableUserMsg : languages.enableUserMsg, 
    );
    if(!bAsk.isConfirmed) return;
    const oper = await NetServices.requestPost('managt/setuseractive', {
      activeUserEmail: e.emailAddress,
      activityType: i,
    }, true);

    if(!oper.bReturn){
      appFxs.showAlert(oper.msgTitle, oper.msgBody);
      return;
    }

    setListUsers(oper.listManagtUsers!);
  }

  const loadListUser = async (bAjax: boolean) => {
    const oper = await NetServices.requestGet('managt/getlistmanagtusers', bAjax);
    if(!oper.bReturn){
      setMessageDisplay(oper.msgBody);
      setNetError(true);
      setLoading(false);
      return;
    }
    setMessageDisplay(languages.noRecFnd);
    setListUsers(oper.listManagtUsers!);
    setConnectedUser(oper.connectedUser!);
    setNetError(false);
    setLoading(false);
  }

  const prepareLang = () => {
    const oLang = appFxs.getLocalLanguage();
    setSelectedLang(oLang);
  }

  const afterReceiveData = (lst?: IManagtUser[]) => {
    if(lst){
      setListUsers(lst!);
    }

    setFormOpen(false);
  }



  useEffect(() => {
    if(bLoaded.current)return;
    loadListUser(false);
    prepareLang();
    bLoaded.current = true;
  }, []);
  

  return (
    <>
      <ManagtUserForm isOpen={isFormOpen} 
        onClose={afterReceiveData}
        currentRecord={currentRec}
        isMainAdmin={connectedUser?.mainAdmin ?? false}
        recordStatus={recStatus}
        dialogTitle={formTitle}
        />
      <div className="flex flex-col gap-3">
        <Card>
          <div className="flex items-center">
            <div className="grow">
              <div className="flex">
                <h3 className="flex-1 text-lg font-bold text-gray-800">
                  {languages.users}
                </h3>
                <div className="flex gap-1">
                  <button
                    type="button"
                    className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                    onClick={() => addEdditRecord()}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <p className="mt-1 text-gray-800 dark:text-gray-400">
                {languages.usersMsg}
              </p>
            </div>
            <div className="grow-0 flex items-center gap-2"></div>
          </div>
        </Card>

        <Card>
          <div className="flex flex-col space-y-2">
            {/* Info */}
            {(!(connectedUser === null)) && 
              <>
                {(connectedUser.mainAdmin && (selectedLang === 'en')) && 
                  <div>
                    <p className='text-sm my-3'>
                      <strong>{`${connectedUser?.firstName} ${connectedUser?.lastName}`}</strong>, 
                      you are the Administrator for <strong>La Colombière</strong> school management 
                      application. You can invite your other colleagues and grant them some rights to 
                      interact with the application features.
                    </p>
                  </div>
                }

                {(!connectedUser.mainAdmin && (selectedLang === 'en')) && 
                  <div>
                    <p className='text-sm my-3'>
                      <strong>{`${connectedUser?.firstName} ${connectedUser?.lastName}`}</strong>, 
                      you've been invited by the Administrator of the <strong>La Colombière</strong> school management 
                      application. You have some limited rights.
                    </p>
                  </div>
                }

                {(connectedUser.mainAdmin && (selectedLang === 'fr')) && 
                  <div>
                    <p className='text-sm my-3'>
                      <strong>{`${connectedUser?.firstName} ${connectedUser?.lastName}`}</strong>, 
                      vous êtes l'Administrateur principal de l'application de gestion de l'école 
                      <strong>La Colombière</strong>. Vous pouvez inviter d'autres collègues et leur 
                      octroyer des droits liimités pour gérer certaines fonctionnalités de cette application.
                    </p>
                  </div>
                }

                {(!connectedUser.mainAdmin && (selectedLang === 'fr')) && 
                  <div>
                    <p className='text-sm my-3'>
                      <strong>{`${connectedUser?.firstName} ${connectedUser?.lastName}`}</strong>, 
                      vous avez été invité par l'Administrateur principal de l'application de gestion de l'école 
                      <strong>La Colombière</strong>. Vous avez des droits limités.
                    </p>
                  </div>
                }
              </>            
            }

            {/* User Table */}
            <table className="table table-zebra">
              <thead>
                <tr>
                  <th>{languages.name}</th>
                  <th className='hidden sm:table-cell'>{languages.email}</th>
                  <th className='hidden md:table-cell'>{languages.phoneNbr}</th>
                  <th className='hidden lg:table-cell'>{languages.sex}</th>
                  <th className='text-right hidden lg:table-cell'>{languages.dob}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {/* Loading */}
                {isLoading && (
                  <tr>
                    <td colSpan={6}>
                      <div className="h-auto py-2 flex flex-col justify-center items-center space-y-2">
                        <span className="loading loading-spinner loading-lg"></span>
                        <h3 className='font-semibold text-lg'>{languages.loading}</h3>
                      </div>
                    </td>
                  </tr>
                )}
                {/* Loading */}
                {/* No Record */}
                {(!isLoading && listUsers.length === 0) && (
                  <tr>
                    <td colSpan={6}>
                      <div className="h-auto py-2 flex flex-col justify-center items-center space-y-2">
                        <h3>{messageDisplay}</h3>
                        {!hastNetError && <button
                          type="button"
                          className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                          onClick={() => addEdditRecord()}
                        >
                          <i className="fa fa-plus"></i>
                        </button>}

                        {hastNetError && 
                          <button
                            type="button"
                            className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                            onClick={() => loadListUser(true)}>
                            <i className="fa fa-history"></i>
                          </button>}
                      </div>
                    </td>
                  </tr>
                )}
                {/* No Record */}
                {/* Records */}
                
                  {listUsers.map((user, idx) => (
                    <UserRowComp key={idx} 
                      userRecord={user}
                      onEditRec={addEdditRecord}
                      onDeleteRec={deleteUserRecord}
                      onActivate={onActivateUser} 
                      resendActiveKey={resendActiveKey} />
                  ))}
                {/* Records */}
              </tbody>
            </table>
            {/* User Table */}
          </div>
        </Card>
      </div>
    </>
  );
}



const UserRowComp = ({userRecord, onEditRec, onDeleteRec, onActivate, resendActiveKey}: {
  userRecord: IManagtUser,
  onEditRec: (e: IManagtUser) => void,
  onDeleteRec: (e: IManagtUser) => void,
  onActivate: (e: IManagtUser, iStatus: number) => void,
  resendActiveKey: (e: IManagtUser) => void,
}) => {
  return (
    <tr>
      <td>
        <div className="flex flex-col gap-1">
          {`${userRecord.firstName} ${userRecord.lastName}`}
          <span className="sm:hidden text-slate-600">
            <i className="fa fa-envelope"></i>&nbsp;
            <strong>{userRecord.emailAddress}</strong>
          </span>
          <span className="md:hidden text-sm text-slate-600">
            <i className="fa fa-phone"></i>&nbsp;
            <strong>{userRecord.phoneNbr}</strong>
          </span>
        </div>
      </td>
      <td className="hidden sm:table-cell">{userRecord.emailAddress}</td>
      <td className="hidden md:table-cell">{userRecord.phoneNbr}</td>
      <td className="hidden lg:table-cell">
        {userRecord.sex === "0" ? languages.male : languages.female}
      </td>
      <td className="text-right hidden lg:table-cell">
        {moment(userRecord.dateOfBirth).format("DD/MM/YYYY")}
      </td>
      <td>
        <div className="flex items-center gap-1 justify-end">
          {!userRecord.mainAdmin && (
            <button
              className="border border-red-500 rounded-md py-1 px-2"
              onClick={() => onDeleteRec(userRecord)}>
              <i className="fa fa-trash text-red-500"></i>
            </button>
          )}
          <button
            className="border border-sky-500 rounded-md py-1 px-2"
            onClick={() => onEditRec(userRecord)}>
            <i className="fa fa-pencil text-sky-500"></i>
          </button>
          {!userRecord.mainAdmin && (
          <button
            className="border border-slate-500 rounded-md py-1 px-2"
            onClick={() =>
              onActivate(userRecord, userRecord.userActive ? 0 : 1)
            }
            title={`${
              userRecord.userActive
                ? languages.disableUser
                : languages.enableUser
            }`}
          >
            <i
              className={`fa ${
                userRecord.userActive ? "fa-lock" : "fa-unlock"
              } text-slate-500`}
            ></i>
          </button>
          
          )}
          {!userRecord.userActive && 
            <button
              className="border border-sky-500 rounded-md py-1 px-2"
              onClick={() => resendActiveKey(userRecord)}>
              <i className="fa fa-key text-sky-500"></i>
            </button>
          }
        </div>
      </td>
    </tr>
  );
}