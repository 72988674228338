/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';



import Card from 'src/components/Card';
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";

import {  ISalesComplex } from 'src/models/smarttypes';
import NetServices from 'src/utils/netservices';

import { ErrorTableRow, LoadingTableRow, NoRecordTableRow } from 'src/components/majorsmallcomps';
import moment from 'moment';
import WiseDropDown from 'src/components/wsedropdown/WiseDropDown';
import SalesDetailDlg from 'src/components/salesdetails/SalesDetailDlg';

const languages = getLanguages();
const appFxs = getFxs();

export default function ShopOpersPage() {
  document.title = `${languages.salesOpers} | ${languages.management} - ${languages.schoolName}`;
  const bLoaded = useRef(false);

  const [isLoading, setLoading] = useState(true);

  const [lstSalesOpers, setLstSalesOpers] = useState<ISalesComplex[]>([]);

  const [messageDisplay, setMessageDisplay] = useState("");
  const [hastNetError, setHastNetError] = useState(false);

  const [salesData, setSalesData] = useState<ISalesComplex | null>(null);
  const [isDlgOpen, setIsDlgOpen] = useState<boolean>(false)
  const [typeDisplay, setTypeDisplay] = useState<"contact" | "details">('contact');
  const [dlgTitle, setDlgTitle] = useState('');

  const onPreviewContact = (e: ISalesComplex) => {
    setDlgTitle(languages.salesContact)
    setSalesData(e);
    setTypeDisplay('contact');
    setIsDlgOpen(true);
  }
  const onPreviewDetails = (e: ISalesComplex) => {
    setDlgTitle(languages.salesDetails);
    setTypeDisplay('details');
    setSalesData(e);
    setIsDlgOpen(true);
  }

  const populateData = async () => {
    setLoading(true);
    setHastNetError(false);
    setMessageDisplay("");

    const oper = await NetServices.requestGet(
      "managt/getallsalesops",
      false
    );

    if (!oper.bReturn) {
      setHastNetError(true);
      setMessageDisplay(oper.msgBody);
      setLoading(false);
      return;
    }


    setLstSalesOpers(oper.lstOfCplxSalesOps!);
    //setCurrentUser(oper.connectedUser!)

    setHastNetError(false);
    setMessageDisplay("");
    setLoading(false);
  }

  useEffect(() => {
    if (bLoaded.current) return;
    populateData();
    bLoaded.current = true;
  }, []);

  return (
    <>
      <SalesDetailDlg
        dialogTitle={dlgTitle}
        isOpen={(isDlgOpen && salesData !== null)}
        onClose={() => setIsDlgOpen(false)}
        salesData={salesData!}
        typeDisplay={typeDisplay}
      />
      <div className='flex flex-col gap-3'>
        <Card>
          <div className="flex items-center">
            <div className="grow">
              <div className="flex">
                <h3 className="flex-1 text-lg font-bold text-gray-800">
                  {languages.salesOpers}
                </h3>
                <div className="flex gap-1">
                  <button
                    type="button"
                    className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                    onClick={() => populateData()}
                    title={languages.reload}
                  >
                    <i className="fa fa-history"></i>
                  </button>
                </div>
              </div>
              <div className="mt-1 text-zinc-700 dark:text-gray-400">
                {languages.salesOpersMsg}
              </div>
            </div>
            <div className="grow-0 flex items-center gap-2"></div>
          </div>
        </Card>

        {/* Details */}
        <Card>
          <div className="flex flex-col">
            <table className="table table-zebra">
            <thead>
                <tr>
                  <th>{languages.client}</th>
                  <th className="hidden md:table-cell">{languages.date}</th>
                  <th className="hidden sm:table-cell text-right">{languages.items}</th>
                  <th className="text-right">{languages.total}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {/* Loading */}
                {isLoading && <LoadingTableRow colSpan={5} />}

                {/* error */}
                {!isLoading && hastNetError && (
                  <ErrorTableRow
                    colSpan={5}
                    messageDisplay={messageDisplay}
                    actionToTake={populateData}
                  />
                )}

                {/* No Record */}
                {!isLoading && !hastNetError && lstSalesOpers.length === 0 && (
                  <NoRecordTableRow
                    colSpan={5}
                    messageDisplay={languages.noProdFound}
                    buttonIconCss="fa fa-history"
                    actionToTake={populateData}
                  />
                )}

                {/* list */}
                {!isLoading &&
                  !hastNetError &&
                  lstSalesOpers.length > 0 &&
                  lstSalesOpers.map((oSales, idx) => (
                    <SalesTableRow
                      sales={oSales}
                      key={idx}
                      onPreviewContact={onPreviewContact}
                      onPreviewDetails={onPreviewDetails}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </Card>
      </div>
    </>
  )
}
interface SalesTableRowProps{
  sales: ISalesComplex,
  onPreviewContact: (e: ISalesComplex) => void,
  onPreviewDetails: (e: ISalesComplex) => void,
}
const SalesTableRow = ({sales, onPreviewContact, onPreviewDetails}: SalesTableRowProps) => {
  const operation = sales.operation;
  const contact = sales.contact;
  const details = sales.details;
  const client = `${contact.firstName} ${contact.lastName}`;
  const items = details.length;

  const clientCommOper = (oper: number) => {
    if(oper===0){
      window.location.href = `mailto:${contact.email}`;;
    }else{
      window.location.href = `tel:${contact.phone}`;
    }
  }

  return (
    <tr>
      <td>
        <div className="flex flex-col gap-1">
          <div className='hidden md:block'>
            <WiseDropDown
              position="left"
              hasDropdown={false}
              options={[
                { id: "0", label: languages.sendEmail },
                { id: "1", label: languages.placeCall },
              ]}
              onSelected={(e) => {
                clientCommOper(Number(e.id));
              }}
            >
              <span className="text-blue-500 hover:text-blue-700">{client}</span>
            </WiseDropDown>
          </div>
          <div className='md:hidden flex items-center gap-2'>
            <span>{client}</span>
            <a href={`mailto:${contact.email}`} 
              className='text-blue-500 hover:text-blue-700 hover:font-semibold'
              title={languages.sendEmail}>
              <i className='fa fa-envelope-o'></i>
            </a>
            <a href={`tel:${contact.phone}`} 
              className='text-blue-500 hover:text-blue-700 hover:font-semibold'
              title={languages.placeCall}>
              <i className='fa fa-phone'></i>
            </a>
          </div>

          <span className="md:hidden flex items-center gap-1">
            <i className="fa fa-calendar-o"></i>
            {moment(operation.operationDate).format("DD/MM/YYYY HH:mm")}
          </span>

          {/* items */}
          <span
            className="sm:hidden text-sky-500 hover:text-sky-700 cursor-pointer hover:underline hover:font-semibold"
            onClick={() => onPreviewDetails(sales)}
            title={languages.viewDetails}>
            <span className='flex items-center gap-3'>
              <i className='fa fa-list'></i>
              <span>{languages.items}&nbsp;{appFxs.formatNumbers(items)}</span>
            </span>
          </span>          
        </div>
      </td>

      {/* date */}
      <td className="hidden md:table-cell">
        {moment(operation.operationDate).format("DD/MM/YYYY HH:mm")}
      </td>

      {/* items */}
      <td className="hidden sm:table-cell text-right">
        <span
        title={languages.viewDetails}
          className="text-sky-500 hover:text-sky-700 cursor-pointer hover:underline hover:font-semibold"
          onClick={() => onPreviewDetails(sales)}
        >
          {appFxs.formatNumbers(items)}
        </span>
      </td>

      {/* total */}
      <td className="text-right">
        <span className="text-[12px] font-semibold">Rwf</span>&nbsp;
        {appFxs.formatNumbers(operation.total)}
      </td>

      {/* actions */}
      <td>
        <div className="flex items-center gap-1 justify-end">
          <button
            className="border border-solid border-sky-500 rounded-md py-1 px-2 inline-block"
            title={languages.viewDetails}
            onClick={() => onPreviewDetails(sales)}
          >
            <i className="fa fa-list text-sky-500"></i>
          </button>
          <button
            className="border border-green-500 rounded-md py-1 px-2"
            onClick={() => onPreviewContact(sales)}
            title={languages.viewCustomer}
          >
            <i className="fa fa-user-o text-green-500"></i>
          </button>
        </div>
      </td>
    </tr>
  );
}