import appDeclarations from "./declarations";

const getAppLang = (): string => {
    const elt = localStorage.getItem(appDeclarations.currentLang);
    return elt ?? 'en';
}

const retLang = (enCont: string, frCont: string, rwCont: string = '') :string =>{
    const lang =  getAppLang();

    return lang === 'en' ? enCont:
    lang === 'fr' ? frCont:
    lang === 'rw' ? rwCont:
    enCont;
}

function GetLanguage() {
    
    return {
        loading: retLang('Loading','Chargement',''),
        login: retLang('Login','Connection',''),
        loggin: retLang('Logging In','Connection',''),

        password: retLang('Password','Mot de passe',''),
        signInTo: retLang('Sign in to','Connectez-vous à',''),
        signIn: retLang('Sign in','Connection',''),
        signOut: retLang('Sign out','Se deconnecter',''),
        signOutMsg: retLang(
            'Do you want to sign out?',
            'Voulez-vous vous déconnecter?',''),
        rememberMe: retLang('Remember me','Souviens-toi de moi',''),
        forgotPassWord: retLang('Forgot password?','Mot de passe oublié?',''),
        appName: retLang('Alumni App','Alumni App',''),
        dashboard: retLang('Dashboard','Tableau de bord',''),
        blog: retLang('Blog','Journal',''),
        socialevents: retLang('Social events','Evénements sociaux',''),
        messages: retLang('Messages','Messages',''),
        events: retLang('Events','Evénements',''),
        calendar: retLang('Calendar','Calendrier',''),
        menu: retLang('Menu','Menu',''),

        emailEx: retLang('you@example.com','vous@exemple.com',''),
        reset: retLang('Reset','Réinitialiser',''),
        logInInstead: retLang('I have account, log in instead','J\'ai déja un compte. Veuillez me connecter',''),
        resetPassword: retLang('Reset your password','Modifier votre mot de passe',''),
        setPasswordWithNew: retLang('Set your password with a new one','Configurez votre mot de passe avec un nouveau',''),
        confirmPasword: retLang('Confirm your password','Confirmer votre mot de passe',''),
        weak: retLang('Weak','Faible',''),
        good: retLang('Good','Bon',''),
        strong: retLang('Strong','Fort',''),
        very_strong: retLang('Very strong','Très fort',''),
        passwordReqEmailSent: retLang('Waiting for password reset', 'En attente de la réinitialisation du mot de passe', ''),
        checkUrEmail: retLang('Check your email', 'Vérifiez votre courrier électronique.', ''),
        checkUrEmailPasswordReset: retLang(
            'Check your email for password reset process.',
            'Vérifiez votre courrier électronique pour le processus de réinitialisation du mot de passe.', ''),

        deleteImgQst: retLang('Do you want to remove this image?', 'Voulez-vous supprimer cette image?', ''),
        
        users: retLang('Users', 'Utilisateurs', ''),
        networkError: retLang('Network error', 'Erreur de réseau', ''),
        networkErrorMsg: retLang(
        'There is a network error on this device. Check this issue and try again.', 
        'Il y a une erreur réseau sur cet appareil. Vérifiez ce problème et réessayez.', ''),
        fldRequired: retLang('This field is required', 'Ce champ est obligatoire', ''),
        product: retLang('Product', 'Produit', ''),
        productMsg: retLang(
            'You can bring some changes to this product and change its images.', 
            'Vous pouvez apporter quelques modifications à ce produit et modifier ses images.', 
            ''),
        emailFormat: retLang('The email format seems wrong.', 'Le format de l\'e-mail semble incorrect.', ''),
        passLengthMsg: retLang('Password must be at least 8 characters.', 
            'Le mot de passe doit comporter au moins 8 caractères.', ''),
        passValidMsg: retLang('The password strength is too weak to be valid.', 
            'Le niveau de sécurité du mot de passe est trop faible pour être valide.', ''),
        sameAsPasswordMsg: retLang('You are unable to confirm your password correctly.', 
            'Vous n\'arrivez pas à confirmer votre mot de passe correctement.', ''),
        
        phonenbrLengthMsg: retLang('The phone number must be 12 caracters long.', 
            'Le numéro de téléphone doit être de 12 caractères.', ''),



        jan_lng: retLang('January', 'Janvier', 'Mutarama'),
        feb_lng: retLang('February', 'Février', 'Gashyantare'),
        mar_lng: retLang('March', 'Mars', 'Werurwe'),
        apr_lng: retLang('April', 'Avril', 'Mata'),
        may_lng: retLang('May', 'Mai', 'Gicurasi'),
        jun_lng: retLang('Jun', 'Juin', 'Kamena'),
        jul_lng: retLang('July', 'Juillet', 'Nyakanga'),
        aug_lng: retLang('August', 'Août', 'Kanama'),
        sept_lng: retLang('September', 'Septembre', 'Nzeri'),
        oct_lng: retLang('October', 'Octobre', 'Ukwakira'),
        nov_lng: retLang('November', 'Novembre', 'Ugushyingo'),
        dec_lng: retLang('December', 'Decembre', 'Ukuboza'),

        jan_shrt: retLang('Jan', 'Janr', 'Muta'),
        feb_shrt: retLang('Feb', 'Fév', 'Gashy'),
        mar_shrt: retLang('Mar', 'Mars', 'Weru'),
        apr_shrt: retLang('Apr', 'Avr', 'Mata'),
        may_shrt: retLang('May', 'Mai', 'Gicu'),
        jun_shrt: retLang('Jun', 'Juin', 'Kame'),
        jul_shrt: retLang('July', 'Juil', 'Nyaka'),
        aug_shrt: retLang('Aug', 'Août', 'Kana'),
        sept_shrt: retLang('Sept', 'Sept', 'Nzeri'),
        oct_shrt: retLang('Oct', 'Oct', 'Ukwa'),
        nov_shrt: retLang('Nov', 'Nov', 'Ugus'),
        dec_shrt: retLang('Dec', 'Dec', 'Uku'),

        monday_lng: retLang('Monday', 'Lundi', ''),
        tuesday_lng: retLang('Tuesday', 'Mardi', ''),
        wednday_lng: retLang('Wednesday', 'Mercredi', ''),
        thursday_lng: retLang('Thursday', 'Jeudi', ''),
        friday_lng: retLang('Friday', 'Vendredi', ''),
        saturday_lng: retLang('Saturday', 'Samedi', ''),
        sunday_lng: retLang('Sunday', 'Dimance', ''),

        monday_shrt: retLang('Mon', 'Lun', ''),
        tuesday_shrt: retLang('Tue', 'Mar', ''),
        wednday_shrt: retLang('Wed', 'Mer', ''),
        thursday_shrt: retLang('Thu', 'Jeu', ''),
        friday_shrt: retLang('Fri', 'Ven', ''),
        saturday_shrt: retLang('Sat', 'Sam', ''),
        sunday_shrt: retLang('Sun', 'Dim', ''),

        other: retLang('Other', 'Autre', ''),
        activate: retLang('Activate', 'Activer', ''),
        deactivate: retLang('Disable', 'Désactiver', ''),
        activate_qst: retLang('Would you like to activate this user?', 'Voudriez-vous activer cet utilisateur?', ''),
        deactivate_qst: retLang('Would you like to Disable this user?', 'Voudriez-vous désactiver cet utilisateur?', ''),
        activated: retLang('Activated', 'Activé', ''),
        deactivated: retLang('Disabled', 'Désactivé', ''),
        active: retLang('Active', 'Actif', ''),
        inactive: retLang('Inactive', 'Inactif', ''),
        status: retLang('Status', 'Etat', ''),

        sign_up: retLang('Sign Up', 'S\'inscrire', ''),
        sign_out: retLang('Sign out', 'Se déconnecter', ''),

        print_prev: retLang('Print preview', 'Aperçu avant impression', ''),
        just_print: retLang('Just print', 'Imprimer', ''),
        export_to_msword: retLang('Export to Ms Word', 'Exporter vers Ms Word', ''),
        export_to_msexcel: retLang('Export to Ms Excel', 'Exporter vers Ms Excel', ''),
        export_to_pdf: retLang('Export to PDF', 'Exporter vers PDF', ''),
        export_to_image: retLang('Export to Image', 'Exporter vers l\'image', ''),
        compose_email_content: retLang('Compose email content', 'Ecrire le contenu du message', ''),
        email_recipient: retLang('Email recipient', 'Destinataire du courriel', ''),
        email_subject: retLang('Email subject', 'Objet du message', ''),
        email_content: retLang('Email content', 'Contenu du message', ''),
        compose_email: retLang('Compose email', 'Composer un email', ''),
        add_recipient: retLang('Add recipient', 'Ajouter destinataire', ''),
        from: retLang('From', 'De', ''),
        subject: retLang('Subject', 'Objet', ''),
        
        add_new_rec_email: retLang('Add new recipient email', 'Ajouter un nouvel e-mail de destinataire', ''),
        add_new_rec_email_cc: retLang('Add new recipient email (CC)', 'Ajouter un nouvel e-mail de destinataire (CC)', ''),
        add_new_rec_email_bcc: retLang('Add new recipient email (BCC)', 'Ajouter un nouvel e-mail de destinataire (BCC)', ''),

        show_recipients_cc: retLang('Show recipients input area (CC)', 'Afficher la zone de saisie des destinataires (CC)', ''),
        show_recipients_bcc: retLang('Show recipients input area (BCC)', 'Afficher la zone de saisie des destinataires (BCC)', ''),
        hide_recipients_cc: retLang('Hide recipients input area (CC)', 'Masquer la zone de saisie des destinataires (CC)', ''),
        hide_recipients_bcc: retLang('Hide recipients input area (BCC)', 'Masquer la zone de saisie des destinataires (BCC)', ''),

        email_failure_sent: retLang(
            'Your email has not been sent successfully. Kindly check the related Email account and try again.', 
            'Votre Email n\'a pas été envoyé avec succès. Veuillez vérifier le compte Email associé et réessayer.', ''),
        
        countr_miss_msg: retLang(
            'The related country seems missing from the database. Try again.',
            'Le pays concerné semble absent de la base de données. Essayer à nouveau.', ''),
        success: retLang('Success', 'Réussite', ''),
        sms_failure_sent: retLang(
            'Your SMS has not been sent successfully. Kindly check the related SMS account and try again.', 
            'Votre SMS n\'a pas été envoyé avec succès. Veuillez vérifier le compte SMS associé et réessayer.', ''),
        sms_success_sent: retLang('SMS sent with success', 'SMS envoyé avec succès', ''),
        email_sent_success: retLang('Email sent with success', 'Email envoyé avec succès', ''),

        terms_m: retLang('Terms', 'Termes', ''),
        cookies_m: retLang('Cookies', 'Cookies', ''),
        privacy_m: retLang('Privacy', 'Vie privée', ''),
        contactus_m: retLang('Contact Us', 'Nous contacter', ''),
        terms_serv: retLang('Terms of Service', 'Conditions d\'utilisation', ''),
        priv_policy: retLang('Privacy Policy', 'Politique de confidentialité', ''),
        all_rights_reserved: retLang('All Rights Reserved', 'Tous droits reservés', ''),
        cookie_policy: retLang('Cookie Policy', 'Politique de cookie', ''),
        waitServerResp: retLang('Waiting for server response...', 'Veuillez patienter la reponse du serveur...', ''),
        
        english_m: retLang('English', 'Anglais', ''),
        french_m: retLang('French', 'Français', ''),
        rwanda_m: retLang('Kinyarwanda', 'Kinyarwanda', ''),
        swahili_m: retLang('Swahili', 'Swahili', ''),
        lingala_m: retLang('Lingala', 'Lingala', ''),
        other_m: retLang('Other language', 'Autre langue', ''),
        reload: retLang("Reload", "Recharger", ''),
        cancel: retLang("Cancel", "Annuler", ''),
        yes: retLang("Yes", "Oui", ''),
        messageDate: retLang("Message date", "Date message", ''),
        networkName: retLang("Network name", "Nom du réseau", ''),
        home: retLang("Home", "Acceuil", ''),
        search: retLang("Search", "Chercher", ''),
        saving: retLang("Saving", "Enregistrement", ''),
        ourPartners: retLang("Our partners", "Nos Partenaires", ''),
        clcickHereToSelect: retLang("Click here to select", "Cliquez ici pour sélectionner", ''),
        subscribe: retLang("Subscribe", "S'abonner", ''),
        subscribers: retLang("Subscribers", "Abonnés", ''),
        yourEmailHere: retLang("Your email here", "Mettez votre email içi", ''),
        schoolName: retLang("Alumni - La Colombière", "Alumni - La Colombière", ''),
        schoolNameOnly: retLang("La Colombière School", "Ecole - La Colombière", ''),
        close: retLang("Close", "Fermer", ''),
        clickHere: retLang("Click here", "Cliquez içi", ''),
        noOptionsFound: retLang("No options found", "Aucune options trouvées", ''),
        chooseOption: retLang("Choose option", "Choisir une option", ''),
        signInToApp: retLang("Sign in to Alumni", "Connectez-vous - Alumni", ''),
        signInToManagtApp: retLang("Sign in to Management", "Connectez-vous - Management", ''),
        enterUrEmailPw: retLang("Enter your email and password", "Saisissez votre email et votre mot de passe", ''),
        enterUrEmail: retLang("Enter your email", "Saisissez votre email", ''),
        yourEmail: retLang("Your email", "Votre email", ''),
        yourPassword: retLang("Your password", "Votre mot de passe", ''),
        logInstead: retLang("Log in instead", "Se connecter plutôt", ''),
        recaptchaError: retLang("There's a security error in your form.", 
            "Il y a une erreur de sécurité dans votre formulaire.", ''),
        secCheckError: retLang("Captcha error. Try again", "Erreur CAPTCHA. Essayer à nouveau", ''),
        setNewPassword: retLang("Set new password", "Definir un nouveau mot de passe", ''),
        newPassword: retLang("New password", "Nouveau mot de passe", ''),
        setPassword: retLang("Set password", "Definir mot de passe", ''),
        provideNewPassword: retLang("Provide new password", "Fournir un nouveau mot de passe", ''),
        is: retLang("is", "est", ''),
        waitPwSet: retLang("Waiting for password to be set.", "En attente de définition du mot de passe", ''),
        loadingData: retLang("Loading data", "Chargement des données", ''),
        dashboardUserMsg: retLang(
            "Track KPIs, metrics, and other data points in one visual, central place.",
            "Suivez les Indicateurs clés, les mesures et autres points de données dans un endroit visuel et central.",
            ""),
        details: retLang("Details", "Détails", ''),
        listBlogs: retLang("List of posts", "Liste des publications", ''),
        listBlogsMsg: retLang(
            "List of publications you have published; follow in detail the reactions of people who liked your stories and be open to their criticism.", 
            "Liste des publications que vous avez publiées; suivez en détail les réactions des personnes qui ont aimé vos histoires et soyez ouvert à leurs critiques.", ''),
        title: retLang("Title", "Titre", ''),
        date: retLang("Date", "Date", ''),
        tags: retLang("Tags", "Mots clés", ''),
        comments: retLang("Comments", "Commentaires", ''),
        published: retLang("Published", "Publié", ''),
        unpublished: retLang("Unpublished", "non publié", ''),
        publish: retLang("Publish", "Publier", ''),
        newBlog: retLang("New blog", "Nouveau blog", ''),
        blogSubjectEx: retLang("ex: Shoes shopping", "ex: Achats de chaussures", ''),
        blogTitleEx: retLang("ex: Shoes price are skyrocketing", "ex: Le prix des chaussures monte en flèche", ''),
        newBlogMsg: retLang(
            "Compose a blog telling the world about your daily journey and let them respond to your blog post. These blogs can be found by search engines. These articles will be published on the La Colombière website. You will be notified if people react to your messages.", 
            "Créez un blog racontant au monde votre voyage quotidien et laissez-le répondre à votre article de blog. Ces blogs peuvent être trouvés par les moteurs de recherche. Ces articles seront publiés sur le site Internet de La Colombière. Vous serez averti si les gens réagissent à vos messages.", ''),
        noTagsYet: retLang('No tags yet', 'Pas d\'étiquettes'),
        remove: retLang('Remove', 'Retirer'),
        featuredImage: retLang('Featured image', 'Image en vedette'),
        dialogBoxTitle: retLang('Dialog box title', 'Titre de la boîte de dialogue'),
        selectFileFromComp: retLang('Select a file from your computer', 'Sélectionnez un fichier sur votre ordinateur'),
        selectFileFromPexels: retLang('Pick an image from pexels website', 'Choisissez une image sur le site Web de Pexels'),
        searchFromPexels:retLang('Search from Pexels website', 'Rechercher sur le site Web Pexels'),
        waitingforPexelsServer:retLang('Waiting for Pexels server', 'En attente du serveur Pexels'),
        waitingforServerResp:retLang('Waiting for server response', 'En attente de la reponse du serveur'),
        photographer:retLang('Photographer', 'Photographe'),
        typeHere:retLang('Type here', 'Tapez içi'),
        postContent:retLang('Blog post content', 'Contenu de l\'article de blog'),
        previewContent:retLang('Preview content', 'Visualisez le contenu'),
        hideContent:retLang('Hide content', 'Cacher le contenu'),
        copyOnlineImagesLink:retLang('Search images and copy links', 'Rechercher des images et copier des liens'),
        photoImage:retLang('Photo or Image link', 'Photo ou video'),
        searchByImgs:retLang('Search images', 'Cherchez les images'),
        searchByVidz:retLang('Search vidéos', 'Cherchez les vidéos'),
        photoImageLinkCp:retLang(
            'Your media link has been copied to the clipboard. Jost paste by using CTRL+V (windows)/Cmd+V(Mac Os).', 
            'Votre lien média a été copié dans le presse-papiers. Collez simplement en utilisant CTRL+V (windows)/Cmd+V(Mac Os).'),
        
        listOfblogs:retLang('List of blogs', 'Liste des blogs'),
        blogs:retLang('Blogs', 'Blogs'),
        noblogFnd:retLang('No blog found', 'Aucun blog trouvé'),
        loadingBlogs:retLang('Loading blogs', 'Chargement des blogs'),
        save:retLang('Save', 'Enregistrer'),
        warning:retLang('Warning', 'Avertissement'),
        next: retLang("Next", "Suivant", ''),
        previous: retLang("Previous", "Précédent", ''),
        deleteComment: retLang("Delete comment", "Supprimer commentaire", ''),
        warningPostTags:retLang(
            'Blogs need tags and it appears that you have not set any. Add at least one and try again.',
            'Les blogs ont besoin de balises et il semble que vous n\'en ayez défini aucune. Ajoutez-en au moins un et réessayez.'),
        warningPostFimg:retLang(
            'You need to set a featured image before saving this blog post.',
            'Vous devez définir une image sélectionnée avant d\'enregistrer cet article de blog.'),
        warningPostTitle:retLang(
            'The post title field seems empty. Provide a meaningful title to your blog post and try again.',
            'Le champ du titre du message semble vide. Donnez un titre significatif à votre article de blog et réessayez.'),
        warningPostSubject:retLang(
            'The post subject field seems empty. Provide a meaningful subject to your blog post and try again.',
            'Le champ Objet du message semble vide. Donnez un Sujet significatif à votre article de blog et réessayez.'),
        warningPostContent:retLang(
            'You haven\'t yet typed your story. Type a story before posting it. Otherwise Administrators shall remove it so that it does not appear online.',
            'Vous n\'avez pas encore écrit votre histoire. Tapez une histoire avant de la publier. Dans le cas contraire, les administrateurs devront le supprimer afin qu\'il n\'apparaisse pas en ligne.'),
        publishPostMsg:retLang(
            'Do you want to publish this blog post?',
            'Voulez-vous publier cet article de blog ?'),
        delete:retLang("Delete","Supprimer",""),
        event:retLang("Event","Événement",""),
        nbr:retLang("Number","Nombre",""),
        edit:retLang("Edit","Modifier",""),
        typeDescr:retLang("Type description here","Saisissez la description içi",""),
        description:retLang("Description","Description",""),
        nbrPics:retLang("Number","Nombre",""),
        newSocialEvent:retLang("New social event","Nouvel événement social",""),
        fieldError:retLang("Input field error","Erreur dans le champ de saisie",""),
        fieldErrorDesc:retLang("Some record have empty description field.","Certains enregistrements ont un champ de description vide.",""),
        fieldErrorTitle:retLang("Some record have empty title field.","Certains enregistrements ont un champ de titre vide.",""),
        loadSocEvts:retLang("Loading social events","Chargement des événements sociaux",""),
        deleteCommentMsg:retLang("Do you want to delete this blog comment?","Voudriez-vous supprimer ce commentaire du blog?",""),
        previewComments:retLang("Preview comments","Aperçu des commentaires",""),
        socialEventsMsg:retLang(
            "Post photos of your vacations, parties and important events. Appreciate the reactions of your former colleagues.",
            "Postez des photos de vos vacances, de vos fêtes et des évenements importants. Appreciez les réactions de vos anciens collègues.",""),
        newSocialEventMsg:retLang(
            "Upload photos from your social event, comment on them and let people enjoy your photos and your social routine.",
            "Téléchargez des photos de votre événement social, commentez-les et laissez les gens profiter de vos photos et de votre routine sociale.",""),
        deleteSocEventDet:retLang("Do you want to delete this social event detail?","Voudriez-vous supprimer ce détail en rapport avec cet événement social?",""),
        eventTitle:retLang("Event title","Titre de l'événement",""),
        typeEventTitle:retLang("Type event title here","Saisissez le titre de l'événement",""),
        location:retLang("Location","Emplacement",""),
        typeEventLoc:retLang("Type event location here","Saisissez l'emplacement de l'événement",""),
        tagHolderText:retLang("Type and press enter to inser a new tag","Tapez et appuyez sur Entrée pour insérer une nouvelle balise",""),
        locTitleReqMsg:retLang(
            "Location and Title fields are required. Fill them and try again.",
            "Les champs Emplacement et Titre sont obligatoires. Remplissez-les et réessayez.",""),
        messagesMsg:retLang(
            "Send to your former classmates private and encrypted messages, they can answer and share their impressions.",
            "Envoyez à vos anciens camarades de classe des messages privés et cryptés, ils pourront y répondre et partager leurs impressions.",""),
        recipient:retLang("Recipient","Destinataire",""),
        recipients:retLang("Recipients","Destinataires",""),
        loadingMessages:retLang("Loading messages","Chanrgement des messages",""),
        noMsgFnd:retLang("No messages found","Aucun message trouvé",""),
        newMessage:retLang("New message","Nuveau message",""),
        newMessageMsg: retLang(
            "Compose a message to send to several recipients among your former classmates.", 
            "Composez un message à envoyer à plusieurs destinataires parmi vos anciens camarades de classe.", ''),
        noAttachts:retLang("No attachments","Pas des fichiers joints",""),
        typeUrSubject:retLang("Type your subject here","Tapez ici votre sujet",""),
        showMediaFiles:retLang("Show media files","Afficher les fichiers multimédias",""),
        addMoreFiles:retLang("Add more files", "Ajouter plus de fichiers", ""),
        addMorePictures:retLang("Add more pictures", "Ajouter plus de photos", ""),
        addOnePictureTime:retLang("Add one picture at a time", "Add one image at a time", ""),
        imageProdAddQst:retLang(
            "Do you want to add this picture file to this product?", 
            "Voulez-vous ajouter ce fichier image à ce produit ?", ""),
        mediaFiles:retLang("Media files", "Fichiers multimedia", ""),
        noMediaFiles:retLang(
            "No media files were found. Download a few before using this feature.", 
            "Aucun fichier multimédia n'a été trouvé. Téléchargez-en quelques-uns avant d'utiliser cette fonctionnalité.", ""),
        uploadFiles:retLang("Upload files", "Télécharger des fichiers", ""),
        files:retLang("Files", "Fichiers", ""),
        noFilesSelected:retLang("No files have been selected. Try again.", "Aucun fichier n'a été sélectionné. Essayer à nouveau.", ""),
        loadingFiles:retLang("Loading media files", "Chargement des fichiers multimedia", ""),
        sku:retLang("SKU", "UGS", ""),
        skuV2:retLang("Stock keeping unit", "Unité de gestion de stock", ""),
        productName:retLang("Product name", "Nom produit", ""),
        
        to:retLang("To", "A", ""),end:retLang("End", "Fin", ""),
        inbox:retLang("Inbox", "Bte réception", ""),
        sent:retLang("Sent", "Envoyés", ""),
        deleted:retLang("Deleted", "Supprimés", ""),
        preview:retLang("Preview", "Visualisez", ""),
        markAsRead:retLang("Mark as read", "Marquer comme lu", ""),
        markAsUnread:retLang("Mark as unread", "Marquer comme non lu", ""),
        deleteMessage:retLang("Do you want to delete this message?", "Voulez-vous supprimer ce message?", ""),
        undeleteMessage:retLang("Do you want to un-delete this message?", "Voulez-vous annuler la suppression de ce message?", ""),
        undelete:retLang("Undelete", "Annuler la suppression", ""),
        eventColor:retLang("Event color", "Couleur de l'événement", ""),
        default:retLang("Default", "Par défaut", ""),
        hasEndDate:retLang("Has end date", "A une date de fin", ""),
        dateEndError:retLang("Kindly check date of end vs date of start.", "Veuillez vérifier les paramétrages des dates.", ""),

        messageContentReq:retLang(
            "Hey there! It seems you forgot to type your message body. Try again.", 
            "Salut! Il semble que vous ayez oublié de saisir le corps de votre message. Essayer à nouveau.", ""),
        messageSubjectReq:retLang(
            "Hey there! It seems you forgot to type your message subject. Try again.", 
            "Salut! Il semble que vous ayez oublié de saisir l'objet de votre message. Essayer à nouveau.", ""),
        messageReceipientsReq:retLang(
            "Hey there! It seems you forgot the recipients. Try again.", 
            "Salut! Il semble que vous ayez oublié les destinataires. Essayer à nouveau.", ""),
        attachments:retLang("Attachements", "Pièces jointes", ""),
        in:retLang("In", "Dans", ""),
        start:retLang("Start", "Debut", ""),
        calendarEvent:retLang("Calendar event", "Événement calendrier", ""),
        calendarEvents:retLang("Calendar events", "Événements calendrier", ""),
        calendarEventsMsg:retLang(
            "Your calendar event will trigger a notification before the event date so that you stay up to date with other alumni student.", 
            "Votre événement de calendrier déclenchera une notification avant la date de l'événement afin que vous restiez au courant des autres anciens étudiants.", ""),
        deleteCalEvtMsg:retLang("Do you want to delete this calendar event?", "Voudriez-vous supprimer cet événement calendrier.", ""),
        firstName:retLang("First name", "Prénom", ""),
        lastName:retLang("Last name", "Nom", ""),
        birthDate:retLang("Birth date", "Date de naissance", ""),
        phoneNbr:retLang("Phone number", "N° de téléphone", ""),
        myProfile:retLang("My profile", "Mon profile", ""),
        sex:retLang("Sex", "Sexe", ""),
        female:retLang("Female", "Féminin", ""),
        address:retLang("Address", "Adresse", ""),
        yearCompleted:retLang("Year completed", "Année completée", ""),
        completedDegree:retLang("Completed degree", "Diplôme obtenu", ""),
        wrongFieldValue:retLang("Wrong field value", "Valeur de champ incorrecte", ""),
        male:retLang("Male", "Masculin", ""),
        myProfileMsg:retLang(
            "Kindly identify yourself so other alumni students can recognize you.", 
            "Veuillez vous identifier pour que les autres anciens étudiants puissent vous reconnaitre.", ""),
        social:retLang("Social", "Social", ""),
        view:retLang("View", "Afficher", ""),
        serverError:retLang("Server error", "Erreur serveur", ""),
        language:retLang("Language", "Langue", ""),
        year:retLang("Year", "Année", ""),
        unfollow:retLang("Unfollow", "Pas suivre", ""),
        follow:retLang("Follow", "Suivre", ""),
        alumni:retLang("Alumni", "Anciens élèves", ""),
        alumniMembers:retLang("Alumni members", "Membres - Anciens élèves", ""),
        alumniMember:retLang("Alumni member", "Membre - Ancien élève", ""),
        job:retLang("Job", "Travail", ""),
        unEmployed:retLang("Unemployed", "Chaumeur", ""),
        members:retLang("Members", "Membres", ""),
        sender:retLang("Sender", "Expéditeur", ""),
        mailingList:retLang("Mailing list", "Liste de diffusion", ""),
        mailingListMsg:retLang(
            "You can review the list of subscribers and send them mails accordingly.", 
            "Vous pouvez consulter la liste des abonnés et leur envoyer des e-mails en conséquence.", ""),
        mailingListShort:retLang("Mailing", "Diffusion", ""),
        loadAlumniMembers:retLang("Loading alumni members", "Chargement des membres Alumni", ""),
        previewAlumniMember:retLang(
            "Preview the details of an Alumni member before activating his/her account.", 
            "Veuillez vérifier les détails de cet ancien élève avant d'activer son compte.", ""),
        alumniMembersMsg:retLang(
            "List of registered former students. You can activate or deactivate them, or even permanently suspend them.", 
            "Liste des anciens élèves qui se sont inscrits. Vous pouvez les activer ou les désactiver, voir les suspendre définitivement.", ""),
        refresh:retLang("Refresh", "Recharger", ""),
        itemsPerList:retLang("Items per list", "Éléments par liste", ""),
        savingSettings:retLang("Saving settings", "Enregistrement des paramètres", ""),
        settings:retLang("Settings", "Paramètres", ""),
        name:retLang("Name", "Nom", ""),
        email:retLang("Email", "Email", ""),
        dob:retLang("DOB", "D. Naiss.", ""),
        settingsMsg:retLang(
            "Configure your software so that it can work according to your preferences.", 
            "Paramétrer votre logiciel pour qu'il puisse fonctionner selon vos préférences.", ""),
        loadingRecipients:retLang("Loading recipients", "Chargement des destinataires", ""),
        management:retLang("Management", "Gestion", ""),
        deleteRecordQst:retLang("Do you want to delete this record?", "Voulez-ous supprimer cet enregistrement?", ""),
        noRecFnd:retLang("No record found", "Aucun enregistrement trouvé", ""),
        usersMsg:retLang(
            "You can invite other employees to join you as users with limited rights.", 
            "Vous pouvez inviter d'autres employés à vous joindre comme utilisateurs avec des droits limités.", ""),
        enableUser:retLang("Enable user", "Activer utilisateur", ""),
        disableUser:retLang("Disable user", "Désactiver utilisateur", ""),
        enableUserMsg:retLang(
            "Do you want to activate this user?", 
            "Voulez-vous activer cet utilisateur?", ""),
        disableUserMsg:retLang(
            "Do you want to de-activaye this user?", 
            "Vulez-vous désactiver cet utilisateur", ""),
        addNewUser:retLang("Add new user", "Ajouter nouvel utilisateur", ""),
        editUser:retLang("Edit user", "Modifier utilisateur", ""),
        administrator:retLang("Administrator", "Administrateur", ""),
        joinTeam:retLang("Join team", "Joindre l'équipe", ""),
        confirmAndSetPassword:retLang("Confirm and set password", "Confirmer et définir le mot de passe", ""),
        blogsMsg:retLang(
            "When you create a blog with <strong>La Colombire <a href=\"https://blog.lacolombiere.ac.rw\" target=\"_blank\">Blog Engine</a></strong>, you must inform the application of management so that it displays on the main website. Otherwise, people visiting your website will not be informed of any changes.", 
            "Lorsque vous créez un blog avec <strong>La Colombire <a href=\"https://blog.lacolombiere.ac.rw\" target=\"_blank\">Blog Engine</a></strong>, vous devez en informer l'application de gestion afin qu'il l'affiche sur le site Web principal. Sinon, les personnes visitant votre site Web ne seront informées d'aucun changement.", ""),
        author:retLang("Author", "Auteur", ""),
        addNewBlog:retLang("Add new blog", "Ajouter nouveau blog", ""),
        editBlog:retLang("Edit blog", "Modifier blog", ""),
        notSpecified:retLang("Not specified", "Non spécifié", ""),
        imageUrl:retLang("Image URL", "Adresse image", ""),
        blogPathUrl:retLang("Blog web address", "Adresse web du blog", ""),
        selectAuthor:retLang("Select author", "Sélectionne l'auteur", ""),
        blogColor:retLang("Blog color", "Couleur du blog", ""),
        unpublish:retLang("Unpublish", "Annuler la publication", ""),
        publishBlog:retLang("Publish blog", "Publier l'article", ""),
        publishBlogMsg:retLang(
            "Do you want to publish this blog online?", 
            "Voulez-vous publier cet article en ligne?", ""),
        unPublishBlog:retLang("Unpublish blog", "Supprimer la publication du blog", ""),
        unPublishBlogMsg:retLang(
            "Do you want to unpublish this blog?", 
            "Voulez-vous supprimer cette publication en ligne?", ""),
        color_1:retLang("Color 1", "Couleur 1", ""),
        color_2:retLang("Color 2", "Couleur 2", ""),
        color_3:retLang("Color 3", "Couleur 3", ""),
        color_4:retLang("Color 4", "Couleur 4", ""),
        color_5:retLang("Color 5", "Couleur 5", ""),

        activateAlumniMember:retLang(
            "Would you like to activate this alumni member?", 
            "Voudriez-vous activer ce membre des anciens élèves?", 
            ""),
        deActivateAlumniMember:retLang(
            "Would you like to de-activate this alumni member?", 
            "Voudriez-vous désactiver ce membre des anciens élèves?", 
            ""),
        noMailListFnd:retLang(
            "No mail has been sent to mailing subscribers. Compose now.", 
            "Aucun courrier n'a été envoyé aux abonnés mailing. Composez maintenant.", 
            ""),
        noMailListMembersFnd:retLang(
            "No subscribers to La Colombière mailing list has been found.", 
            "Aucun abonné à la liste de diffusion de La Colombière n'a été trouvé.", 
            ""),
        composeNewMail:retLang("Compose new mail", "Composer un nouveau courrier", ""),
        composeNewMailMsg:retLang(
            "This email you compose will be sent to all mailing subscribers.", 
            "Cet email sera envoyé à tous les abonnés au de la messagerie.", ""),
        mailAddress:retLang("Mail address", "Edresse email", ""),
        send:retLang("Send", "Envoyer", ""),
        sendMailListQst:retLang(
            "Do you want to send this email to subscribers?", 
            "Voulez-vous envoyer cet email à tous les abonnés.", ""),
        subscrDate:retLang("Subscription date", "Date abonnement", ""),
        previewMessage:retLang("Preview message", "Affichage du message", ""),

        price:retLang("Price", "Prix", ""),
        discounted:retLang("Discounted", "Prix reduit", ""),
        category:retLang("Catgeory", "Catégory", ""),
        noProdFound:retLang(
            "No product has been found in our database. Create a new one.", 
            "Aucun produit n'a été trouvé dans notre base de données. Créez-en un nouveau.", ""),

        previewMessageMsg:retLang(
            "Preview the selected message.", 
            "Affichage du message sélectionné", 
            ""),
        noContactMsgFnd:retLang(
            "No contact message found", 
            "Aucun message de contact n'a été trouve.", 
            ""),
        download:retLang("Download", "Télécharger", ""),
        shop:retLang("Shop", "Boutique", ""),
        sales_ops:retLang("Sales ops", "Opér. ventes", ""),
        lookingFor:retLang("Looking for", "Recherche", ""),
        saveProdQst:retLang("Do you want to save this product?", "Voulez-vous enregistrer ce produit?", ""),
        updateProdQst:retLang(
            "Do you want to update this product?", 
            "Voulez-vous mettre à jour ce produit?", 
            ""),
        prodSkuError:retLang("The product SKU must have 15 characters.", "Le SKU du produit doit comporter 15 caractères.", ""),
        productImageError:retLang(
            "A product must have at least one picture. Select a picture before proceeding.", 
            "Un produit doit avoir au moins une photo. Sélectionnez une image avant de continuer.", ""),
        low:retLang("Low", "Bas", ""),
        medium:retLang("Medium", "Moyen", ""),
        high:retLang("High", "Haut", ""),
        staff:retLang("Staff", "Employé", ""),
        student:retLang("Student", "Elève", ""),
        askAbtKids:retLang("Ask about students", "Renseignez-vous sur les étudiants", ""),
        classRoom:retLang("Class room", "Salle", ""),
        priority:retLang("Priority", "Priorité", ""),
        goback:retLang("Go back", "Retour", ""),
        products:retLang("Products", "Produits", ""),
        salesOpers:retLang("Sales Operations", "Opérations de vente", ""),
        client:retLang("Client", "Client", ""),
        total:retLang("Total", "Total", ""),
        items:retLang("Items", "Article", ""),
        salesDetails:retLang("Sales details", "Détails des ventes", ""),
        salesContact:retLang("Sales contact", "Contact des ventes", ""),
        city:retLang("City", "Ville", ""),
        askKidsMessageMsg:retLang(
            "This message has been sent from Asking about Kids form.", 
            "Ce message a été envoyé à partir du formulaire Demander des informations sur les enfants.", ""),
        viewDetails:retLang("View details", "Afficher détails", ""),
        viewCustomer:retLang("View customer", "Afficher client", ""),
        sendEmail:retLang("Send email", "Envoyer message", ""),
        placeCall:retLang("Place call", "appel téléphonique", ""),
        salesOpersMsg:retLang(
            "Check purchases made online and above all make sure that payments have been made.", 
            "Vérifiez les achats qui ont été effectués en ligne et surtout assurez-vous que les paiements ont été effectués.", ""),
        requiredFields:retLang("Required fields", "Champs requis", ""),
        requiredFieldsMsg:retLang(
            "Some required fields have not yet filled. Try again.", 
            "Certains champs obligatoires ne sont pas encore remplis. Essayer à nouveau.", 
            ""),
        contactMessages:retLang("Contact messages", "Messages de contact", ""),
        contactMessagesMsg:retLang(
            "List all messages that were sent through the main website's contact page. You can respond directly to your contact using this page.", 
            "Répertoriez tous les messages envoyés via la page de contact du site Web principal. Vous pouvez répondre directement à votre contact en utilisant cette page.", ""),

        previewContactMessage:retLang(
            "You can preview messages sent from the contact form through the main web site.", 
            "Vous pouvez prévisualiser les messages envoyés depuis le formulaire de contact via le site Web principal.", ""),

        foodBev: retLang("Food & Beverages", "Nourriture et boissons",""),
        schoolSupplStat: retLang("School Supplies and Stationery", "Fournitures scolaires et papeterie",""),
        clothAccess: retLang("Clothes & Styling Accessories", "Vêtements et accessoires de style",""),
        services: retLang("Services", "Services",""),
        otherAweSome: retLang("Other Awesome Things", "D'autres choses géniales",""),
        discountedPriceErr: retLang(
            "The discounted price must be less than the product price.", 
            "Le prix réduit doit être inférieur au prix du produit.",""),
        available: retLang("Available", "Disponible",""),
        notAvailabe: retLang("Not available", "Non disponible",""),
        newProduct: retLang("New product", "Nouveau produit",""),
        listProds: retLang("List products", "Liste des produits",""),
        images: retLang("Images", "Images",""),
        listProdsMsg: retLang(
            "You can setup new products and they will appear under the Shop menu of your main website.", 
            "Vous pouvez configurer de nouveaux produits et ils apparaîtront dans le menu Boutique de votre site Web principal.",""),
        newProductMsg: retLang(
            "This page helps you how to create a new product. If you want to setup variants, kindly save this product and preview it before proceeding.", 
            "Cette page vous aide à créer un nouveau produit. Si vous souhaitez configurer des variantes, veuillez enregistrer ce produit et prévisualiser-le avant de continuer.",""),
    };
}


export default GetLanguage;