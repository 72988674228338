/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {ThumbsUp, ThumbsDown} from 'lucide-react'
import moment from 'moment';


import Card from 'src/components/Card';
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";
import ManagtBlogForm from 'src/components/managtblogform/ManagtBlogForm';

import { IManagtBlog, IManagtUser } from 'src/models/smarttypes';
import NetServices from 'src/utils/netservices';

const languages = getLanguages();
const appFxs = getFxs();

const defBlogData:IManagtBlog = {
    blogColor: '#ff236c',
    blogAuthorEmail: '',
    blogDate: new Date(),
    blogDescription: '',
    blogImageUrl: '',
    blogKey: '',
    blogLang: 'en',
    blogTitle: '',
    published: false,
    userEmail: '',
    blogPathUrl: ''
}
export default function ListBlogPage() {
  document.title = `${languages.blogs} | ${languages.management} - ${languages.schoolName}`;
    const [isLoading, setLoading] = useState(true);
    const [hastNetError, setNetError] = useState(false);
    const [messageDisplay, setMessageDisplay] = useState(languages.noRecFnd);

    const [formTitle, setFormTitle] = useState(languages.addNewBlog);
    const [formOpen, setFormOpen] = useState(false);

    const [lstOfMgtBlogs, setLstOfMgtBlogs] = useState<IManagtBlog[]>([]);
    const [lstAllUsers, setLstAllUsers] = useState<IManagtUser[]>([]);
    const [connectedUser, setConnectedUser] = useState<IManagtUser | null>(null);

    const [recStatus, setRecStatus] = useState(0);
    const [currentRec, setCurrentRec] = useState<IManagtBlog>({...defBlogData});
    
    const bLoaded = useRef(false);

    const addNewBlog = (e?: IManagtBlog) => {
        setRecStatus(e == null ? 0: 1);
        setCurrentRec(e ?? {...defBlogData, blogAuthorEmail: connectedUser!.emailAddress})
        setFormTitle(e == null ? languages.addNewBlog: languages.editBlog);
        setFormOpen(true);
    };
    const loadListBlogs = async (bAjax: boolean = false) => {
        const oper = await NetServices.requestGet(
          "managt/getlistofallblogs",
          bAjax
        );
        if (!oper.bReturn) {
          setMessageDisplay(oper.msgBody);
          setNetError(true);
          setLoading(false);
          return;
        }
        setMessageDisplay(languages.noRecFnd);
        setLstAllUsers(oper.listManagtUsers!);
        setLstOfMgtBlogs(oper.lstManagtBlogs!);
        setConnectedUser(oper.connectedUser!);

        setCurrentRec({...currentRec, blogAuthorEmail: oper.connectedUser!.emailAddress});


        setNetError(false);
        setLoading(false);
    };

    const addEdditRecord = (e?: IManagtBlog) => {
      setRecStatus(e == null ? 0 : 1);
      setCurrentRec(e ?? { ...defBlogData, blogAuthorEmail: connectedUser!.emailAddress });
      setFormTitle(e == null ? languages.addNewUser : languages.editUser);
      setFormOpen(true);
    };
    const deleteBlogRecord = async (e: IManagtBlog) => {
        const bAsk = await appFxs.showConfirm(
        languages.delete,
        languages.deleteRecordQst
        );
        if (!bAsk.isConfirmed) return;
        const oper = await NetServices.requestPost("managt/deletemanagtblog",
            {
                blogDeleteKey: e.blogKey,
            },true);

        if (!oper.bReturn) {
            appFxs.showAlert(oper.msgTitle, oper.msgBody);
            return;
        }

        setLstOfMgtBlogs(oper.lstManagtBlogs!);
    };
    const onPublishBlog = async (e: IManagtBlog, i: number) => {
      const bAsk = await appFxs.showConfirm(
        i===0?  languages.unPublishBlog : languages.publishBlog, 
        i===0?  languages.unPublishBlogMsg : languages.publishBlogMsg, 
      );
      if(!bAsk.isConfirmed) return;
      const oper = await NetServices.requestPost('managt/setblogpublished', {
        blogElementKey: e.blogKey,
        activityType: i,
      }, true);
  
      if(!oper.bReturn){
        appFxs.showAlert(oper.msgTitle, oper.msgBody);
        return;
      }
  
      setLstOfMgtBlogs(oper.lstManagtBlogs!);
    }

    const afterReceiveData = (lst?: IManagtBlog[]) => {
        if(lst){
            setLstOfMgtBlogs(lst!);
        }
    
        setFormOpen(false);
      }

    useEffect(() => {
        if(bLoaded.current)return;
        loadListBlogs(false);
        bLoaded.current = true;
      }, []);
  

    return (
      <>
      <ManagtBlogForm isOpen={formOpen} 
        onClose={afterReceiveData}
        currentRecord={currentRec}
        isMainAdmin={connectedUser?.mainAdmin ?? false}
        recordStatus={recStatus}
        dialogTitle={formTitle}
        meUser={connectedUser!}
        authors={lstAllUsers}
        />


        <div className="flex flex-col gap-3">
          <Card>
            <div className="flex items-center">
              <div className="grow">
                <div className="flex">
                  <h3 className="flex-1 text-lg font-bold text-gray-800">
                    {languages.blogs}
                  </h3>
                  <div className="flex gap-1">
                    <button
                      type="button"
                      className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                      onClick={() => addNewBlog()}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div className="mt-1 text-zinc-700 dark:text-gray-400">
                  <p
                    dangerouslySetInnerHTML={{ __html: languages.blogsMsg }}
                  ></p>
                </div>
              </div>
              <div className="grow-0 flex items-center gap-2"></div>
            </div>
          </Card>
          <Card>
            <div className="flex flex-col space-y-2">
              <table className="table table-zebra">
                <thead>
                  <tr>
                    <th>{languages.title}</th>
                    <th className="hidden sm:table-cell">{languages.date}</th>
                    <th className="hidden md:table-cell">{languages.author}</th>
                    <th className="hidden lg:table-cell">
                      {languages.status}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* Loading */}
                  {isLoading && (
                    <tr>
                      <td colSpan={5}>
                        <div className="h-auto py-2 flex flex-col justify-center items-center space-y-2">
                          <span className="loading loading-spinner loading-lg"></span>
                          <h3 className="font-semibold text-lg">
                            {languages.loading}
                          </h3>
                        </div>
                      </td>
                    </tr>
                  )}

                  {/* No Record */}
                  {!isLoading && lstOfMgtBlogs.length === 0 && (
                    <tr>
                      <td colSpan={5}>
                        <div className="h-auto py-2 flex flex-col justify-center items-center space-y-2">
                          <h3>{messageDisplay}</h3>
                          {!hastNetError && (
                            <button
                              type="button"
                              className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                              onClick={() => addNewBlog()}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          )}

                          {hastNetError && (
                            <button
                              type="button"
                              className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                              onClick={() => loadListBlogs(true)}
                            >
                              <i className="fa fa-history"></i>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}

                  {/* Records */}
                
                  {lstOfMgtBlogs.map((user, idx) => (
                    <BlogRowComp key={idx} 
                      blogRecord={user}
                      users={lstAllUsers}
                      onEditRec={addEdditRecord}
                      onDeleteRec={deleteBlogRecord}
                      onPublish={onPublishBlog}
                      meUser={connectedUser!}  />
                  ))}
                </tbody>
              </table>
            </div>
          </Card>
        </div>
      </>
    );
}

interface BlogRowCompProps {
    blogRecord: IManagtBlog,
    users: IManagtUser[],
    meUser: IManagtUser,
    onEditRec: (e: IManagtBlog) => void,
    onDeleteRec: (e: IManagtBlog) => void,
    onPublish: (e: IManagtBlog, iStatus: number) => void,
}
const BlogRowComp = ({
    blogRecord, 
    users, 
    meUser, 
    onPublish,
    onDeleteRec,
    onEditRec,
}:BlogRowCompProps) => {
    const selectedUser = users.find(o => o.emailAddress === blogRecord.blogAuthorEmail);
    const authorName = selectedUser ? `${selectedUser?.firstName} ${selectedUser?.lastName}`:
     languages.notSpecified;
    

    const PublishStatus = () => {
        return (
          <>
            {!(meUser.mainAdmin ||
              meUser.emailAddress === blogRecord.blogAuthorEmail) && <span>{blogRecord.published ? languages.published: languages.unpublished}</span>}
            {((meUser.mainAdmin ||
              meUser.emailAddress === blogRecord.blogAuthorEmail) && blogRecord.published ) && (
              <span className="flex items-center gap-1">
                <strong>{languages.published}</strong>
                
                <span className='flex items-center justify-center cursor-pointer p-2' 
                  onClick={() => onPublish(blogRecord, 0)}
                  title={languages.unpublish}>
                  <ThumbsDown size={12} color="black" />
                </span>
              </span>
            )}
            {((meUser.mainAdmin ||
              meUser.emailAddress === blogRecord.blogAuthorEmail) && !blogRecord.published ) && (
              <span className="flex items-center gap-1">
                <strong className='text-red-500'>{languages.unpublished}</strong>
                
                <span className='flex items-center justify-center cursor-pointer p-2' 
                  onClick={() => onPublish(blogRecord, 1)}
                  title={languages.publish}>
                  <ThumbsUp size={12} color="red" />
                </span>
              </span>
            )}
          </>
        );
    }

    return (
      <tr>
        {/* Title */}
        <td>
          <div className="flex flex-col gap-1">
            <a className='text-blue-600' target='_blank' href={blogRecord.blogPathUrl} rel="noreferrer">
              {blogRecord.blogTitle}
            </a>
            <span className="sm:hidden text-slate-600">
              <strong>{moment(blogRecord.blogDate).format("DD/MM/YY")}</strong>
            </span>
            <span className="md:hidden text-sm text-slate-600">
              <strong>{authorName}</strong>
            </span>
          </div>
        </td>
        {/* Date */}
        <td className="hidden sm:table-cell">
          {moment(blogRecord.blogDate).format("DD/MM/YY")}
        </td>
        {/* Author */}
        <td className="hidden md:table-cell">{authorName}</td>
        {/* Status */}
        <td className="hidden lg:table-cell">
          <PublishStatus />
        </td>
        <td>
          <div className="flex items-center gap-1 justify-end">
            <button
              className="border border-sky-500 rounded-md py-1 px-2"
              onClick={() => onEditRec(blogRecord)}>
              <i className="fa fa-pencil text-sky-500"></i>
            </button>
            {(meUser.mainAdmin ||
              meUser.emailAddress === blogRecord.blogAuthorEmail) && (
              <button
                className="border border-red-500 rounded-md py-1 px-2"
                onClick={() => onDeleteRec(blogRecord)}
              >
                <i className="fa fa-trash text-red-500"></i>
              </button>
            )}
            {((meUser.mainAdmin ||
              meUser.emailAddress === blogRecord.blogAuthorEmail) && !blogRecord.published ) && (
              <button
                className="border border-green-500 rounded-md py-1 px-2 lg:hidden"
                onClick={() => onPublish(blogRecord,1)}
                title={languages.publish}
              >
                <ThumbsUp size={18} color="#22C55E" />
              </button>
            )}
            {((meUser.mainAdmin ||
              meUser.emailAddress === blogRecord.blogAuthorEmail) && blogRecord.published ) && (
              <button
                className="border border-sky-500 rounded-md py-1 px-2 lg:hidden"
                onClick={() => onPublish(blogRecord,0)}
                title={languages.unpublish}
              >
                <ThumbsDown size={18} color="#0E41E9" />
              </button>
            )}
          </div>
        </td>
      </tr>
    );
}
