/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';


import Card from 'src/components/Card';
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";

import { IManagtUser, IProductWithVariant} from 'src/models/smarttypes';
import NetServices from 'src/utils/netservices';

import { ErrorTableRow, LoadingTableRow, NoRecordTableRow } from 'src/components/majorsmallcomps';
import { ThumbsDown, ThumbsUp } from 'lucide-react';

const languages = getLanguages();
const appFxs = getFxs();


export default function ListProductsPage() {
  document.title = `${languages.products} | ${languages.management} - ${languages.schoolName}`;
  const bLoaded = useRef(false);

  const [isLoading, setLoading] = useState(true);

  const [lstShopProducts, setLstShopProducts] = useState<IProductWithVariant[]>([]);

  const [messageDisplay, setMessageDisplay] = useState("");
  const [hastNetError, setHastNetError] = useState(false);

  const [currentUser, setCurrentUser] = useState<IManagtUser | null>(null);

  const navigate = useNavigate();

  const onDeleteProduct = async (productKey: string) => {
    const bAsk = await appFxs.showConfirm(languages.delete, languages.deleteRecordQst);
    if(!bAsk.isConfirmed)return;

    const oper = await NetServices.requestPost('managt/deleteshopproduct',{
      productKey: productKey
    }, true);

    if(!oper.bReturn){
      appFxs.showAlert(oper.msgTitle, oper.msgBody);
      return;
    }

    setLstShopProducts(oper.lstShopProducts!);
  }


  const populateData = async () => {
    setLoading(true);
    setHastNetError(false);
    setMessageDisplay("");

    const oper = await NetServices.requestGet(
      "managt/getallshopproducts",
      false
    );

    if (!oper.bReturn) {
      setHastNetError(true);
      setMessageDisplay(oper.msgBody);
      setLoading(false);
      return;
    }


    setLstShopProducts(oper.lstShopProducts!);
    setCurrentUser(oper.connectedUser!)

    setHastNetError(false);
    setMessageDisplay("");
    setLoading(false);
  };

  useEffect(() => {
    if (bLoaded.current) return;
    populateData();
    bLoaded.current = true;
  }, []);


  return (
    <div className='flex flex-col gap-4'>
      <Card>
        <div className="flex items-center">
          <div className="grow">
            <div className="flex">
              <h3 className="flex-1 text-lg font-bold text-gray-800">
                {languages.products}
              </h3>
              <div className="flex gap-1">
                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={() => populateData()}
                  title={languages.reload}
                >
                  <i className="fa fa-history"></i>
                </button>

                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={() => navigate("/app/main/products/newproduct")}
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
            <div className="mt-1 text-zinc-700 dark:text-gray-400">
              {languages.listProdsMsg}
            </div>
          </div>
          <div className="grow-0 flex items-center gap-2"></div>
        </div>
      </Card>

      {/* details */}
      <Card>
        <div className="flex flex-col">
          <table className="table table-zebra">
            <thead>
              <tr>
                <th>{languages.name}</th>
                <th className="hidden md:table-cell">{languages.category}</th>
                <th className="hidden sm:table-cell text-right">{languages.price}</th>
                <th className="hidden sm:table-cell text-right">{languages.discounted}</th>
                <th className="hidden lg:table-cell">{languages.status}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {/* Loading */}
              {isLoading && <LoadingTableRow colSpan={6} />}

              {/* error */}
              {!isLoading && hastNetError && (
                <ErrorTableRow
                  colSpan={6}
                  messageDisplay={messageDisplay}
                  actionToTake={populateData}
                />
              )}

              {/* No Record */}
              {!isLoading && !hastNetError && lstShopProducts.length === 0 && (
                <NoRecordTableRow
                  colSpan={6}
                  messageDisplay={languages.noProdFound}
                  buttonIconCss="fa fa-plus"
                  actionToTake={() => navigate("/app/main/products/newproduct")}
                />
              )}

              {/* list */}
              {!isLoading &&
                !hastNetError &&
                lstShopProducts.length > 0 &&
                lstShopProducts.map((oProduc, idx) => (
                  <ProductTableRow
                    product={oProduc}
                    key={idx}
                    meUser={currentUser!}
                    onDeleteProduct={onDeleteProduct}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
}

interface ProductTableRowProps {
  product: IProductWithVariant;
  meUser: IManagtUser,
  onDeleteProduct: (key:string) => void,
}

const ProductTableRow = ({
  product,
  meUser,
  onDeleteProduct,
}: ProductTableRowProps) => {
  const oProduct = product.product;
  const oCategory = 
    oProduct.category === 0 ? languages.foodBev:
    oProduct.category === 1 ? languages.schoolSupplStat:
    oProduct.category === 2 ? languages.clothAccess:
    oProduct.category === 3 ? languages.services:
    oProduct.category === 4 ? languages.otherAweSome:
      languages.foodBev;

    const navigate = useNavigate();

  const availableTxt = oProduct.isAvailable ? languages.available: languages.notAvailabe

  return (
    <tr>
      {/* Subject */}
      <td>
        <div className="flex flex-col gap-1">
          <Link
            className="text-blue-500 hover:text-blue-700"
            to={`/app/main/products/preview/${oProduct.productKey}`}
          >
            {oProduct.productName}
          </Link>
          <span className="md:hidden text-slate-600">
            <span className="flex items-center gap-1">
              {oCategory}
            </span>
          </span>
          <span className="sm:hidden text-slate-600" title={languages.price}>
            <span className="flex items-center gap-1">
              <ThumbsUp size={18} />
              <span><strong className='text-[12px]'>Rwf</strong>&nbsp;{appFxs.formatNumbers(oProduct.productPrice)}</span>
            </span>
          </span>
          <span className="sm:hidden text-sm text-slate-600" title={languages.discounted}>
            <span className="flex items-center gap-1">
              <ThumbsDown size={18} />
              <span><strong className='text-[12px]'>Rwf</strong>&nbsp;{appFxs.formatNumbers(oProduct.discountedPrice)}</span>
            </span>
          </span>
          <span className="sm:hidden text-sm text-slate-600" title={languages.status}>
            <span className="flex items-center gap-1">
              {availableTxt}
            </span>
          </span>
        </div>
      </td>

      {/* sender */}
      <td className="hidden md:table-cell">
        <span className="flex items-center gap-1">
          {oCategory}
        </span>
      </td>

      {/* productPrice */}
      <td className="hidden sm:table-cell text-right">
        <strong className='text-[12px]'>Rwf</strong>&nbsp;{appFxs.formatNumbers(oProduct.productPrice)}
      </td>

      {/* discountedPrice */}
      <td className="hidden sm:table-cell text-right">
        <strong className='text-[12px]'>Rwf</strong>&nbsp;{appFxs.formatNumbers(oProduct.discountedPrice)}
      </td>

      {/* availableTxt */}
      <td className="hidden lg:table-cell">
        <span className="flex items-center gap-1">
          {availableTxt}
        </span>
      </td>

      {/* actions */}
      <td>
        <div className="flex items-center gap-1 justify-end">
          <button
            className="border border-solid border-sky-500 rounded-md py-1 px-2 inline-block"
            title={languages.preview}
            onClick={() => navigate(`/app/main/products/preview/${oProduct.productKey}`)}
          >
            <i className="fa fa-eye text-sky-500"></i>
          </button>

          {(meUser.mainAdmin || meUser.admin) && (
              <button
                className="border border-red-500 rounded-md py-1 px-2"
                onClick={() => onDeleteProduct(oProduct.productKey)}
                title={languages.delete}>
                <i className="fa fa-trash-o text-red-500"></i>
              </button>
            )}
        </div>
      </td>
    </tr>
  );
};