/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from 'src/components/Card';
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";

import NetServices from 'src/utils/netservices';

import { LinkAchorRound } from 'src/components/linkanchorround/LinkAchorRound';

import testImg from 'src/assets/images/alumniimg.png';
import { IProductShop, IProductShopError } from 'src/models/smarttypes';

const languages = getLanguages();
const appFxs = getFxs();

interface FileWithKey {
  file: File,
  key: string,
}
interface ProductImageClickerProps {
  onReceivefile: (f: File[]) => void,
}
interface ProductImageWrapperProps {
  file: FileWithKey, onRemove: (fileKey: string) => void,
}

const defaulData:IProductShop = {
  productKey: '',
  category: 0,
  discountedPrice: 0,
  imagePath: [],
  isAvailable: true,
  operDate: new Date(),
  productName: '',
  productPrice: 0,
  productSKU: '',
  productDescript: '',
}

export default function ProductsCreateNew() {
  document.title = `${languages.composeNewMail} | ${languages.management} - ${languages.schoolName}`;
  const navigate = useNavigate();

  const [lstFiles, setLstFiles] = useState<FileWithKey[]>([]);
  const [formData, setFormData] = useState<IProductShop>({...defaulData});
  const [formError, setFormError] = useState<IProductShopError>({});

  const [skuLen, setSkuLen] = useState(0)


  const afterSelectFiles = (files: File[]) => {
    const lstImages = files.filter(o => {
      const ext = o.name.split('.').pop();
      
      if(!ext) return false;
  
      return appFxs.isFileImageExt(ext);
    });


    const ff:FileWithKey[] = lstImages.map(o => {
      const ext = o.name.split('.').pop();
      return {
        file: o,
        key: `${appFxs.generateUUID()}.${ext}`,
      };
    })
    setLstFiles([...lstFiles, ...ff]);
  }

  const saveProductRec = () => {
    const errors:IProductShopError = {};

    if(!formData.productSKU || formData.productSKU.length === 0){
      errors.productSKU = languages.fldRequired;
    }else if(formData.productSKU.length < 15) {
      errors.productSKU = languages.prodSkuError;
    }
    if(!formData.productName || formData.productName.length === 0){
      errors.productName = languages.fldRequired;
    }
    if(!formData.productPrice || formData.productPrice === 0){
      errors.productPrice = languages.fldRequired;
    }
    if(formData.discountedPrice.toString().length === 0){
      errors.discountedPrice = languages.fldRequired;
    } else if(formData.productPrice && formData.discountedPrice){
      if(formData.discountedPrice > formData.productPrice){
        errors.discountedPrice = languages.discountedPriceErr;
      }
    }
    if(lstFiles.length === 0){
      errors.imagePath = languages.productImageError;
    }


    setFormError(errors);
    if(Object.keys(errors).length > 0)return;
    
    saveFinalData();
  }
  const saveFinalData = async () => {
    const bAsk = await appFxs.showConfirm(languages.save, languages.saveProdQst);
    if(!bAsk.isConfirmed)return;

    const filesNames = lstFiles.map(o => o.key);
    formData.imagePath = filesNames;

    const oRecord = new FormData();
    oRecord.append('productData', JSON.stringify(formData));
    lstFiles.forEach(xO => {
      oRecord.append(xO.key, xO.file);
    });

    const oper = await NetServices.requestPostWithFileAxios('managt/saveshopproductrecord', oRecord, true);

    if(!oper.bReturn){
      appFxs.showAlert(oper.msgTitle, oper.msgBody);
      return;
    }

    navigate('/app/main/products');
  }

  const onRemove = (fileKey: string) => {
    const finalAtt = [...lstFiles];
    const lst = finalAtt.filter(ofile => ofile.key !== fileKey);
    setLstFiles(lst);
  };

  const onRefuseNonNumer = (e: React.KeyboardEvent<HTMLInputElement>) => {
    appFxs.strictlyNbr(e)
  }

  return (
    <div className="flex flex-col gap-3">
      <Card>
        <div className="flex items-center">
          <div className="grow">
            <div className="flex">
              <h3 className="flex-1 text-lg font-bold text-gray-800">
                {languages.newProduct}
              </h3>
              <div className="flex gap-1">
                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={() => navigate("/app/main/products")}
                  title={languages.listProds}
                >
                  <i className="fa fa-list"></i>
                </button>
                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={() => saveProductRec()}
                  title={languages.save}
                >
                  <i className="fa fa-save"></i>
                </button>
              </div>
            </div>
            <div className="mt-1 text-zinc-700 dark:text-gray-400">
              {languages.newProductMsg}
            </div>
          </div>
          <div className="grow-0 flex items-center gap-2"></div>
        </div>
      </Card>

      {/* Details */}
      <Card>
        <div className="flex flex-col">
          <h3 className="text-lg font-semibold mb-2">
            {languages.images}&nbsp;
            {formError.imagePath && (
              <span className="text-red-400">{formError.imagePath}</span>
            )}
          </h3>
          {/* Images */}
          <div className="flex flex-wrap gap-2 py-1">
            {lstFiles.map((o, idx) => (
              <ProductImageWrapper onRemove={onRemove} file={o} key={idx} />
            ))}
            <ProductImageClicker onReceivefile={afterSelectFiles} />
          </div>

          <div className="flex flex-wrap justify-between">
            {/* sku */}
            <label className="form-control block basis-full md:basis-[49%] mb-2">
              <div className="label">
                <span className="label-text">{languages.skuV2}</span>{" "}
                <span>
                  (<span className="font-semibold">{skuLen}</span>/15)
                </span>
              </div>
              <input
                type="text"
                placeholder={languages.sku}
                className="input input-bordered w-full ring-1"
                name="productSKU"
                value={formData.productSKU}
                onKeyPress={onRefuseNonNumer}
                maxLength={15}
                onChange={(e) => {
                  setSkuLen(e.target.value.length);
                  setFormData({ ...formData, productSKU: e.target.value });
                }}
              />
              {formError.productSKU && (
                <div className="w-full py-1 text-red-500">
                  {formError.productSKU}
                </div>
              )}
            </label>

            {/* productName */}
            <label className="form-control block basis-full md:basis-[49%] mb-2">
              <div className="label">
                <span className="label-text">{languages.productName}</span>
              </div>
              <input
                type="text"
                placeholder={languages.productName}
                className="input input-bordered w-full ring-1"
                name="productName"
                value={formData.productName}
                onChange={(e) =>
                  setFormData({ ...formData, productName: e.target.value })
                }
              />
              {formError.productName && (
                <div className="w-full py-1 text-red-500">
                  {formError.productName}
                </div>
              )}
            </label>

            {/* productPrice */}
            <label className="form-control block basis-full md:basis-[49%] mb-2">
              <div className="label">
                <span className="label-text">{languages.price}</span>
              </div>
              <input
                type="text"
                placeholder={languages.price}
                className="input input-bordered w-full ring-1 text-right"
                name="productPrice"
                value={formData.productPrice}
                onKeyPress={onRefuseNonNumer}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    productPrice: Number(e.target.value),
                  })
                }
              />
              {formError.productPrice && (
                <div className="w-full py-1 text-red-500">
                  {formError.productPrice}
                </div>
              )}
            </label>

            {/* discountedPrice */}
            <label className="form-control block basis-full md:basis-[49%] mb-2">
              <div className="label">
                <span className="label-text">{languages.discounted}</span>
              </div>
              <input
                type="text"
                placeholder={languages.discounted}
                className="input input-bordered w-full ring-1 text-right"
                name="discountedPrice"
                value={formData.discountedPrice}
                onKeyPress={onRefuseNonNumer}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    discountedPrice: Number(e.target.value),
                  })
                }
              />
              {formError.discountedPrice && (
                <div className="w-full py-1 text-red-500">
                  {formError.discountedPrice}
                </div>
              )}
            </label>

            {/* category */}
            <label className="form-control block basis-full md:basis-[49%] mb-2">
              <div className="label">
                <span className="label-text">{languages.category}</span>
              </div>
              <select
                className="select select-bordered w-full ring-1"
                name="category"
                value={formData.category}
                onChange={(e) =>
                  setFormData({ ...formData, category: Number(e.target.value) })
                }
              >
                <option value={0}>{languages.foodBev}</option>
                <option value={1}>{languages.schoolSupplStat}</option>
                <option value={2}>{languages.clothAccess}</option>
                <option value={3}>{languages.services}</option>
                <option value={4}>{languages.otherAweSome}</option>
              </select>
              {formError.category && (
                <div className="w-full py-1 text-red-500">
                  {formError.category}
                </div>
              )}
            </label>

            {/* productDescript */}
            <label className="form-control block basis-full md:basis-[49%] mb-2">
              <div className="label">
                <span className="label-text">{languages.description}</span>
              </div>
              <textarea
                placeholder={languages.description}
                className="textarea textarea-bordered w-full ring-1 no-resize"
                name="productDescript"
                value={formData.productDescript}
                onChange={(e) =>
                  setFormData({ ...formData, productDescript: e.target.value })
                }
              ></textarea>
              {formError.productDescript && (
                <div className="w-full py-1 text-red-500">
                  {formError.productDescript}
                </div>
              )}
            </label>

            <div className="flex items-center w-full">
              <label className="label cursor-pointer flex gap-2">
                <span className="label-text">{languages.available}</span>
                <input
                  type="checkbox"
                  className="toggle border border-slate-500 border-solid"
                  checked={formData.isAvailable}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      isAvailable: !formData.isAvailable,
                    })
                  }
                />
              </label>
            </div>

            <div className="w-full mt-4">
              <LinkAchorRound
                text={languages.save}
                type="button"
                fullWidth={true}
                borderRadius={18}
                onClick={saveProductRec}
                bgColor="#0b5b13"
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}




const ProductImageWrapper = ({file, onRemove}:ProductImageWrapperProps) => {
  const [fileUrl, setFileUrl] = useState<string|null>(null)
  const reader = new FileReader();
  reader.addEventListener(
    "load",
    function () {
      const imageURL = reader.result! as string;
      setFileUrl(imageURL);
    },
    false
  );
  if (file) {
    reader.readAsDataURL(file.file);
  }


  return (
    <div className='group w-[100px] h-[150px] rounded-lg border border-slate-600 border-solid relative overflow-hidden'>
      <img src={fileUrl ?? testImg} 
        alt=''
        className='z-0 absolute top-0 right-0 w-full h-full object-cover object-top'/>
      <div className='z-[1] absolute top-0 right-0 w-full h-full hidden group-hover:block bg-transition'
        style={{backgroundColor: 'rgba(0,0,0,.6)'}}></div>
      <button className='hidden group-hover:inline-block z-[1] absolute right-1 top-1'
         title={languages.remove}
         onClick={() => onRemove(file.key)}>
        <i className='fa fa-trash text-white'></i>
      </button>
    </div>
  );
}


const ProductImageClicker = ({onReceivefile}:ProductImageClickerProps) => {
  const fileSelector = useRef<HTMLInputElement>(null);
  const onSelectFile = () => {
    fileSelector?.current?.click();
  }
  const receiveFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    onReceivefile(Array.from(e.target.files!));
  }

  return (
    <div className='group w-[100px] h-[150px] rounded-lg border border-slate-600 border-solid relative overflow-hidden flex items-center justify-center'
      style={{backgroundColor: 'rgba(0,0,0,.6)'}}>      
      <button className='' 
        title={languages.addMorePictures}
        onClick={onSelectFile}>
        <i className='fa fa-plus-circle text-white fa-3x'></i>
      </button>
      <input accept="image/*"
        ref={fileSelector} 
        type='file' 
        className='hidden' 
        onChange={receiveFile}
        multiple={true} />
    </div>
  );
}