/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'

import Card from 'src/components/Card';
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";

import { IAlumniUser} from 'src/models/smarttypes';
import NetServices from 'src/utils/netservices';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import LoadingWaitSpace from 'src/components/LoadingWaitSpace';

import logoImage from 'src/assets/images/alumniimg.png';
import appDecl from 'src/utils/declarations';
import { ThumbsDown, ThumbsUp } from 'lucide-react';

const languages = getLanguages();
const appFxs = getFxs();

export default function AlumniDetailPage() {
  const [memberName, setMemberName] = useState(`${languages.loading}...`);
  const [alumniMember, setAlumniMember] = useState<IAlumniUser | null>(null);

  const [isLoading, setLoading] = useState(true);
  const [hasNetError, setHasNetError] = useState(false);
  const [netError, setNetError] = useState("");
  const [alumniImgPath, setAlumniImgPath] = useState(logoImage);

  const { encodedEmail } = useParams();
  const bLoaded = useRef(false);

  document.title = `${memberName} | ${languages.management} - ${languages.schoolName}`;

  const loadUserData = async (bAjax?: boolean) => {
    setLoading(true);
    setHasNetError(false);
    setNetError("");

    const oper = await NetServices.requestGet(
      `managt/getonealumnimember/${encodedEmail!}`,
      bAjax
    );
    if (!oper.bReturn) {
      setNetError(oper.msgBody);
      setHasNetError(true);
      setLoading(false);
      return;
    }

    const oMember = oper.alumniMember!;

    setAlumniMember(oMember);
    setMemberName(`${oMember.firstName} ${oMember.lastName}`);
    setAlumniImgPath(oMember.profilePicturePath ? `${appDecl.apiUrlTest}/cloud_data/alumni_profiles/${oMember.profilePicturePath}`: logoImage)

    setHasNetError(false);
    setNetError("");
    setLoading(false);
  };

  const onActivateAcct = async (iStatus: number) => {
    const bAsk = await appFxs.showConfirm(
      iStatus === 0 ? languages.deactivate : languages.activate,
      iStatus === 0
        ? languages.deActivateAlumniMember
        : languages.activateAlumniMember
    );
    if (!bAsk.isConfirmed) return;

    const oper = await NetServices.requestPost('managt/setactivealumnimemberretone', {
        encryptedEmail: window.btoa(alumniMember!.emailAddress),
        operationStatus: iStatus,
    }, true);

    if(!oper.bReturn){
        appFxs.showAlert(oper.msgTitle, oper.msgBody);
        return;
    }

    setAlumniMember(oper.alumniMember!);
  }

  useEffect(() => {
    if (bLoaded.current) return;
    loadUserData();
    bLoaded.current = true;
  }, []);

  return (
    <>
      <div className="flex flex-col gap-3">
        <Card>
          <div className="flex items-center">
            <div className="grow">
              <div className="flex">
                <h3 className="flex-1 text-lg font-bold text-gray-800">
                  {`${languages.alumniMember}: ${memberName}`}
                </h3>
                <div className="flex gap-1">
                  <button
                    type="button"
                    className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                    onClick={() => loadUserData()}
                  >
                    <i className="fa fa-history"></i>
                  </button>
                </div>
              </div>
              <div className="mt-1 text-zinc-700 dark:text-gray-400">
                {languages.previewAlumniMember}
              </div>
            </div>
            <div className="grow-0 flex items-center gap-2"></div>
          </div>
        </Card>

        <Card>
          {isLoading && <LoadingWaitSpace height={150} />}
          {!isLoading && hasNetError && (
            <div className="flex flex-col items-center justify-center max-w-[450px] mx-auto">
              <p className="text-center mb-3">{netError}</p>
              <button onClick={() => loadUserData()} className="btn btn-info">
                {languages.reload}
              </button>
            </div>
          )}

          {!isLoading && !hasNetError && alumniMember && (
            <div className="flex flex-col space-y-2 items-center">
                <div className='flex justify-end py-4 mb-3 w-full'>
                    {!alumniMember.userActive &&
                        <button className='btn btn-success flex items-center w-full md:w-auto'
                        onClick={() => onActivateAcct(1)}>
                            <ThumbsUp color='#fff' />
                            <span className='text-white'>{languages.activate}</span>
                        </button>
                    }

                    {alumniMember.userActive &&
                        <button className='btn btn-warning flex items-center w-full md:w-auto'
                        onClick={() => onActivateAcct(0)}>
                            <ThumbsDown color='#fff' />
                            <span className='text-white'>{languages.deactivate}</span>
                        </button>
                    }
                </div>
                {/* Image */}
              <div className="relative h-48 w-[160px] border border-solid border-sky-500 rounded-2xl flex items-center">
                <div className="relative w-full h-full  rounded-2xl overflow-hidden">
                    <img
                      src={alumniImgPath}
                      alt=""
                      id="alumniPic"
                      className="rounded-2xl w-full h-full max-w-full max-h-full object-cover object-top"
                    />
                </div>
              </div>

              {/* names */}
              <div className='flex items-start flex-wrap w-full space-y-3 mb-8'>
                <div className='input input-bordered basis-full md:basis-[49%] '>
                    <span>{languages.firstName}</span><br/>
                    <strong>{alumniMember.firstName}</strong>
                </div>            
                <div className='input input-bordered basis-full md:basis-[49%]'>
                    <span>{languages.lastName}</span><br/>
                    <strong>{alumniMember.lastName}</strong>
                </div>           
                <div className='input input-bordered basis-full md:basis-[49%]'>
                    <span>{languages.email}</span><br/>
                    <strong>
                        <a className='text-blue-500' href={`mailto:${alumniMember.emailAddress}`}>{alumniMember.emailAddress}</a>
                    </strong>
                </div>         
                <div className='input input-bordered basis-full md:basis-[49%]'>
                    <span>{languages.phoneNbr}</span><br/>
                    <strong>
                        <a className='text-blue-500' href={`tel:${alumniMember.phoneNbr}`}>{alumniMember.phoneNbr}</a>
                    </strong>
                </div>
                <div className='input input-bordered basis-full md:basis-[49%]'>
                    <span>{languages.job}</span><br/>
                    <strong>{alumniMember.activeJob ?? languages.unEmployed}</strong>
                </div>

                <div className='input input-bordered basis-full md:basis-[49%]'>
                    <span>{languages.dob}</span><br/>
                    <strong>{moment(alumniMember.dateOfBirth).format('DD/MM/YYYY')}</strong>
                </div>
                <div className='input input-bordered basis-full md:basis-[49%]'>
                    <span>{languages.sex}</span><br/>
                    <strong>{alumniMember.sex! === '0' ? languages.male: languages.female}</strong>
                </div>
                <div className='input input-bordered basis-full md:basis-[49%]'>
                    <span>{languages.yearCompleted}</span><br/>
                    <strong>{alumniMember.yearCompleted}</strong>
                </div>
                <div className='input input-bordered basis-full md:basis-[49%]'>
                    <span>{languages.completedDegree}</span><br/>
                    <strong>{alumniMember.completedDegree}</strong>
                </div>
                <div className='input input-bordered basis-full md:basis-[49%] min-h-[150px]'>
                    <span>{languages.address}</span><br/>
                    <strong>{alumniMember.addressLine}</strong>
                </div>
              </div>
            </div>
          )}
        </Card>
      </div>
    </>
  );
}
