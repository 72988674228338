/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import alLogo from 'src/assets/images/alumniimg.png';
import { FormInputElt } from "src/components/forminputelt/FormInputElt";
import { LinkAchorRound } from "src/components/linkanchorround/LinkAchorRound";
import appLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import { RecaptaField } from "src/components/recaptafield/RecaptaField";
import NetServices from "src/utils/netservices";
import { IManagtUser } from "src/models/smarttypes";

const languages = appLanguages();
const appFxs = generalFxs();

interface IFormData {
    setNewPwKey: string,
    magicPassword: string,
    confirmPassword: string,
    captcha: string,
    captchaSet: string,
    isPwGood: boolean,
}

interface IFormError {
  newPassword?: string,
  confirmPassword?: string,
  captcha?: string,
  setupKey?: string,
};
interface IPasswordState {
  width: string,
  bgMeterClass: string,
  infoClass: string,
}
const defaultPwState:IPasswordState = {
  bgMeterClass: '',
  infoClass: '',
  width: '0px',
}
const defaultFormState:IFormData = {
  setNewPwKey: '',
  magicPassword: '', 
  confirmPassword: '', 
  captcha: appFxs.getRandomDigits(5),
  captchaSet: '',
  isPwGood: false,
}


export default function AcctJoinTeamPage() {
  document.title = `${languages.joinTeam} | ${languages.management} | ${languages.schoolName}`;
  
  const navigate = useNavigate();
  const {joinKey} = useParams();

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showServerMsg, setShowServerMsg] = useState(false);
  const [serverMessage, setServerMessage] = useState('');
  const [passwordState, setPasswordState] = useState<IPasswordState>(defaultPwState);

  const [formData, setFormData] = useState<IFormData>({...defaultFormState, setNewPwKey: joinKey!});
  const [formErrors, setFormErrors] = useState<IFormError>({});


  const [checkUser, setCheckUser] = useState<IManagtUser | null>(null);

  //let formData:IFormData = {...defaultFormState, setNewPwKey: setkey!};

  let allErrors:IFormError = {};

  const removeServerError = () => {
    setShowServerMsg(false);
    setServerMessage('');
  }
  const validateFields = () => {
    const finaldata: IFormError = {};

    //email
    if (!formData.magicPassword) {
      finaldata.newPassword = languages.fldRequired;
    }else if(!formData.isPwGood){
      finaldata.newPassword = languages.passValidMsg;
    }

    if (!formData.confirmPassword) {
      finaldata.confirmPassword = languages.fldRequired;
    }else if(formData.confirmPassword !== formData.magicPassword){
      finaldata.confirmPassword = languages.sameAsPasswordMsg;
    }

    if (!formData.captchaSet) {
      finaldata.captcha = languages.fldRequired;
    } else if (formData.captcha !== formData.captchaSet) {
      finaldata.captcha = languages.secCheckError;
    }


    setFormErrors({ ...finaldata });
    allErrors = { ...finaldata };
  };
  const sendForm = async() => {
    validateFields();
    if (Object.keys(allErrors).length > 0) return;
    setIsSaving(true);

    const oData = {
      joinUserPassword: formData.magicPassword,
      joinUserConfPassword:formData.confirmPassword,
      joinUserKey: joinKey,
    }
    const oRes = await NetServices.requestPost('managt/confirmjoinuser', oData); 

    if(!oRes.bReturn){
      setIsSaving(false);
      setServerMessage(oRes.msgBody);
      setShowServerMsg(true);
      return;
    }

    navigate('/account/login');    
  };
  const updatePwValues = (pw: string) => {
    const stren = !pw ? 0: appFxs.passwordStrengthCheck(pw);
    const pctg = (stren / 100) * 100;
    const lng = !pw ? 0: pw.length;

    const clsBg = 
      (pctg < 20) ? 'pw-meter-no-bg' :
      (pctg < 40) ? 'pw-meter-vweak-bg' :
      (pctg < 60) ? 'pw-meter-soso-bg' :
      (pctg < 80) ? 'pw-meter-good-bg' :
      (pctg <= 100) ? 'pw-meter-great-bg' :
      'pw-meter-no-bg';

    const realPctg = lng > 0 ? ` (${pctg}%)`: ' (0px)';
    
    const clsInfo = (pctg < 20) ? `${realPctg}` :
      (pctg < 40) ? languages.weak + realPctg:
      (pctg < 60) ? languages.good + realPctg:
      (pctg < 80) ? languages.strong + realPctg:
      (pctg <= 100) ? languages.very_strong + realPctg:
      '&nbsp;';
      //const goodPw = (pctg >= 70);
      //setFormData({...formData, isPwGood: goodPw});
        
      setPasswordState({
        bgMeterClass: clsBg,
        infoClass: clsInfo,
        width: lng > 0 ? `${pctg}%`: '0px'
      })
  }

  if(isLoading) return (
    <WaitJoinTeamConfirm 
      joinKey={joinKey!} 
      afterSucceed={(e) => {
        setCheckUser(e);
        setLoading(false)
      }}
      />)

  return (
    <div className="flex flex-col">
      <div className="l-hearder mb-2">
        <Link to="/" className="flex items-center">
          <img src={alLogo} alt="" className="logo mr-3" />
          <span className="stockix">La Colombière</span>
        </Link>
      </div>
      {showServerMsg && (
        <div className="alert-error relative" ng-if="isServerError">
          <a
            className="close-btn grow-0"
            title={languages.close}
            onClick={removeServerError}
            data-toggle="tooltip"
            data-placement="top"
          >
            <i className="fa fa-times-circle"></i>
          </a>
          <span className="grow text-red-500">{serverMessage}</span>
        </div>
      )}

      <form className="login-form mt-3">
        <div className="flex flex-col mt-3">
          <h1 className="tiny_bottom_margin size-17rem">
            {languages.confirmAndSetPassword}
          </h1>
          <p className="py-4">managt.lacolombiere.ac.rw</p>
          <div className="my-3 text-slate-600">
            {`${checkUser?.firstName} ${checkUser?.lastName}`}
          </div>
          <p className="mb-4 font-semibold">{languages.provideNewPassword}</p>
          <FormInputElt
            type="password"
            name="magicPassword"
            value={formData.magicPassword}
            placeholder={languages.newPassword.toLowerCase()}
            errorMessage={formErrors.newPassword}
            onChange={(e) => {
              setFormData({...formData, magicPassword: e.value, isPwGood: appFxs.isPasswordGood(e.value)});
              updatePwValues(e.value);
            }}
            disabled={isSaving}
          />
          {/* security */}
          <div id="passw-meter-wrapper"
            className="relative w-full my-1">
            <div className="h-[4px] bg-[#E8E8E8] w-full absolute left-0"></div>
            <div id="password-meter-strength" 
              className={`h-[4px] absolute left-0 ${passwordState.bgMeterClass}`}
              style={{width: passwordState.width}}></div>
            <div className="h-[4px] w-[2px] bg-white absolute left-1/4"></div>
            <div className="h-[4px] w-[2px] bg-white absolute left-1/2"></div>
            <div className="h-[4px] w-[2px] bg-white absolute left-3/4"></div>
            <div id="password-meter-label" className="pw-meter-lbl-norm">
              {passwordState.infoClass}&nbsp;
            </div>
          </div>

          {/* confirm pw */}
          <FormInputElt
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            placeholder={languages.confirmPasword.toLowerCase()}
            errorMessage={formErrors.confirmPassword}
            onChange={(e) => {
              setFormData({...formData, confirmPassword: e.value});
            }}
            disabled={isSaving}
            otherClasses="mt-3"
          />
          <RecaptaField
            startingValue={formData.captcha}
            onChange={(e) => {
              setFormData({...formData, captchaSet: e});
            }}
            disabled={isSaving}
          />

          {formErrors.captcha && (
            <div className="pb-2 text-end">
              <span className="text-red-800">{formErrors.captcha}</span>
            </div>
          )}
          
          {isSaving && (
            <div className="flex items-center justify-center py-3">
              <i className="fa fa-spinner fa-spin fa-3x"></i>
            </div>
          )}
          {!isSaving && (
            <LinkAchorRound
              type="button"
              text={languages.setPassword}
              bgColor="#28a745"
              hoverBgcolor="#218838"
              borderRadius={4}
              onClick={sendForm}
            />
          )}
          {!isSaving && (
            <Link
              className="font-semibold text-blue-500 mt-6 hover:text-blue-700"
              to={"/account/login"}
            >
              {languages.logInstead}
            </Link>
          )}
        </div>
      </form>
    </div>
  )
}

const WaitJoinTeamConfirm = ({joinKey, afterSucceed}:{
  joinKey: string,
  afterSucceed: (e: IManagtUser) => void,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [operError, setOperError] = useState('');
  const [hasError, setHasError] = useState(false);
  const bLoaded = useRef(false);

  const encryptedKey = window.btoa(joinKey);

  const prepareCheckData = async () => {
    const oper = await NetServices.requestGet(`managt/checkjoinkey/${encryptedKey}`, false);
    if(!oper.bReturn){
      setOperError(oper.msgBody);
      setHasError(true);
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    afterSucceed(oper.userToConfirm!);
  }

  useEffect(() => {
    if(bLoaded.current)return;
    prepareCheckData();
    bLoaded.current = true;
  }, []);

  return (
    <div className="wait-to-confirm h-screen flex flex-col justify-center items-center">
      {isLoading && (
        <div className="flex flex-col items-center justify-center">
          <span className="loading loading-ring loading-lg"></span>
          <p className="mb-3">{languages.loading}...</p>
        </div>
      )}

      {(!isLoading && hasError) && (
        <div className="flex flex-col items-center justify-center max-w-[300px]">          
          <p className="text-center leading-6 text-lg">{operError}...</p>
          <Link className="my-3 btn btn-primary" to={'/'}>{languages.home}</Link>
        </div>
      )}
    </div>
  );
}