import {CSSProperties} from 'react';

export type SayingType = {
    say: string;
    author: string;
}
export type BgType  = {
    ['background-image']: string;
}
export type PadLeftStType  = {
    ['padding-left']: string;
}
export type WidthCssStyle  = {
    width: string;
}
export type SayElt = {
    saying: SayingType;
    bg: BgType;
}
export type PasswordStyle = {
    width: string;
    bgMeterClass: string;
    infoClass: string;
}
export type PasswordStrengthType = {
    value: number;
    message: string;
    length: number;
}

export type HeaderType = {
    'user-access-key': string;
    lang: string;
    'company-code': string;
    'client-operation-date': string;
    'year-exercise-app': string;
    'default-sms-account': string;
    'current-devise-val': string;
    'current-branch-val': string;
    'user-email-value': string;
    'user-password-value': string;
    'app-type': string;
}

export type TelCode = {
    name: string;
    dial_code: string;
    code: string;
}

export type CurrencyCode = {
    symbol: string;
    name: string;
    symbol_native: string;
    decimal_digits: number;
    rounding: number;
    code: string;
    name_plural: string;
}

export type TimeZoneCode = {
    value: string;
    abbr: string;
    offset: number;
    isdst: boolean;
    text: string;
    utc: string[];
}

export type ShortData = {
    id: number;
    label: string;
}

export enum DateOperation {
    Day,
    Month,
    Year,
}
export enum RightFitEnum {
    Brain,
    Infra,
    Book,
}

export type JoinRecord = {
    JoinKey: string;
    Password: string;
    ConfirmPassword: string;
}

export type PrintRecType = {
    ReportTitle: string;
    TypeSoft: number;
    ReportToPrint: number;
    StylePrint: number;
    EmailPrint: string;
    LangPrint: string;
    EmailReceiver: string;
    EmailSubject: string;
    EmailMessage: string;
};
export interface ILoginDataTransfer {
    userEmail: string,
    userPassword: string,
    userRole: string,
}

export interface ISocialIcon {
    label: string,
    href: string,
    color: string,
    id: string,
    icon: string
}
export interface IMenuColomb {
    label: string,
    href: string,
    color: string,
    id: string,
    selected?: boolean,
}
export interface IMenuColombElt {
    menu: IMenuColomb,
    onClick?: (oMenu: IMenuColomb) => void,
}
export interface IRecentPost {
    postTitle: string,
    href: string,
    imagePath: string,
    postDate: Date,
    id: string,
}

export interface IRecentPostElt {
    postElt: IRecentPost,
}
export interface ISocialIconElt {
    socialElt: ISocialIcon,
}
export interface ISliderCompElt {
    children: React.ReactNode,
    classes?: string,
    hidden?: boolean,
    onClick?: () => void,
}
export enum HomeSpecType {
    StaffTeam,
    ReadingKid,
    AlumniTeam,
    SchoolAmenities,
}
export enum CurricularType {
    higher,
    medium,
    lower,
    daycare,
    education,
    internation,
}
export interface IHomeSpec {
    label: string,
    iconComponent: HomeSpecType,
    color: string,
    href: string,
}
export interface IHomeSpecElt {
    specElt: IHomeSpec,
}

export interface ICurricularData {
    label: string,
    description: string,
    iconType: CurricularType,
    bgColor: string,
    linkColor: string,
    href: string,
    id: number,
    typeView?: 'normal'|'circle',
    circleColor?: string,
    hasLink?: boolean,
}
export interface ICurricularElt {
    currElt: ICurricularData,
}

export interface IRightFit {
    label: string,
    iconType: RightFitEnum,
    iconColor: string,
    id: number
}
export interface IRightFitElt {
    rightElt: IRightFit,
}

export type ISelectOptionType = {
    id: string;
    label :string;
}
export interface IImageElt {
    id: number,
    image: any,
    href: string;
    label: string;
}
export interface IStaffStatusElt {
    id: number,
    staffName: string,
    staffJob: string,
    phoneNbr: string,
    twitterAcct?: string,
    fbAcct?: string,
    linkedInAcct?: string,
    instAcct?: string,
    color: string,
    staffImage: any,

    isPhoneVis?: boolean,
    colorSubtitle?: string,
    colorTitle?: string,
    phoneBgColor?: string,
    phoneHoverBgColor?: string,
    phoneTextColor?: string,
    phoneHoverTextColor?: string,
}
export type TSocialIcon = {
  href: string;
  type: 'facebook'|'instagram'|'linkedin'|'twitter';
}
export interface IStaffSocialIcon {
    id: number,
    label?: string,
    href: string,
    color: string,
    iconType: 'twitter' | 'linkedin' | 'instagram'| 'facebook'| 'whatsapp'
}


export interface IParentTestimonial {
    id: number,
    label?: string,
    color: string,
    parentName: string,
    testimonial: string,
    image: any,
    selected: boolean,
}
export interface IParentTestimonialElt {
    testimonialElt: IParentTestimonial,
    onSelected: () => void
}

export interface InnerCss extends CSSProperties {
    '--testimonial-color'?: string,
    '--qualif-color'?: string,
    '--parent-color'?: string,
    '--entry-date-color'?: string,
    '--title-color'?: string,
    '--title-width'?: string,
    '--sub-title-width'?: string,
    '--blog-entry-color'?: string,
    '--strangeImageBg'?: string,
    '--special-color'?: string,
    '--bgImage'?: string,
    '--bgColor'?: string,
    '--bgHoverColor'?: string,
    '--textColor'?: string,
    '--textHoverColor'?: string,
    '--iconColor'?: string,
    '--width'?: string,
    '--borderRadius'?: string,
}

export interface ITestimonialQualif{
    eltNbr: number,
    label: string,
    color: string,
    image: any,
    id: number,
}
export interface ILastBlog {
    postTitle: string,
    href: string,
    imagePath: any,
    postDate: Date,
    postContent: string,
    id: number,
    color: string,

}

export interface ILastBlogElt {
    eltPros: ILastBlog,
    isMainBlog: boolean,
    classes: string,
    hasBgColor?: boolean,
}
export interface IRadioRecord {
    label: string,
    value: string,
    detail: string,
}
export interface IInputData {
    type?: 'text'|'email'|'tel'|'url'|'password'|'number'|'select'|'textarea'|'date',
    placeholder?: string,
    maxLength?: number,
    disabled?: boolean,
    selectOptions?:{id: string, label:string }[],
    name: string,
    value: undefined | string | number | Date,
    rows?: number,
    label?: string,
    otherClasses?: string,
    errorMessage?: string,
    onChange?: (e: IFieldRecord) => void

    step?: number, 
    min?: number,
    max?: number,

    displayClass?: 'block'|'inline-block',
    layoutStyle?: 'row'|'column',

    groupRadioValues?: IRadioRecord[]
}


export interface IPageTitleSection {
    pageTitle: string,
    currentText: string,
    classes: string,
    subPageTitle?: string,
    subPageLink?: string,
}

export interface ICurricular {
    id: number,
    title: string,
    detail: string,
    type: 'book' | 'guitar' | 'certificate' | 'staff',
}

export interface IFaq{
    id: number,
    title: string,
    message: string,
}
export interface IFaqItem {
    showDescription: string,
    fontWeightBold: string,
    ariaExpanded: "true" | "false",
    item: IFaq,
    index: number,
    onClick: () => void,
}
export interface IFacqElt {
    title: string,
    lstQuest: IFaq[],
}
export interface IFacilityAmenity {
    id: number,
    iconType: 'aircondition'|'infrastructure'|'transport'|'foodstay'|'networked',
    title: string,
    subTitle: string,
}
export interface IFacilityFeature {    
    id: number,
    type: 'brightclass' | 'multimedia' | 'playground' |'lablibrary' | 'meetinghall' |'sportsclub',
    title: string,
    detail: string,
    href: string,
}

export interface ITestiomonialElt {
    lstElts: IParentTestimonial[],
    textColor?:string,
}

export interface IGeneralType {
    title?: string,
    message?: string,
    href?: string,
    fawesomeIcon?: string,
    iconColor?: string,
    textColor?: string,
    leading?: string,
    fontSize?: number,
    fontName?: 'Quicksand' | 'Raleway',
    placeholder?: string,
    submitText?: string,
}
export interface IFacVideoVign {
    id: number,
    href: string,
    img: any,
    label: string,
} 
export interface IRealAbtUsElt {
    ytbLink: string,
    parentClasses: string,
    hasTopBody?: boolean,
    topBodyText?: string,
    bottomBodyText?: string,
    hasButtonLink?: boolean,
    navigationLinkRef?: string,
    linkType?: 'dark'| 'light',
}
export interface ITypeAddr {
    type: 'text' | 'email' | 'phone',
    text: string,
    label: string,
}
export interface IContactAddress {
    id: number,
    title: string,
    iconClass: string,
    iconColor: string,
    lstDetails: ITypeAddr[],
}
export interface IContactAddressForm {
    contactKey: string,
    firstName: string,
    lastName: string,
    mailAddress: string,
    phoneAddress: string,
    lookingForWhat: number,
    priorityType: number,
    contactMessage: string,
    captchaValue: string,
}
export interface IContactAddressFormError {
    contactKey?: string,
    firstName?: string,
    lastName?: string,
    mailAddress?: string,
    phoneAddress?: string,
    lookingForWhat?: string,
    priorityType?: string,
    contactMessage?: string,
    captchaValue?: string,
}


export interface IShopMainData {
    typeDisplay: 'grid'|'list',
    gridDisplay: 'oneline'|'twoline'|'threeline'|'fourline'|'imageleft'|'imageright',
    items: IShopProduct[],
}


export interface IShopProduct {
    typeDisplay: 'oneline'|'twoline'|'threeline'|'fourline'|'imageleft'|'imageright',
    item: IShopProductItem,
}
export interface IProductVariant {
    variantName: string, 
    values: {id: string, label: string}[]
}
export interface IFieldRecord {
    field: string,
    value: any,
}

export interface IProductVariantField {
    variant: IProductVariant,
    fieldName: string,
    onChange: (fo: IFieldRecord) => void,
    value: any,
}


export interface IShopProductItem {
    productName: string,
    productPrice: number,
    discountedPrice: number,
    productId: string,
    imagePath: any[],


    productDescript?: string,
    sku?: string,
    category?: string,
    variants? : IProductVariant[]
}
export interface ICartOperationProd {
    productName: string,
    productId: string,
    imagePath: string,
    productPrice: number,
    quantity: number,
    subtotal: number,
    variants?: {label: string, value: string}[]
}
export interface ICartOperation {
    operationDate: string,
    operationKey: string,
    products: ICartOperationProd[],
    subtotal: number,
    total: number,
}
export interface ICartItemOper {
    product: ICartOperationProd,
    inlistPos: number,
    onItemQtyChanged: (listPos: number, newQty: number) => void,
    onItemRemoved: (listPos: number) => void,
}


export interface IProductWithPagination {
    items: IShopProductItem[], 
    gridDisplay: 'oneline'|'twoline'|'threeline'|'fourline'|'imageleft'|'imageright',
}
export interface IProductWithPaginationAll {
    itemsPerPage: number,
    items: IShopProductItem[],
    gridDisplay: 'oneline'|'twoline'|'threeline'|'fourline'|'imageleft'|'imageright',
    onChange?: (iPage: number) => void,
}

export interface IContactInfo {
    firstName: string,
    lastName: string,
    companyName: string,
    province: string,
    city: string,
    phone: string,
    email: string,
    roadNbr: string,
    orderNotes: string,
    typePaiement: number,
    paytDetail: string,
}
export interface IAlumniUser {
    firstName: string,
    lastName: string,
    dateOfBirth: Date,
    yearCompleted: string,
    sex?: string,
    phoneNbr: string,
    emailAddress: string,
    password?: string,
    addressLine: string,
    completedDegree: string,
    userActive: boolean,
    profilePicturePath?: string,
    activeJob?: string,
    facebookAcct?: string,
    twitterAcct?: string,
    linkedInAcct?: string,
}
export interface IAlumniRegister {
    firstName: string,
    lastName: string,
    dateOfBirth: Date,
    yearCompleted: string,
    sex?: string,
    phoneNbr: string,
    emailAddress: string,
    addressLine: string,
    completedDegree: string,

    activeJob?: string,
    facebookAcct?: string,
    twitterAcct?: string,
    linkedInAcct?: string,
}
export interface IAlumniRegisterError {
    firstName?: string,
    lastName?: string,
    dateOfBirth?: string,
    yearCompleted?: string,
    sex?: string,
    phoneNbr?: string,
    emailAddress?: string,
    addressLine?: string,
    completedDegree?: string,
    
    activeJob?: string,
    facebookAcct?: string,
    twitterAcct?: string,
    linkedInAcct?: string,
}
export interface IAskAboutKid {
    operationKey: string,
    userName: string,
    emailAddress: string,
    telNumber: string,
    studies: string,
    message: string,
    messageDate: Date,
    captcthaVal: string,
}
export interface IAskAboutKidSend {
    operationKey: string,
    userName: string,
    emailAddress: string,
    telNumber: string,
    studies: string,
    message: string,
    captcthaVal: string,
}
export interface IAskAboutKidError {
    userName?: string,
    emailAddress?: string,
    telNumber?: string,
    studies?: string,
    message?: string,
    captcthaVal?: string,
}
export interface IStaffTeam {
    id: number,
    title: string,
    headerContent: string,
    subheaderContent: string,
    quotationText: string,
    signatureData?: any,
    staffImage: any,
    staffName: string,
    staffJob: string,
}


export interface ISchoolQuote {
    quote: string,
    author: string,
}


export interface IAlumniBlogPost {
    postKey: string,
    publisherEmail: string,
    postDate: Date,
    postSubject: string,
    postTitle: string,
    postContent: string,
    featuredImage: string,
    tags: string[],
    published: boolean,
    featuredImageType: string,
    postLang: string,
    
}
export interface ICommentLike {
    likeKey: string,
    likeEmail: string,
    likeDate: Date,
}
export interface IAlumniBlogPostComment {
    commentKey: string,
    postKey: string,
    commenterEmail: string,
    commenterName: string,
    commentDate: Date,
    comment: string,
    commentLikes: ICommentLike[]
}

export interface IBlogRecord {
    post: IAlumniBlogPost,
    comments: IAlumniBlogPostComment[],
}
export interface IPexelsImageSrc {
    landscape?: string,
    large?: string,
    large2x?: string,
    medium?: string,
    original?: string,
    portrait?: string,
    small?: string,
    tiny?: string,
}
export interface IPexelsImage {
    alt: string,
    avg_color: string,
    height: number,
    id: number,
    liked: boolean,
    photographer: string,
    photographer_id: number,
    photographer_url: string,
    url: string,
    width: number,
    src: IPexelsImageSrc,
}
export interface IPexelsImageRequest {
    next_page: string,
    page: number,
    per_page: number,
    photos: IPexelsImage[],
}


export interface ISocialEvent {
    eventKey: string,
    publisherEmail: string,
    eventDate: Date,
    eventTitle: string,
    eventLocation: string,
    eventTags: string[],
    published: boolean,
}
export interface ISocialEventDetail {
    eventDetailKey: string,
    eventKey: string,
    eventPhotoName: string,
    fileSize: number,
    description?: string,
    photoTitle: string,
}
export interface ISocialEventComment {
    eventCmtKey: string,
    eventKey: string,
    typeComment: number,
    eventDetailKey?: string,
    commentDate: Date,
    eventComment: string,
    authorName: string,
    authorEmail: string,
    authorWebsite: string,
}
export interface ISocialEventCplx{
    socialEvent: ISocialEvent,
    lstDetails: ISocialEventDetail[],
    lstComments: ISocialEventComment[],
    publisher: IAlumniUser,
}
export interface ITagRec {
    id:string,
    text: string
}



export interface ISocialEvtFileElt {
    fileKey: string,
    fileName: string,
    fileExt: string,
    fileElt: File,
    title: string,
    descr: string,
    size: number,
}
export interface IScocialEvtData {
    eventKey: string,
    eventTitle: string,
    eventLocation: string,
    evtTags: ITagRec[],
}
export interface ISimpleData {
  title: string,
  description: string,
  eventKey: string,
}
export interface ISocialFileEltRecord {
  eventData: ISocialEvtFileElt, 
  onDelete: (eventKey: string) => void,
  onUpdateSingle: (data: ISimpleData) => void
}
export interface IFinalEventDetail {
    fileKey: string,
    description: string,
    title: string,
    fileName: string,
    fileSize: number,
    fileExtension: string,
}
export interface IFinalEventRecord {
    eventKey: string,
    eventTitle: string,
    eventLocation: string,
    evtTags: string[],
    details: IFinalEventDetail[]
}
export interface ITableRowBlog {
    postRecord: IBlogRecord,
    afterPublish: (lst: IBlogRecord[]) => void,
}

export interface IPublishStatusComp {
    published: boolean,
    postKey: string,
    afterPublish: (lst: IBlogRecord[]) => void,
}

export interface IBlogPostCommentsPost {
    postKey: string,
    nbrCmnts: number,
}



export interface IAlumniMessage{
    messageKey: string,
    senderEmail: string,
    receiverEmails: string[],
    messageSubject: string,
    messageContent: string,
    messageDate: Date,
    attachmentFiles?: string[],
}
export interface IAlumniMessageAttach{
    attachtKey: string,
    messageKey: string,
    senderEmail: string,
    fileName: string,
    savedFileName: string,
    fileSize: number,
    fileExtension: string,
    dateUploaded: Date,
}
export interface IAlumniMessageReading {
    readingKey: string,
    messageKey: string,
    readerEmail: string,
    readingDate: Date,
}
export interface IAlumniMessageDeletion{
    deletionKey: string,
    messageKey: string,
    deletedByEmail: string,
    deletionDate: Date,
}
export interface IAlumniMessageAnswer{
    answerKey: string,
    messageKey: string,
    messageAnswerKey: string,
    answeredByEmail: string,
    answerDate: Date,
}

export interface IAlMessageInbox {
    messageRecord: IAlumniMessage,
    messageReceivers: IAlumniShortUser[],
    status: 'inbox'|'sent'|'deleted',
    isRead: boolean,
    isAnswered: boolean,

    uOwner: boolean,
    senderRecord?: IAlumniShortUser,
}

export interface IAlumniShortUser {
    userName: string,
    userEmail: string,
    sex: string,
    phoneNbr: string,
    imagePath: string,
}
export interface IMultimediaFile {
    multimediaKey: string,
    resourceFileName: string,
    fileName: string,
    fileSize: number,
    fileExtension: string,
    uploaderEmail: string,
    dateUploaded: Date,
}

export interface IMessagewithAttach {
    message: IAlumniMessage,
    attachments: IAlumniMessageAttach[],
}


export interface ICalendarEvent {
    eventKey: string,
    userEmail: string,
    allDay: boolean,
    backgroundColor?: string,
    end: Date,
    groupId?: string,
    id: string,
    start: Date,
    textColor: string,
    title: string,
    hasEnd: boolean,
}


export interface ICalendarEventSend {
    eventKey: string,
    userEmail: string,
    allDay: boolean,
    backgroundColor: string,
    end: string,
    groupId: string,
    id: string,
    start: string,
    textColor: string,
    title: string,
    hasEnd: boolean,
}

export interface IStatResult {
    statType: 'blogs' | 'social' | 'messages' | 'events',
    arrowStyle: 'up' | 'down' | 'stable',
    currStartDate: Date,
    currEndDate: Date,
    prevStartDate: Date,
    prevEndDate: Date,
    value: string,
    prevValue: string
    percentValue: string
}


export interface IAlumniMember {
    member: IAlumniShortUser,
    isConnected: boolean,
    isMine: boolean,
    friends: IAlumniShortUser[],
}

export interface EventAndStatus {
    event: ICalendarEvent,
    isMine: boolean,
}



export interface IManagtUser {
    firstName: string,
    lastName: string,
    dateOfBirth: Date,
    sex?: string,
    phoneNbr: string,
    emailAddress: string,
    password?: string,
    addressLine: string,
    userActive: boolean,
    profilePicturePath?: string,
    mainAdmin: boolean,
    admin: boolean,
}

export interface IManagtUserUpdater {
    firstName: string,
    lastName: string,
    dateOfBirth: Date,
    sex: string,
    phoneNbr: string,
    emailAddress: string,
    password: string,
    confirmPassword: string,
    addressLine: string,
    admin: boolean,
}

export interface IManagtUserError {
    emailAddress?: string,
    firstName?: string,
    lastName?: string,
    phoneNbr?: string,
    password?: string,
    confirmPassword?: string,
}

export interface IManagtBlog {
    userEmail: string,
    blogKey: string,
    blogTitle: string,
    blogDescription: string,
    blogDate: Date,
    blogLang: string,
    blogAuthorEmail: string,
    blogImageUrl: string,
    blogPathUrl: string,
    blogColor: string,
    published: boolean,
}
export interface IManagtBlogError{
    blogLang?: string,
    blogAuthorEmail?: string,
    blogImageUrl?: string,    
    blogTitle?: string,
    blogDescription?: string,
    blogPathUrl?: string,
    blogColor?: string,
}


export interface IMailingList {
    mailKey: string,
    mailDate: Date,
    mailSubject: string,
    senderEmail: string,
    mailContent: string,
    mailReceivers: string[],
}

export interface IMailSubscription {
    recordKey: string,
    subscriptionDate: Date,
    email: string,   
}


export interface ICompMultimediaFile {
    multimediaKey: string,
    resourceFileName: string,
    fileName: string,
    fileSize: number,
    fileExtension: string,
    uploaderEmail: string,
    dateUploaded: Date,
}

export interface IContactAddressFormRec {
    contactKey: string,
    firstName: string,
    lastName: string,
    mailAddress: string,
    phoneAddress: string,
    lookingForWhat: number,
    priorityType: number,
    contactMessage: string,
    operationDate: Date,
}
export interface IVariant {
    id: string,
    label: string,
}
export interface IProductVariant {
    variantKey: string,
    productKey: string,
    variantName: string,
    values: IVariant[],
}
export interface IProductShop {
    productKey: string,
    productSKU: string,
    productName: string,
    productPrice: number,
    discountedPrice: number,
    imagePath: string[],
    category: number,
    productDescript?: string,
    operDate: Date,
    isAvailable: boolean,
}
export interface IProductShopError {
    productSKU?: string,
    productName?: string,
    productPrice?: string,
    discountedPrice?: string,
    discountedPriceErr?: string,
    imagePath?: string,
    category?: string,
    productDescript?: string,
}

export type IProductWithVariant = {
    product: IProductShop,
    variants: IProductVariant[]
}


export interface ISalesOperation {
    salesKey: string,
    operationKey: string,
    operationDate: Date,
    subTotal: number,
    total: number,
}

export interface ISalesOperationDetail {
    salesKey: string,
    detailKey: string,

    productName: string,

    productKey: string,
    productPrice: number,
    quantity: number,
    subtotal: number,
    variants?: {label: string, value: string}[]
}

export interface ISalesOperationContact {    
    salesKey: string,
    contactKey: string,

    firstName: string,
    lastName: string,
    companyName?: string,
    province: string,
    city: string,
    phone: string,
    email: string,
    roadNbr?: string,
    orderNotes?: string,
    typePaiement: number,
    paytDetail: string,
}


export interface ISalesComplex {
    operation: ISalesOperation,
    contact: ISalesOperationContact,
    details: ISalesOperationDetail[],
}

export interface IStatMngtResult {
    statType: 'blog'|'alumni'|'mailing'|'message'|'products'|'sales',
    arrowStyle: 'up' | 'down' | 'stable',
    currStartDate: Date,
    currEndDate: Date,
    prevStartDate: Date,
    prevEndDate: Date,
    value: string,
    prevValue: string
    percentValue: string
}








