/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { Link } from 'react-router-dom';


import Card from 'src/components/Card';
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";

import { IAskAboutKid, IContactAddressFormRec, IManagtUser} from 'src/models/smarttypes';
import NetServices from 'src/utils/netservices';

import { ErrorTableRow, LoadingTableRow, NoRecordTableRow } from 'src/components/majorsmallcomps';

import {ReactComponent as AskKidsSvg} from 'src/assets/images/svg/ask_kids.svg';
import {ReactComponent as ContactMsqSvg} from 'src/assets/images/svg/contact_message.svg';


const languages = getLanguages();
const appFxs = getFxs();

export default function ContactMessagePage() {
  document.title = `${languages.contactMessages} | ${languages.management} - ${languages.schoolName}`;
  const bLoaded = useRef(false);

  const [isLoading, setLoading] = useState(true);

  const [lstMessages, setLstMessages] = useState<IContactAddressFormRec[]>([]);
  const [lstAbtKids, setLstAbtKids] = useState<IAskAboutKid[]>([]);


  const [messageDisplay, setMessageDisplay] = useState("");
  const [hastNetError, setHastNetError] = useState(false);
  const [currentUser, setCurrentUser] = useState<IManagtUser | null>(null);
  const [typeDisplay, setTypeDisplay] = useState(0);

  const populateData = async () => {
    setLoading(true);
    setHastNetError(false);
    setMessageDisplay("");

    const oper = await NetServices.requestGet(
      "managt/getcontactmsfaskkids",
      false
    );

    if (!oper.bReturn) {
      setHastNetError(true);
      setMessageDisplay(oper.msgBody);
      setLoading(false);
      return;
    }

    setLstAbtKids(oper.lstAskAbtKidsMessages!);
    setLstMessages(oper.lstContactMessages!);
    setCurrentUser(oper.connectedUser!);

    setHastNetError(false);
    setMessageDisplay("");
    setLoading(false);
  };

  const onDeleteMsg = async (e: IContactAddressFormRec) => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteRecordQst
    );
    if (!bAsk.isConfirmed) return;

    const oper = await NetServices.requestPost(
      "managt/deletecontactmessage",
      {
        contactKey: e.contactKey,
      },
      true
    );

    if (!oper.bReturn) {
      appFxs.showAlert(oper.msgTitle, oper.msgBody);
      return;
    }

    setLstMessages(oper.lstContactMessages!);
  };
  const onDeleteAskMsg = async (e: IAskAboutKid) => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteRecordQst
    );
    if (!bAsk.isConfirmed) return;

    const oper = await NetServices.requestPost(
      "managt/deleteaskkidsmessage",
      {
        contactKey: e.operationKey,
      },
      true
    );

    if (!oper.bReturn) {
      appFxs.showAlert(oper.msgTitle, oper.msgBody);
      return;
    }

    setLstAbtKids(oper.lstAskAbtKidsMessages!);
  }

  useEffect(() => {
    if (bLoaded.current) return;
    populateData();
    bLoaded.current = true;
  }, []);

  return (
    <>
      <div className="flex flex-col gap-3">
        <Card>
          <div className="flex items-center">
            <div className="grow">
              <div className="flex">
                <h3 className="flex-1 text-lg font-bold text-gray-800">
                  {languages.contactMessages}
                </h3>
                <div className="flex gap-1">
                  <button
                    type="button"
                    className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                    onClick={() => populateData()}
                  >
                    <i className="fa fa-history"></i>
                  </button>
                </div>
              </div>
              <div className="mt-1 text-zinc-700 dark:text-gray-400">
                {languages.contactMessagesMsg}
              </div>
            </div>
            <div className="grow-0 flex items-center gap-2"></div>
          </div>
        </Card>

        {/*  */}
        <Card>
          <div className="flex flex-col">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold">
                {typeDisplay === 0
                  ? languages.contactMessages
                  : languages.askAbtKids}
              </h3>
              <ul className="menu menu-horizontal bg-base-200 rounded-box">
                <li>
                  <button
                    className="tooltip"
                    data-tip={languages.contactMessages}
                    onClick={() => setTypeDisplay(0)}
                  >
                    <ContactMsqSvg width={20} height={20} />
                  </button>
                </li>
                <li>
                  <button
                    className="tooltip"
                    data-tip={languages.askAbtKids}
                    onClick={() => setTypeDisplay(1)}
                  >
                    <AskKidsSvg width={20} height={20} />
                  </button>
                </li>
              </ul>
            </div>

            {typeDisplay === 0 && (
              <table className="table table-zebra">
                <thead>
                  <tr>
                    <th>{languages.sender}</th>
                    <th className="hidden sm:table-cell">{languages.email}</th>
                    <th className="hidden sm:table-cell">
                      {languages.phoneNbr}
                    </th>
                    <th className="hidden md:table-cell">{languages.date}</th>
                    <th className="hidden lg:table-cell">
                      {languages.lookingFor}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* Loading */}
                  {isLoading && <LoadingTableRow colSpan={6} />}

                  {/* error */}
                  {!isLoading && hastNetError && (
                    <ErrorTableRow
                      colSpan={6}
                      messageDisplay={messageDisplay}
                      actionToTake={populateData}
                      buttonIconCss="fa fa-history"
                    />
                  )}

                  {/* No Record */}
                  {!isLoading && !hastNetError && lstMessages.length === 0 && (
                    <NoRecordTableRow
                      colSpan={6}
                      messageDisplay={languages.noContactMsgFnd}
                      buttonIconCss="fa fa-refresh"
                      actionToTake={() => populateData()}
                    />
                  )}

                  {/* list */}
                  {!isLoading &&
                    !hastNetError &&
                    lstMessages.length > 0 &&
                    lstMessages.map((oMsg, idx) => (
                      <ContactMsgRow
                        message={oMsg}
                        key={idx}
                        meUser={currentUser!}
                        onDeleteMsg={(e) => onDeleteMsg(e)}
                      />
                    ))}
                </tbody>
              </table>
            )}

            {typeDisplay === 1 && (
              <table className="table table-zebra">
                <thead>
                  <tr>
                    <th>{languages.sender}</th>
                    <th className="hidden sm:table-cell">{languages.email}</th>
                    <th className="hidden sm:table-cell">{languages.phoneNbr}</th>
                    <th className="hidden md:table-cell">{languages.date}</th>
                    <th className="hidden lg:table-cell">{languages.classRoom}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* Loading */}
                  {isLoading && <LoadingTableRow colSpan={6} />}

                  {/* error */}
                  {!isLoading && hastNetError && (
                    <ErrorTableRow
                      colSpan={6}
                      messageDisplay={messageDisplay}
                      actionToTake={populateData}
                      buttonIconCss="fa fa-history"
                    />
                  )}

                  {/* No Record */}
                  {!isLoading && !hastNetError && lstAbtKids.length === 0 && (
                    <NoRecordTableRow
                      colSpan={6}
                      messageDisplay={languages.noContactMsgFnd}
                      buttonIconCss="fa fa-refresh"
                      actionToTake={() => populateData()}
                    />
                  )}

                  {/* list */}
                  {!isLoading &&
                    !hastNetError &&
                    lstAbtKids.length > 0 &&
                    lstAbtKids.map((oMsg, idx) => (
                      <AskKidMsgRow
                        message={oMsg}
                        key={idx}
                        meUser={currentUser!}
                        onDeleteMsg={(e) => onDeleteAskMsg(e)}
                      />
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </Card>
      </div>
    </>
  );
}

interface ContactMsgRowProps {
    message: IContactAddressFormRec,
    meUser: IManagtUser,
    onDeleteMsg: (e: IContactAddressFormRec) => void,
}

const ContactMsgRow = ({
  message,
  meUser,
  onDeleteMsg,
}: ContactMsgRowProps) => {
  const sender = `${message.firstName} ${message.lastName}`;
  const priorityType = 
    message.priorityType === 0 ? languages.low:
    message.priorityType === 1 ? languages.medium:
    message.priorityType === 2 ? languages.high:
    languages.low;
  const priorityClass = 
    message.priorityType === 0 ? "text-blue-500 hover:text-blue-700":
    message.priorityType === 1 ? "text-green-500 hover:text-green-700":
    message.priorityType === 2 ? "text-red-500 hover:text-red-700":
    "text-blue-500 hover:text-blue-700";
  
  const priorityArrow = 
    message.priorityType === 0 ? "fa fa-arrow-down":
    message.priorityType === 1 ? "fa fa-arrow-right":
    message.priorityType === 2 ? "fa fa-arrow-up":
    "fa fa-arrow-down";
  
  const lookingForType = 
    message.lookingForWhat === 0 ? languages.job:
    message.lookingForWhat === 1 ? languages.student:
    message.lookingForWhat === 2 ? languages.staff:
    languages.low;


  return (
    <tr>
      {/* Subject */}
      <td>
        <div className="flex flex-col gap-1">
          {/* Sender */}
          <Link
            title={priorityType}
            className={priorityClass}
            to={`/app/main/contactmessages/preview/${message.contactKey}`}
          >
            <i
              className={priorityArrow}
            ></i>
            &nbsp;{sender}
          </Link>

          {/* Email */}
          <span
            className="sm:hidden text-slate-600"
            title={languages.mailAddress}
          >
            <span className="flex items-center gap-1">
              <i className="fa fa-envelope"></i>
              <a href={`mailto:${message.mailAddress}`}>
                {message.mailAddress}
              </a>
            </span>
          </span>

          {/* Phone */}
          <span className="sm:hidden text-slate-600" title={languages.phoneNbr}>
            <span className="flex items-center gap-1">
              <i className="fa fa-phone"></i>
              <a href={`tel:${message.phoneAddress}`}>{message.phoneAddress}</a>
            </span>
          </span>

          {/* date */}
          <span className="md:hidden text-slate-600" title={languages.date}>
            <span className="flex items-center gap-1">
              <i className="fa fa-calendar"></i>
              {moment(message.operationDate).format("DD/MM/YY HH:mm")}
            </span>
          </span>
        </div>
      </td>
      {/* email */}
      <td className="hidden sm:table-cell">
        <span className="flex items-center gap-1 text-blue-500 hover:text-blue-700">
          <a href={`mailto:${message.mailAddress}`}>{message.mailAddress}</a>
        </span>
      </td>

      {/* phone */}
      <td className="hidden sm:table-cell">
        <span className="flex items-center gap-1 text-blue-500 hover:text-blue-700">
          <a href={`tel:${message.phoneAddress}`}>{message.phoneAddress}</a>
        </span>
      </td>

      {/* Date */}
      <td className="hidden md:table-cell">
        <span className="flex items-center gap-1">
          {moment(message.operationDate).format("DD/MM/YY HH:mm")}
        </span>
      </td>

      {/* lookingfor */}
      <td className="hidden lg:table-cell">
        <span className="flex items-center gap-1">
          {lookingForType}
        </span>
      </td>
      {/* actions */}
      <td>
        <div className="flex items-center gap-1 justify-end">
          <Link
            className="border border-solid border-sky-500 rounded-md py-1 px-2 inline-block"
            title={languages.preview}
            to={`/app/main/contactmessages/preview/${message.contactKey}`}
          >
            <i className="fa fa-eye text-sky-500"></i>
          </Link>

          {meUser.mainAdmin && (
            <button
              className="border border-red-500 rounded-md py-1 px-2"
              onClick={() => onDeleteMsg(message)}
              title={languages.delete}
            >
              <i className="fa fa-trash-o text-red-500"></i>
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

interface AskKidMsgRowProps {
  message: IAskAboutKid,
  meUser: IManagtUser,
  onDeleteMsg: (e: IAskAboutKid) => void,
}
const AskKidMsgRow = ({
  message,
  meUser,
  onDeleteMsg,
}: AskKidMsgRowProps) => {
  const sender = message.userName;

  /* const priorityType = 
    message.priorityType === 0 ? languages.low:
    message.priorityType === 1 ? languages.medium:
    message.priorityType === 2 ? languages.high:
    languages.low;
  const priorityClass = 
    message.priorityType === 0 ? "text-blue-500 hover:text-blue-700":
    message.priorityType === 1 ? "text-green-500 hover:text-green-700":
    message.priorityType === 2 ? "text-red-500 hover:text-red-700":
    "text-blue-500 hover:text-blue-700";
  
  const priorityArrow = 
    message.priorityType === 0 ? "fa fa-arrow-down":
    message.priorityType === 1 ? "fa fa-arrow-right":
    message.priorityType === 2 ? "fa fa-arrow-up":
    "fa fa-arrow-down";
  
  const lookingForType = 
    message.lookingForWhat === 0 ? languages.job:
    message.lookingForWhat === 1 ? languages.student:
    message.lookingForWhat === 2 ? languages.staff:
    languages.low; */


  return (
    <tr>
      {/* Subject */}
      <td>
        <div className="flex flex-col gap-1">
          {/* Sender */}
          <Link className='text-blue-500 hover:text-blue-700'
            to={`/app/main/contactmessages/askpreview/${message.operationKey}`}>
              {sender}
          </Link>

          {/* Email */}
          <span
            className="sm:hidden text-slate-600"
            title={languages.mailAddress}
          >
            <span className="flex items-center gap-1">
              <i className="fa fa-envelope"></i>
              <a href={`mailto:${message.emailAddress}`}>
                {message.emailAddress}
              </a>
            </span>
          </span>

          {/* Phone */}
          <span className="sm:hidden text-slate-600" title={languages.phoneNbr}>
            <span className="flex items-center gap-1">
              <i className="fa fa-phone"></i>
              <a href={`tel:${message.telNumber}`}>{message.telNumber}</a>
            </span>
          </span>

          {/* date */}
          <span className="md:hidden text-slate-600" title={languages.date}>
            <span className="flex items-center gap-1">
              <i className="fa fa-calendar"></i>
              {moment(message.messageDate).format("DD/MM/YY HH:mm")}
            </span>
          </span>
        </div>
      </td>
      {/* email */}
      <td className="hidden sm:table-cell">
        <span className="flex items-center gap-1 text-blue-500 hover:text-blue-700">
          <a href={`mailto:${message.emailAddress}`}>{message.emailAddress}</a>
        </span>
      </td>

      {/* phone */}
      <td className="hidden sm:table-cell">
        <span className="flex items-center gap-1 text-blue-500 hover:text-blue-700">
          <a href={`tel:${message.telNumber}`}>{message.telNumber}</a>
        </span>
      </td>

      {/* Date */}
      <td className="hidden md:table-cell">
        <span className="flex items-center gap-1">
          {moment(message.messageDate).format("DD/MM/YY HH:mm")}
        </span>
      </td>
      {/* actions */}
      <td>
        <div className="flex items-center gap-1 justify-end">
          <Link
            className="border border-solid border-sky-500 rounded-md py-1 px-2 inline-block"
            title={languages.preview}
            to={`/app/main/contactmessages/askpreview/${message.operationKey}`}
          >
            <i className="fa fa-eye text-sky-500"></i>
          </Link>

          {meUser.mainAdmin && (
            <button
              className="border border-red-500 rounded-md py-1 px-2"
              onClick={() => onDeleteMsg(message)}
              title={languages.delete}
            >
              <i className="fa fa-trash-o text-red-500"></i>
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};