import React, { useState } from 'react'

import WiseModal from '../modalportal/WiseModal';

import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";
import { IManagtUser, IManagtBlog, IManagtBlogError } from 'src/models/smarttypes';
import NetServices from 'src/utils/netservices';


const languages = getLanguages();
const appFxs = getFxs();

type ManagtBlogFormProps = {
    isOpen: boolean;
    onClose: (lst?: IManagtBlog[]) => void;
    dialogWidth?: number;
    currentRecord: IManagtBlog;
    recordStatus: number;
    isMainAdmin: boolean;
    dialogTitle: string;
    meUser: IManagtUser;
    authors: IManagtUser[];
}
type ManagtBlogFormWrapperProps = {
    currentRecord: IManagtBlog;
    meUser: IManagtUser;
    isMainAdmin: boolean;
    recordStatus: number;
    authors: IManagtUser[];
    onClose: (lst?: IManagtBlog[]) => void;
}

export default function ManagtBlogForm({
  isOpen,
  onClose,
  dialogWidth,
  currentRecord,
  isMainAdmin,
  recordStatus,
  dialogTitle,
  meUser,
  authors,
}: ManagtBlogFormProps) {
  if (!isOpen) return null;
  return (
    <WiseModal
      isOpen={isOpen}
      onClose={() => onClose()}
      titleBox={dialogTitle}
      width={dialogWidth ?? 450}
      hasFooter={false}
    >
        <ManagtBlogFormWrapper 
            currentRecord={currentRecord}
            isMainAdmin={isMainAdmin}
            recordStatus={recordStatus}
            onClose={(lst) => onClose(lst)}
            meUser={meUser}
            authors={authors}
             />
    </WiseModal>
  );
}

const ManagtBlogFormWrapper = (
    {
        isMainAdmin, 
        recordStatus, 
        onClose, 
        currentRecord,
        meUser,
        authors
    }: ManagtBlogFormWrapperProps) => {

    const [formData, setFormData] = useState<IManagtBlog>({...currentRecord});
    const [formError, setFormError] = useState<IManagtBlogError>({});

    const onChangeFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const deleteManagtRecord = async () => {
      const bAsk = await appFxs.showConfirm(
        languages.delete,
        languages.deleteRecordQst
      );
      if (!bAsk.isConfirmed) return;
      const oper = await NetServices.requestPost(
        "managt/deletemanagtblog",
        {
            blogDeleteKey: currentRecord.blogKey,
        },
        true
      );

      if (!oper.bReturn) {
        appFxs.showAlert(oper.msgTitle, oper.msgBody);
        return;
      }

      onClose(oper.lstManagtBlogs!);
    }
    const saveRecordToDB = () => {
        const errors: IManagtBlogError = {};
        if (!formData.blogTitle || formData.blogTitle.length === 0) {
          errors.blogTitle = languages.fldRequired;
        }
        if (!formData.blogDescription || formData.blogDescription.length === 0) {
          errors.blogDescription = languages.fldRequired;
        }
        if (!formData.blogLang || formData.blogLang.length === 0) {
          errors.blogLang = languages.fldRequired;
        }
        if (!formData.blogImageUrl || formData.blogImageUrl.length === 0) {
          errors.blogImageUrl = languages.fldRequired;
        }
        if (!formData.blogPathUrl || formData.blogPathUrl.length === 0) {
          errors.blogPathUrl = languages.fldRequired;
        }
        if (!formData.blogAuthorEmail || formData.blogAuthorEmail.length === 0) {
          errors.blogAuthorEmail = languages.fldRequired;
        }
        if (!formData.blogColor || formData.blogColor.length === 0) {
          errors.blogColor = languages.fldRequired;
        }

        setFormError(errors);

        if(Object.keys(errors).length>0)return;

        onSaveRecord();
    }

    const onSaveRecord = async () => {
        const oper = await NetServices.requestPost('managt/saveblogrecord', {
            recordManagtBlog: formData,
            recordStatus: recordStatus,
        }, true);

        if(!oper.bReturn){
            appFxs.showAlert(oper.msgTitle, oper.msgBody);
            return;
        }

        onClose(oper.lstManagtBlogs!);
    }



    return (
      <div className="flex flex-col">
        <div className="pt-0 pb-4 flex flex-col max-h-[350px] overflow-y-auto px-2">
          {/* Title */}
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">{languages.title}</span>
            </div>
            <input
              type="text"
              placeholder={languages.title.toLowerCase()}
              className="input input-bordered w-full ring-1"
              value={formData.blogTitle}
              name="blogTitle"
              onChange={onChangeFormData}
              disabled={recordStatus !== 0}
            />
            {formError.blogTitle && (
              <div className="label">
                <span className="label-text-alt text-red-500">
                  {formError.blogTitle}
                </span>
              </div>
            )}
          </label>

          {/* blogDescription */}
          <label className="form-control w-full mt-2">
            <div className="label">
              <span className="label-text">{languages.description}</span>
            </div>
            <textarea
              placeholder={languages.description.toLowerCase()}
              className="textarea textarea-bordered w-full ring-1 no-resize"
              value={formData.blogDescription}
              name="blogDescription"
              rows={6}
              onChange={(e) =>
                setFormData({ ...formData, blogDescription: e.target.value })
              }
            ></textarea>
            {formError.blogDescription && (
              <div className="label">
                <span className="label-text-alt text-red-500">
                  {formError.blogDescription}
                </span>
              </div>
            )}
          </label>

          {/* image url */}          
          <label className="form-control w-full mt-2">
            <div className="label">
              <span className="label-text">{languages.imageUrl}</span>
            </div>
            <input
              type="url"
              placeholder={languages.imageUrl.toLowerCase()}
              className="input input-bordered w-full ring-1"
              value={formData.blogImageUrl}
              name="blogImageUrl"
              onChange={onChangeFormData}
            />
            {formError.blogImageUrl && (
              <div className="label">
                <span className="label-text-alt text-red-500">
                  {formError.blogImageUrl}
                </span>
              </div>
            )}
          </label>

          {/* blog url */}
          <label className="form-control w-full mt-2">
            <div className="label">
              <span className="label-text">{languages.blogPathUrl}</span>
            </div>
            <input
              type="url"
              placeholder={languages.blogPathUrl.toLowerCase()}
              className="input input-bordered w-full ring-1"
              value={formData.blogPathUrl}
              name="blogPathUrl"
              onChange={onChangeFormData}
            />
            {formError.blogPathUrl && (
              <div className="label">
                <span className="label-text-alt text-red-500">
                  {formError.blogPathUrl}
                </span>
              </div>
            )}
          </label>

          {/* Color */}
          <div className='flex items-end gap-1'>
            <label className="form-control flex-1 mt-2">
              <div className="label">
                <span className="label-text" style={{color: formData.blogColor}}>{languages.blogColor}</span>
              </div>
              <select
                className="ring-1 select select-bordered w-full"
                name="blogColor"
                value={formData.blogColor}
                onChange={(e) =>
                  setFormData({ ...formData, blogColor: e.target.value })
                }
              >
                <option value="#5d58f0">{languages.color_1}</option>  
                <option value="#799f05">{languages.color_2}</option>  
                <option value="#3cd8e8">{languages.color_3}</option>  
                <option value="#ff236c">{languages.color_4}</option>  
                <option value="#f1aa00">{languages.color_5}</option>              
              </select>
              {formError.blogColor && (
                <div className="label">
                  <span className="label-text-alt text-red-500">
                    {formError.blogColor}
                  </span>
                </div>
              )}
            </label>
            <div className='w-7 h-7' style={{backgroundColor: formData.blogColor}}></div>
          </div>

          {/* Author */}
          <label className="form-control w-full mt-2">
            <div className="label">
              <span className="label-text">{languages.author}</span>
            </div>
            <select
              className="ring-1 select select-bordered w-full"
              name="blogAuthorEmail"
              value={formData.blogAuthorEmail}
              onChange={(e) =>
                setFormData({ ...formData, blogAuthorEmail: e.target.value })
              }
            >
              <option value="">{languages.selectAuthor}</option>
              {authors.map((oAuthor) => (
                <option
                  value={oAuthor.emailAddress}
                >{`${oAuthor.firstName} ${oAuthor.lastName}`}</option>
              ))}
            </select>
            {formError.blogAuthorEmail && (
              <div className="label">
                <span className="label-text-alt text-red-500">
                  {formError.blogAuthorEmail}
                </span>
              </div>
            )}
          </label>

          {/* Is Admin */}
            <div className="flex items-center gap-2 w-full py-3 mt-2">
                <input
                    type="checkbox"
                    className="toggle border border-solid"
                    id="admin"
                    checked={formData.published}
                    onChange={() =>
                        setFormData({ ...formData, published: !formData.published })
                    }
                />
                <label htmlFor="admin" className="text-black cursor-pointer">
                    {languages.published}
                </label>
            </div>
        </div>

        {/* Actions */}
        <div className="modal-action">
          <div className="w-full flex justify-between">
            {(isMainAdmin ||
              currentRecord.blogAuthorEmail === meUser.emailAddress) &&
            recordStatus !== 0 ? (
              <div>
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={deleteManagtRecord}
                >
                  {languages.delete}
                </button>
              </div>
            ) : (
              <span></span>
            )}
            <div className="flex gap-2 items-center">
              <button className="btn" onClick={() => onClose()}>
                {languages.close}
              </button>
              {(recordStatus === 0 ||
                isMainAdmin ||
                currentRecord.blogAuthorEmail === meUser.emailAddress) && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveRecordToDB}
                >
                  {languages.save}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
}