import getLang from 'src/utils/language';
const languages = getLang();

interface LoadingTableRowProps {
  colSpan: number,
  loadText?: string,
}

interface ErrorTableRowProps {
  colSpan: number,
  messageDisplay?: string,
  actionToTake?: () => void,
  buttonIconCss?: string,
}
interface NoRecordTableRowProps {
  colSpan: number,
  messageDisplay: string,
  actionToTake?: () => void,
  buttonIconCss?: string,
}


export const LoadingTableRow = ({colSpan, loadText}: LoadingTableRowProps) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="h-auto py-2 flex flex-col justify-center items-center space-y-2">
          <span className="loading loading-spinner loading-lg"></span>
          <h3 className="font-semibold text-lg">{loadText ?? languages.loading}</h3>
        </div>
      </td>
    </tr>
  );
};

export const ErrorTableRow = ({ colSpan, messageDisplay, actionToTake, buttonIconCss }: ErrorTableRowProps) => {
  const iconCss = buttonIconCss ?? 'fa fa-history';
  const handleClick = () => {
    if(!actionToTake)return;
    actionToTake!();
  }
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="h-auto py-2 flex flex-col justify-center items-center space-y-2">
          <div className="flex flex-col items-center justify-center">
            <h3 className="text-lg my-3">{messageDisplay}</h3>

            <button
              type="button"
              className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
              onClick={handleClick}
            >
              <i className={iconCss}></i>
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export const NoRecordTableRow = ({colSpan, messageDisplay, actionToTake, buttonIconCss}: NoRecordTableRowProps) => {

  const handleClick = () => {
    if(!actionToTake)return;
    actionToTake!();
  }
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="h-auto py-2 flex flex-col justify-center items-center space-y-2">
          <div className="flex flex-col items-center justify-center">
            <h3 className="text-lg my-3">{messageDisplay}</h3>
            <button
              type="button"
              className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
              onClick={handleClick}
            >
              <i className={buttonIconCss}></i>
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

