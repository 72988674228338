/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Card from 'src/components/Card';
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";

import NetServices from 'src/utils/netservices';

import { LinkAchorRound } from 'src/components/linkanchorround/LinkAchorRound';

import { IProductShop, IProductShopError } from 'src/models/smarttypes';
import LoadingWaitSpace from 'src/components/LoadingWaitSpace';

const languages = getLanguages();
const appFxs = getFxs();


interface ProductImageClickerProps {
  onReceivefile: (f: File) => void,
}

interface ProductImageWrapperProps {
  fileName: string, 
  onRemove: (fileName: string) => void,
  remainFiles: number,
  openWindowFile: (imagePath: string) => void,
  downloadFile: (imagePath: string) => void,
}
interface ProductViewerProps {
  product: IProductShop,
  afterUpdate: (e: IProductShop) => void,
}


export default function ProductPreviewPage() {
  const [isLoading, setLoading] = useState(true);
  const [productTitle, setProductTitle] = useState(`${languages.loading}...`);
  const [productRecord, setProductRecord] = useState<IProductShop | null>(null);

  document.title = `${productTitle} | ${languages.management} - ${languages.schoolName}`;
  const navigate = useNavigate();
  const bLoaded = useRef(false);
  const { productKey } = useParams();

  const populateData = async () => {
    setLoading(true);
    setProductTitle(`${languages.loading}...`);

    const oper = await NetServices.requestGet(
      `managt/getoneproductrecord/${productKey}`,
      false
    );
    if (!oper.bReturn) {
      await appFxs.showAlert(oper.msgTitle, oper.msgBody);
      navigate("/app/main/products");
      return;
    }
    setProductTitle(oper.productShopRecord!.productName);
    setProductRecord(oper.productShopRecord!);
    setLoading(false);
  };

  const afterUpdateProd = (e: IProductShop) => {
    setLoading(true);

    setTimeout(() => {
      setProductRecord(e);
      setLoading(false);
    }, 1000);
  }

  useEffect(() => {
    if (bLoaded.current) return;
    populateData();
    bLoaded.current = true;
  }, []);

  return (
    <div className="flex flex-col gap-3">
      <Card>
        <div className="flex items-center">
          <div className="grow">
            <div className="flex">
              <h3 className="flex-1 text-lg font-bold text-gray-800">
                {languages.product}
              </h3>
              <div className="flex gap-1">
                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={() => navigate('/app/main/products')}>
                  <i className="fa fa-list"></i>
                </button>
                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={() => populateData()}>
                  <i className="fa fa-history"></i>
                </button>
              </div>
            </div>
            <div className="mt-1 text-zinc-700 dark:text-gray-400">
              {languages.productMsg}
            </div>
          </div>
          <div className="grow-0 flex items-center gap-2"></div>
        </div>
      </Card>

      <Card>
        <div className="flex flex-col">
          {isLoading && <LoadingWaitSpace height={150} />}
          {!isLoading && 
            <ProductViewer 
              product={productRecord!} 
              afterUpdate={afterUpdateProd} 
              />
          }
        </div>
      </Card>
    </div>
  );
}


const ProductViewer = ({ product, afterUpdate }: ProductViewerProps) => {
  const [formData, setFormData] = useState<IProductShop>(product);
  const [formError, setFormError] = useState<IProductShopError>({});

  const [skuLen, setSkuLen] = useState(product.productSKU.length);

  const onRefuseNonNumer = (e: React.KeyboardEvent<HTMLInputElement>) => {
    appFxs.strictlyNbr(e);
  };

  const onRemoveImage = async (fileName: string) => {
    const bAsk = await appFxs.showConfirm(languages.delete, languages.deleteImgQst);
    if(!bAsk.isConfirmed)return;

    const oper = await NetServices.requestPost('managt/removeoneimagefromproduct',
     {
      productKey: product.productKey,
      productFileName: fileName,
     }, 
     true);

     if(!oper.bReturn){
      appFxs.showAlert(oper.msgTitle, oper.msgBody);
      return;
     }

     afterUpdate(oper.productShopRecord!);
  };

  const onReceiveImageFile = async (f: File) => {
    const bAsk = await appFxs.showConfirm(languages.images, languages.imageProdAddQst);
    if(!bAsk.isConfirmed)return;

    const file = `${appFxs.generateUUID()}.${f.name.split('.').pop()}`;

    const oData = new FormData();
    oData.append('productKey', product.productKey);
    oData.append('productFileName', file);
    oData.append(file, f);


    const oper = await NetServices.requestPostWithFileAxios(
      'managt/saveproductimageone', 
      oData, 
      true,
    );

    if(!oper.bReturn){
      appFxs.showAlert(oper.msgTitle, oper.msgBody);
      return;
    }

    afterUpdate(oper.productShopRecord!);
  };

  const saveProductRec = () => {
    const errors: IProductShopError = {};

    if (!formData.productSKU || formData.productSKU.length === 0) {
      errors.productSKU = languages.fldRequired;
    } else if (formData.productSKU.length < 15) {
      errors.productSKU = languages.prodSkuError;
    }
    if (!formData.productName || formData.productName.length === 0) {
      errors.productName = languages.fldRequired;
    }
    if (!formData.productPrice || formData.productPrice === 0) {
      errors.productPrice = languages.fldRequired;
    }
    if (formData.discountedPrice.toString().length === 0) {
      errors.discountedPrice = languages.fldRequired;
    } else if (formData.productPrice && formData.discountedPrice) {
      if (formData.discountedPrice > formData.productPrice) {
        errors.discountedPrice = languages.discountedPriceErr;
      }
    }

    setFormError(errors);
    if (Object.keys(errors).length > 0) return;

    saveFinalData();
  };
  const saveFinalData = async () => {
    const bAsk = await appFxs.showConfirm(languages.save, languages.updateProdQst);
    if(!bAsk.isConfirmed)return;

    const oper = await NetServices.requestPost('managt/updateproductrecord', {
      productRecord: formData
    }, true);

    if(!oper.bReturn){
      appFxs.showAlert(oper.msgTitle, oper.msgBody);
      return;
    }

    afterUpdate(oper.productShopRecord!);
  };
  const openWindowFile = (f: string) => {
    appFxs.openWindowUrl(f);
  }
  const downloadFile = (f:string) => {
    const fileName = f.split('/').pop();
    appFxs.downloadFromFileUrl(f, fileName!);
  }

  return (
    <div className="flex flex-col">
      <h3 className="text-lg font-semibold py-2 mb-2">{product.productName}</h3>
      <h3 className="text-lg font-semibold mb-2">
        {languages.images}&nbsp;
        {formError.imagePath && (
          <span className="text-red-400">{formError.imagePath}</span>
        )}
      </h3>

      {/* Images */}
      <div className="flex flex-wrap gap-2 py-1">
        {product.imagePath.map((o, idx) => (
          <ProductImageWrapper
            remainFiles={product.imagePath.length}
            onRemove={onRemoveImage}
            fileName={o}
            openWindowFile={openWindowFile}
            downloadFile={downloadFile}
            key={idx}
          />
        ))}
        <ProductImageClicker onReceivefile={(e) => onReceiveImageFile(e)} />
      </div>

      <div className="flex flex-wrap justify-between">
        {/* sku */}
        <label className="form-control block basis-full md:basis-[49%] mb-2">
          <div className="label">
            <span className="label-text">{languages.skuV2}</span>{" "}
            <span>
              (<span className="font-semibold">{skuLen}</span>/15)
            </span>
          </div>
          <input
            type="text"
            placeholder={languages.sku}
            className="input input-bordered w-full ring-1"
            name="productSKU"
            value={formData.productSKU}
            onKeyPress={onRefuseNonNumer}
            maxLength={15}
            onChange={(e) => {
              setSkuLen(e.target.value.length);
              setFormData({ ...formData, productSKU: e.target.value });
            }}
            disabled={true}
          />
          {formError.productSKU && (
            <div className="w-full py-1 text-red-500">
              {formError.productSKU}
            </div>
          )}
        </label>

        {/* productName */}
        <label className="form-control block basis-full md:basis-[49%] mb-2">
          <div className="label">
            <span className="label-text">{languages.productName}</span>
            <span>&nbsp;</span>
          </div>
          <input
            type="text"
            placeholder={languages.productName}
            className="input input-bordered w-full ring-1"
            name="productName"
            value={formData.productName}
            onChange={(e) =>
              setFormData({ ...formData, productName: e.target.value })
            }
          />
          {formError.productName && (
            <div className="w-full py-1 text-red-500">
              {formError.productName}
            </div>
          )}
        </label>

        {/* productPrice */}
        <label className="form-control block basis-full md:basis-[49%] mb-2">
          <div className="label">
            <span className="label-text">{languages.price}</span>
            <span className="text-sm font-semibold">{"Rwf".toLowerCase()}</span>
          </div>
          <input
            type="text"
            placeholder={languages.price}
            className="input input-bordered w-full ring-1 text-right"
            name="productPrice"
            value={formData.productPrice}
            onKeyPress={onRefuseNonNumer}
            onChange={(e) =>
              setFormData({ ...formData, productPrice: Number(e.target.value) })
            }
          />
          {formError.productPrice && (
            <div className="w-full py-1 text-red-500">
              {formError.productPrice}
            </div>
          )}
        </label>

        {/* discountedPrice */}
        <label className="form-control block basis-full md:basis-[49%] mb-2">
          <div className="label">
            <span className="label-text">{languages.discounted}</span>
            <span className="text-sm font-semibold">{"Rwf".toLowerCase()}</span>
          </div>
          <input
            type="text"
            placeholder={languages.discounted}
            className="input input-bordered w-full ring-1 text-right"
            name="discountedPrice"
            value={formData.discountedPrice}
            onKeyPress={onRefuseNonNumer}
            onChange={(e) =>
              setFormData({
                ...formData,
                discountedPrice: Number(e.target.value),
              })
            }
          />
          {formError.discountedPrice && (
            <div className="w-full py-1 text-red-500">
              {formError.discountedPrice}
            </div>
          )}
        </label>

        {/* category */}
        <label className="form-control block basis-full md:basis-[49%] mb-2">
          <div className="label">
            <span className="label-text">{languages.category}</span>
          </div>
          <select
            className="select select-bordered w-full ring-1"
            name="category"
            value={formData.category}
            onChange={(e) =>
              setFormData({ ...formData, category: Number(e.target.value) })
            }
          >
            <option value={0}>{languages.foodBev}</option>
            <option value={1}>{languages.schoolSupplStat}</option>
            <option value={2}>{languages.clothAccess}</option>
            <option value={3}>{languages.services}</option>
            <option value={4}>{languages.otherAweSome}</option>
          </select>
          {formError.category && (
            <div className="w-full py-1 text-red-500">{formError.category}</div>
          )}
        </label>

        {/* productDescript */}
        <label className="form-control block basis-full md:basis-[49%] mb-2">
          <div className="label">
            <span className="label-text">{languages.description}</span>
          </div>
          <textarea
            placeholder={languages.description}
            className="textarea textarea-bordered w-full ring-1 no-resize"
            name="productDescript"
            value={formData.productDescript}
            onChange={(e) =>
              setFormData({ ...formData, productDescript: e.target.value })
            }
          ></textarea>
          {formError.productDescript && (
            <div className="w-full py-1 text-red-500">
              {formError.productDescript}
            </div>
          )}
        </label>

        <div className="flex items-center w-full">
          <label className="label cursor-pointer flex gap-2">
            <span className="label-text">{languages.available}</span>
            <input type="checkbox" 
              className="toggle border border-slate-500 border-solid" 
              checked={formData.isAvailable}
              onChange={() => setFormData({...formData, isAvailable: !formData.isAvailable})} />
          </label>
        </div>

        <div className="w-full mt-4">
          <LinkAchorRound
            text={languages.save}
            type="button"
            fullWidth={true}
            borderRadius={18}
            onClick={saveProductRec}
            bgColor="#0b5b13"
          />
        </div>
      </div>
    </div>
  );
};












const ProductImageWrapper = ({
  fileName, 
  onRemove, 
  remainFiles, 
  openWindowFile, 
  downloadFile,
}:ProductImageWrapperProps) => {
  const filePath = `${appDecl.apiUrlTest}/cloud_data/shop_products/${fileName}`;

  return (
    <div className='group w-[100px] h-[150px] rounded-lg border border-slate-600 border-solid relative overflow-hidden'>
      <img src={filePath} 
        alt=''
        className='z-0 absolute top-0 right-0 w-full h-full object-cover object-top'/>
      <div className='z-[1] absolute top-0 right-0 w-full h-full hidden group-hover:block bg-transition cursor-pointer'
        style={{backgroundColor: 'rgba(0,0,0,.6)'}}
        onClick={() => openWindowFile(filePath)}></div>
      {remainFiles > 1 && 
        <button className='hidden group-hover:inline-block z-[1] absolute right-1 top-1'
          title={languages.remove}
          onClick={() => onRemove(fileName)}>
          <i className='fa fa-trash text-white hover:text-green-700'></i>
        </button>
      }
      <button className='hidden group-hover:inline-block z-[1] absolute right-7 top-1'
          title={languages.download}
          onClick={() => downloadFile(fileName)}>
          <i className='fa fa-download text-white hover:text-green-700'></i>
        </button>
    </div>
  );
}


const ProductImageClicker = ({onReceivefile}:ProductImageClickerProps) => {
  const fileSelector = useRef<HTMLInputElement>(null);
  const onSelectFile = () => {
    fileSelector?.current?.click();
  }
  const receiveFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    onReceivefile(Array.from(e.target.files!).at(0)!);
  }

  return (
    <div className='group w-[100px] h-[150px] rounded-lg border border-slate-600 border-solid relative overflow-hidden flex items-center justify-center'
      style={{backgroundColor: 'rgba(0,0,0,.6)'}}>      
      <button className='' 
        title={languages.addOnePictureTime}
        onClick={onSelectFile}>
        <i className='fa fa-plus-circle text-white fa-3x'></i>
      </button>
      <input accept="image/*"
        ref={fileSelector} 
        type='file' 
        className='hidden' 
        onChange={receiveFile}
        multiple={false} />
    </div>
  );
}
