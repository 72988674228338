/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from 'src/components/Card';
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";

import NetServices from 'src/utils/netservices';

import { LinkAchorRound } from 'src/components/linkanchorround/LinkAchorRound';
import { RealTinyMceEditor } from 'src/components/tinyeditor/TinyMceEditor';

const languages = getLanguages();
const appFxs = getFxs();



export default function MailingListNewPage() {
  document.title = `${languages.composeNewMail} | ${languages.management} - ${languages.schoolName}`;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    mailSubject: '',
    mailContent: '',
  });

  const oContentChanged = (e:string) => {
    setFormData({...formData, mailContent: e});
  }

  const sendMail = async () => {
    const bSubj = formData.mailSubject.length === 0;
    const bContent = appFxs.getTextFromHtml(formData.mailContent).length === 0;

    if(bSubj || bContent){
      appFxs.showAlert(languages.requiredFields, languages.requiredFieldsMsg);
      return;
    }

    const bAsk = await appFxs.showConfirm(languages.send, languages.sendMailListQst);
    if(!bAsk.isConfirmed)return;

    const oper = await NetServices.requestPost('managt/sendmailingmail',{
      mailSubject: formData.mailSubject,
      mailContent: formData.mailContent,
    }, true);

    if(!oper.bReturn){
      appFxs.showAlert(oper.msgTitle, oper.msgBody);
      return;
    }




    navigate('/app/main/mailinglist');
  }


  return (
    <div className="flex flex-col gap-3">
      <Card>
        <div className="flex items-center">
          <div className="grow">
            <div className="flex">
              <h3 className="flex-1 text-lg font-bold text-gray-800">
                {languages.mailingList}
              </h3>
              <div className="flex gap-1">
                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={() => navigate("/app/main/mailinglist")}
                  title={languages.composeNewMail}
                >
                  <i className="fa fa-list"></i>
                </button>
                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={() => sendMail()}
                  title={languages.send}
                >
                  <i className="fa fa-send"></i>
                </button>
              </div>
            </div>
            <div className="mt-1 text-zinc-700 dark:text-gray-400">
              {languages.composeNewMailMsg}
            </div>
          </div>
          <div className="grow-0 flex items-center gap-2"></div>
        </div>
      </Card>
      <Card>
        <div className="flex flex-col">
          <div className="w-full mb-2">
            <label className="form-control block pr-4">
              <div className="label">
                <span className="label-text">{languages.subject}</span>
              </div>
              <input
                type="text"
                placeholder={languages.typeUrSubject}
                className="input input-bordered w-full ring-1"
                name="messageSubject"
                value={formData.mailSubject}
                onChange={(e) =>
                  setFormData({ ...formData, mailSubject: e.target.value })
                }
              />
            </label>
          </div>
          <div className="flex flex-col gap-1">
              <RealTinyMceEditor
                getContent={oContentChanged}
                hasToolbar={true} />
            </div>
            <div className="w-full mt-4">
              <LinkAchorRound
                text={languages.save}
                type="button"
                fullWidth={true}
                borderRadius={18}
                onClick={() => sendMail()}
              />
            </div>
        </div>
      </Card>
    </div>
  );
}
