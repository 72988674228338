/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";




import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";

import LoadingWaitSpace from "src/components/LoadingWaitSpace";
import Card from "src/components/Card";
import { IManagtUser } from "src/models/smarttypes";
import NetServices from "src/utils/netservices";
import { LinkAchorRound } from "src/components/linkanchorround/LinkAchorRound";

import imgLogo from 'src/assets/images/alumniimg.png'


const languages = getLanguages();
const appFxs = getFxs();

type UserAlumniError = {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  phoneNbr?: string;
  sex?: string;
  yearCompleted?: string;
  completedDegree?: string;
  addressLine?: string;
}

const MyProfilePage = () => {
  const [userNameTitle, setUserNameTitle] = useState(`${languages.loading}...`);
  const [isLoading, setLoading] = useState(true);  
  const [connectedUser, setConnectedUser] = useState<IManagtUser|null>(null);
  const [formErrors, setFormErrors] = useState<UserAlumniError>({});

  const [imageFile, setImageFile] = useState<File|null>(null);
  const [doctImage, setDoctImage] = useState<string>('');
  const [imagePath, setImagePath] = useState<string>(imgLogo);

  const fileSelect = useRef<HTMLInputElement>(null)
  
  const bLoaded = useRef(false);
  const navigate = useNavigate();
  
  
  document.title = `${userNameTitle} | ${languages.management} - ${languages.schoolName}`;

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files![0]!;
    const reader = new FileReader();
    setImageFile(file);

    reader.onload = () => {
      setDoctImage(reader.result as string);
    };

    reader.readAsDataURL(file);
  };

  const prepareProfileData = async () => {
    const oOper = await NetServices.requestGet(`managt/getuserconnected`)
    if(!oOper.bReturn){
      await appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      navigate('/app/main/dashboard')
      return;
    }

    const oUser = oOper.connectedUser!;
    const imgP = `${appDecl.apiUrlTest}/cloud_data/managt_profiles/${oUser.profilePicturePath}`;
    if(oUser.profilePicturePath){
      setImagePath(imgP);
    }

    setConnectedUser(oUser);
    setUserNameTitle(`${oUser.firstName} ${oUser.lastName}`)
    setLoading(false);
  }
  const saveProfileAcct = async () => {
    const errors: UserAlumniError = {};
    const user = { ...connectedUser };

    if (!user.firstName || user.firstName.length === 0) {
      errors.firstName = languages.fldRequired;
    }
    if (!user.lastName || user.lastName.length === 0) {
      errors.lastName = languages.fldRequired;
    }
    if (!user.phoneNbr || user.phoneNbr.length === 0) {
      errors.phoneNbr = languages.fldRequired;
    } else if (!appFxs.isTelephoneNbrValid(user.phoneNbr)) {
      errors.phoneNbr = languages.phonenbrLengthMsg;
    }
    
    if (!user.addressLine || user.addressLine.length === 0) {
      errors.addressLine = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    const routePath = !imageFile
      ? "managt/saveprofiledatanofile"
      : "managt/saveprofiledatawithfile";
    const oDataform = new FormData();

    const otherFields = {
      firstName: connectedUser?.firstName,
      lastName: connectedUser?.lastName,
      dateOfBirth: connectedUser?.dateOfBirth,
      sex: connectedUser?.sex,
      phoneNbr: connectedUser?.phoneNbr,
      emailAddress: connectedUser?.emailAddress,
      addressLine: connectedUser?.addressLine,
      password: "",
    };
    const profileData = JSON.stringify(otherFields);

    oDataform.append("profileData", profileData);
    if (imageFile) {
      oDataform.append("managtProfile", imageFile);
    }

    const saveOper = await NetServices.requestPostWithFileAxios(
      routePath,
      oDataform,
      true
    );
    
    if (!saveOper.bReturn) {
      appFxs.showAlert(saveOper.msgTitle, saveOper.msgBody);
      return;
    }

    appFxs.setLocalStorageByKey(appDecl.connectedUser, JSON.stringify(saveOper.connectedUser!))
    setConnectedUser(saveOper.connectedUser!);


    document.location.reload();
  };
  const onChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;

    setConnectedUser({...connectedUser!, [name]: name === 'dateOfBirth' ? new Date(value): value})
  }
  const refuseString = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    return appFxs.strictlyNbr(evt);
  }
  const removeImageFile = () => {
    setImageFile(null);
  }

  useEffect(() => {
    if(bLoaded.current)return;
    prepareProfileData();
  }, [])

  if(isLoading) return <LoadingWaitSpace />  
  return (
    <div className="flex flex-col gap-3">
      <Card>
        <div className="flex items-center">
          <div className="grow">
            <div className="flex">
              <h3 className="flex-1 text-lg font-bold text-gray-800">
                {languages.myProfile}
              </h3>
              <div className="flex gap-1">
                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={saveProfileAcct}
                >
                  <i className="fa fa-save"></i>
                </button>
              </div>
            </div>
            <p className="mt-1 text-gray-800 dark:text-gray-400">
              {languages.myProfileMsg}
            </p>
          </div>
          <div className="grow-0 flex items-center gap-2"></div>
        </div>
      </Card>

      <Card>
        <div className="flex flex-col">
          <div className="flex flex-wrap justify-between">
            <div className="w-full grid place-content-center py-3">
              <div className="aspect-video border border-solid rounded-lg h-[200px] w-[150px] p-1 relative">
                {imageFile && <span className="absolute right-1 top-1 border border-solid p-1 cursor-pointer w-5 h-5 flex items-center justify-center rounded bg-blue-500 text-white"
                onClick={removeImageFile}>
                  <i className="fa fa-times"></i>
                </span>}
              {imageFile && 
                <img src={doctImage} 
                  className="w-full h-full object-cover"
                  alt=""
                  />
              }
              {!imageFile && 
                <img src={imagePath} 
                  className="w-full h-full object-cover"
                  alt="" 
                  onClick={() => {
                    fileSelect?.current?.click();
                  }}
                />
              }
              </div>
                <input type="file"
                  className="hidden"
                  ref={fileSelect}
                  onChange={onImageChange}
                  accept="image/*" />
            </div>


            {/* firstName */}
            <label className="form-control basis-full md:basis-[49%]">
              <div className="label">
                <span className="label-text">{languages.firstName}</span>
              </div>
              <input
                type="text"
                placeholder={languages.firstName.toLowerCase()}
                className="input input-bordered w-full border border-solid"
                value={connectedUser?.firstName}
                name="firstName"
                id="firstName"
                onChange={onChangeField}
              />
              <div className={`label text-red-500 ${formErrors.firstName && 'hidden'}`}>
                <span className="label-text-alt">{formErrors.firstName}</span>
              </div>
            </label>

            {/* lastName */}
            <label className="form-control basis-full md:basis-[49%]">
              <div className="label">
                <span className="label-text">{languages.lastName}</span>
              </div>
              <input
                type="text"
                placeholder={languages.lastName.toLowerCase()}
                className="input input-bordered w-full border border-solid"
                value={connectedUser?.lastName}
                name="lastName"
                id="lastName"
                onChange={onChangeField}
              />
              <div className={`label text-red-500 ${formErrors.lastName && 'hidden'}`}>
                <span className="label-text-alt">{formErrors.lastName}</span>
              </div>
            </label>

            {/* birthDate */}
            <label className="form-control basis-full md:basis-[49%]">
              <div className="label">
                <span className="label-text">{languages.birthDate}</span>
              </div>
              <input
                type="date"
                placeholder={languages.lastName.toLowerCase()}
                className="input input-bordered w-full border border-solid"
                value={moment(connectedUser?.dateOfBirth).format('YYYY-MM-DD')}
                name="dateOfBirth"
                id="dateOfBirth"
                onChange={onChangeField}
              />
              <div className={`label text-red-500 ${formErrors.dateOfBirth && 'hidden'}`}>
                <span className="label-text-alt">{formErrors.dateOfBirth}</span>
              </div>
            </label>

            {/* phoneNumber */}
            <label className="form-control basis-full md:basis-[49%]">
              <div className="label">
                <span className="label-text">{languages.phoneNbr}</span>
              </div>
              <input
                type="text"
                placeholder={languages.phoneNbr.toLowerCase()}
                className="input input-bordered w-full border border-solid"
                value={connectedUser?.phoneNbr}
                name="phoneNbr"
                id="phoneNbr"
                onChange={onChangeField}
                maxLength={12}
                onKeyPress={refuseString}
              />
              <div className={`label text-red-500 ${formErrors.phoneNbr && 'hidden'}`}>
                <span className="label-text-alt">{formErrors.phoneNbr}</span>
              </div>
            </label>
            {/* sex */}
            <label className="form-control basis-full md:basis-[49%]">
              <div className="label">
                <span className="label-text">{languages.sex}</span>
              </div>
              <select className="select select-bordered w-full border border-solid"
                name="sex"
                value={connectedUser?.sex}
                onChange={e => setConnectedUser({...connectedUser!, sex: e.target.value})}>
                <option value="0">{languages.male}</option>
                <option value="1">{languages.female}</option>
              </select>
              <div className={`label text-red-500 ${formErrors.sex && 'hidden'}`}>
                <span className="label-text-alt">{formErrors.sex}</span>
              </div>
            </label>

            {/* addressLine */}
            <label className="form-control basis-full">
              <div className="label">
                <span className="label-text">{languages.address}</span>
              </div>
              <textarea className="textarea border border-solid border-slate-500 no-resize" 
                placeholder={languages.address.toLowerCase()}
                value={connectedUser?.addressLine}
                name="addressLine"
                id="addressLine"
                onChange={e => setConnectedUser({...connectedUser!, addressLine: e.target.value})}></textarea>
              <div className={`label text-red-500 ${formErrors.sex && 'hidden'}`}>
                <span className="label-text-alt">{formErrors.sex}</span>
              </div>
            </label>



          </div>
          <LinkAchorRound type="button" 
            text={languages.save}
            bgColor="#0b5b13"
            onClick={saveProfileAcct} />
          
          {/* <div className="mt-5">
            <AlumniMembersComp />
          </div> */}
        </div>
      </Card>
    </div>
  );
}

export default MyProfilePage