import React, { useState } from 'react'

import WiseModal from '../modalportal/WiseModal';

import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";
import { IManagtUser, IManagtUserError } from 'src/models/smarttypes';
import NetServices from 'src/utils/netservices';


const languages = getLanguages();
const appFxs = getFxs();



type ManagtUserFormProps = {
    isOpen: boolean;
    onClose: (lst?: IManagtUser[]) => void;
    dialogWidth?: number;
    currentRecord: IManagtUser;
    recordStatus: number;
    isMainAdmin: boolean;
    dialogTitle: string;
}
type ManagtUserFormWrapperProps = {
    currentRecord: IManagtUser;
    isMainAdmin: boolean;
    recordStatus: number;
    onClose: (lst?: IManagtUser[]) => void;
}

export default function ManagtUserForm({
  isOpen,
  onClose,
  dialogWidth,
  currentRecord,
  isMainAdmin,
  recordStatus,
  dialogTitle,
}: ManagtUserFormProps) {
  if (!isOpen) return null;
  return (
    <WiseModal
      isOpen={isOpen}
      onClose={() => onClose()}
      titleBox={dialogTitle}
      width={dialogWidth ?? 450}
      hasFooter={false}
    >
      <ManagtUserFormWrapper 
        currentRecord={currentRecord}
        isMainAdmin={isMainAdmin}
        recordStatus={recordStatus}
        onClose={(lst) => onClose(lst)} />
    </WiseModal>
  );
}

const ManagtUserFormWrapper = ({isMainAdmin, recordStatus, onClose, currentRecord}: ManagtUserFormWrapperProps) => {
    const [formData, setFormData] = useState<IManagtUser>({...currentRecord});
    const [formError, setFormError] = useState<IManagtUserError>({});

    const onChangeFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const deleteUserRecord = async () => {
      const bAsk = await appFxs.showConfirm(
        languages.delete,
        languages.deleteRecordQst
      );
      if (!bAsk.isConfirmed) return;
      const oper = await NetServices.requestPost(
        "managt/setuserdeleted",
        {
          userDeleteEmail: currentRecord.emailAddress,
        },
        true
      );

      if (!oper.bReturn) {
        appFxs.showAlert(oper.msgTitle, oper.msgBody);
        return;
      }

      onClose(oper.listManagtUsers!);
    }
    const saveRecordToDB = () => {
        const errors: IManagtUserError = {};
        if (!formData.firstName || formData.firstName.length === 0) {
          errors.firstName = languages.fldRequired;
        }
        if (!formData.phoneNbr || formData.phoneNbr.length === 0) {
          errors.phoneNbr = languages.fldRequired;
        }else if(formData.phoneNbr.length !== 12){
            errors.phoneNbr = languages.phonenbrLengthMsg;
        }
        if (!formData.lastName || formData.lastName.length === 0) {
          errors.lastName = languages.fldRequired;
        }
        if (!formData.emailAddress || formData.emailAddress.length === 0) {
          errors.emailAddress = languages.fldRequired;
        }else if (!appFxs.isEmailValid(formData.emailAddress)){
            errors.emailAddress = languages.emailFormat;
        }

        setFormError(errors);

        if(Object.keys(errors).length>0)return;

        onSaveRecord();
    }

    const onSaveRecord = async () => {
        const oper = await NetServices.requestPost('managt/sendinvitetousers', {
            recordUser: formData,
            recordStatus: recordStatus,
        }, true);

        if(!oper.bReturn){
            appFxs.showAlert(oper.msgTitle, oper.msgBody);
            return;
        }

        onClose(oper.listManagtUsers!);
    }
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        appFxs.strictlyNbr(e)
    }



    return (
      <div className="flex flex-col">
        <div className="py-4 flex flex-col max-h-[400px] overflow-y-auto px-2">
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">{languages.email}</span>
            </div>
            <input
              type="email"
              placeholder={languages.email.toLowerCase()}
              className="input input-bordered w-full ring-1"
              value={formData.emailAddress}
              name="emailAddress"
              onChange={onChangeFormData}
              disabled={recordStatus !== 0}
            />
            {formError.emailAddress && (
              <div className="label">
                <span className="label-text-alt text-red-500">
                  {formError.emailAddress}
                </span>
              </div>
            )}
          </label>

          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">{languages.firstName}</span>
            </div>
            <input
              type="text"
              placeholder={languages.firstName.toLowerCase()}
              className="input input-bordered w-full ring-1"
              value={formData.firstName}
              name="firstName"
              onChange={onChangeFormData}
            />
            {formError.firstName && (
              <div className="label">
                <span className="label-text-alt text-red-500">
                  {formError.firstName}
                </span>
              </div>
            )}
          </label>

          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">{languages.lastName}</span>
            </div>
            <input
              type="text"
              placeholder={languages.lastName.toLowerCase()}
              className="input input-bordered w-full ring-1"
              value={formData.lastName}
              name="lastName"
              onChange={onChangeFormData}
            />
            {formError.lastName && (
              <div className="label">
                <span className="label-text-alt text-red-500">
                  {formError.lastName}
                </span>
              </div>
            )}
          </label>

          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">{languages.phoneNbr}</span>
            </div>
            <input
              type="text"
              placeholder={languages.phoneNbr.toLowerCase()}
              className="input input-bordered w-full ring-1"
              value={formData.phoneNbr}
              name="phoneNbr"
              onChange={onChangeFormData}
              maxLength={12}
              onKeyPress={handleKeyPress}
            />
            {formError.phoneNbr && (
              <div className="label">
                <span className="label-text-alt text-red-500">
                  {formError.phoneNbr}
                </span>
              </div>
            )}
          </label>

          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">{languages.sex}</span>
            </div>
            <select
              className="ring-1 select select-bordered w-full"
              name="sex"
              value={formData.sex}
              onChange={(e) =>
                setFormData({ ...formData, sex: e.target.value })
              }
            >
              <option value="0">{languages.male}</option>
              <option value="1">{languages.female}</option>
            </select>
          </label>

          {/* Is Admin */}
          {isMainAdmin && !formData.mainAdmin && (
            <div className="flex items-center gap-2 w-full py-3">
              <input
                type="checkbox"
                className="toggle border border-solid"
                id="admin"
                checked={formData.admin}
                onChange={() =>
                  setFormData({ ...formData, admin: !formData.admin })
                }
              />
              <label htmlFor="admin" className="text-black cursor-pointer">
                {languages.administrator}
              </label>
            </div>
          )}

          {/* Is Activated */}
          {(isMainAdmin && !formData.mainAdmin && recordStatus !== 0) && (
            <div className="flex items-center gap-2 w-full py-3">
              <input
                type="checkbox"
                className="toggle border border-solid"
                id="userActive"
                checked={formData.userActive}
                onChange={() =>
                  setFormData({ ...formData, userActive: !formData.userActive })
                }
              />
              <label htmlFor="admin" className="text-black cursor-pointer">
                {languages.activated}
              </label>
            </div>
          )}
        </div>

        <div className="modal-action">
          <div className="w-full flex justify-between">
            {isMainAdmin && recordStatus !== 0 ? (
              <div>
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={deleteUserRecord}
                >
                  {languages.delete}
                </button>
              </div>
            ) : (
              <span></span>
            )}
            <div className="flex gap-2 items-center">
              <button className="btn" onClick={() => onClose()}>
                {languages.close}
              </button>
              {(recordStatus === 0 || isMainAdmin) && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveRecordToDB}
                >
                  {languages.save}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
}