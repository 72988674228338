/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';


import Card from 'src/components/Card';
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";

import { IMailingList} from 'src/models/smarttypes';
import NetServices from 'src/utils/netservices';

import LoadingWaitSpace from 'src/components/LoadingWaitSpace';

const languages = getLanguages();
const appFxs = getFxs();


export default function MailingListPreviewPage() {
    const [messageTitle, setMessageTitle] = useState(`${languages.loading}...`);
    const [isLoading, setLoading] = useState(true);
    const [oneMailingMail, setOneMailingMail] = useState<IMailingList | null>();
    
    const {mailKey}=useParams();
    
    const bLoaded = useRef(false);
    const navigate = useNavigate();

    document.title = `${messageTitle} | ${languages.management} - ${languages.schoolName}`;


    const populateData = async () => {
        setLoading(true);

        const oper = await NetServices.requestGet(`managt/getonemailselected/${window.btoa(mailKey!)}`, false);
        if(!oper.bReturn){
            await appFxs.showAlert(oper.msgTitle, oper.msgBody);
            navigate('/app/main/mailinglist');
            return;
        }
        setMessageTitle(oper.oneMailingMail!.mailSubject);
        setOneMailingMail(oper.oneMailingMail!);
        setLoading(false);
    }


    useEffect(() => {
        if (bLoaded.current) return;
        populateData();
        bLoaded.current = true;
      }, []);


  return (
    <div className="flex flex-col gap-3">
      <Card>
        <div className="flex items-center">
          <div className="grow">
            <div className="flex">
              <h3 className="flex-1 text-lg font-bold text-gray-800">
                {languages.previewMessage}
              </h3>
              <div className="flex gap-1">
                <button
                  type="button"
                  className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
                  onClick={() => populateData()}
                >
                  <i className="fa fa-history"></i>
                </button>
              </div>
            </div>
            <div className="mt-1 text-zinc-700 dark:text-gray-400">
              {languages.previewMessageMsg}
            </div>
          </div>
          <div className="grow-0 flex items-center gap-2"></div>
        </div>
      </Card>

      <Card>
        <div className="flex flex-col">
        {isLoading && <LoadingWaitSpace height={150} />}
        {!isLoading && 
            <div className='flex flex-col'>
                <h3 className='py-2 px-1 mb-2 font-semibold text-lg'>{oneMailingMail?.mailSubject}</h3>
                <div className='p-3 mailing-space' dangerouslySetInnerHTML={{__html: oneMailingMail!.mailContent}}></div>
            </div>
        }
        </div>
      </Card>
    </div>
  );
}
