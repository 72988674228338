import { IBlogRecord, IManagtUser } from "src/models/smarttypes";

import blogImg1 from 'src/assets/images/blog/blog1.jpg';

export const defaultManagtUser:IManagtUser = {
    firstName: 'Colombière',
    lastName: 'School',
    dateOfBirth: new Date(),
    sex: '0',
    phoneNbr: '250788302525',
    emailAddress: '',
    addressLine: '',
    password: '',
    userActive: true,
    admin: false,
    mainAdmin: false,
    profilePicturePath: '',
}

export const listOfBlogs: IBlogRecord[] = [
    {
        post: {
            postKey: '215454jhjhjhj787ftf9887',
            publisherEmail: 'jkayihura@msn.com',
            postDate: new Date(),
            postSubject: 'Shoes and Database',
            postTitle: 'Bringing back shoes from shop',
            postContent:
            `
                Wikipédia est un projet d'encyclopédie collective en ligne, universelle, multilingue et fonctionnant sur le principe du wiki. Ce projet vise à offrir un contenu librement réutilisable, objectif et vérifiable, que chacun peut modifier et améliorer.
                Wikipédia est définie par des principes fondateurs. Son contenu est sous licence Creative Commons BY-SA. Il peut être copié et réutilisé sous la même licence, sous réserve d'en respecter les conditions. Wikipédia fournit tous ses contenus gratuitement, sans publicité, et sans recourir à l'exploitation des données personnelles de ses utilisateurs.
            `,
            featuredImage: blogImg1,
            tags: [],
            published: true,
            featuredImageType: 'file',
            postLang: 'en',
        },
        comments: []
    },
    {
        post: {
            postKey: '215454fdgdf97987sdf2121',
            publisherEmail: 'jkayihura@msn.com',
            postDate: new Date(),
            postSubject: 'Redacteurs et Directeurs',
            postTitle: 'Les rédacteurs des articles de Wikipédia sont bénévoles',
            postContent:
            `
                Wikipédia est un projet d'encyclopédie collective en ligne, universelle, multilingue et fonctionnant sur le principe du wiki. Ce projet vise à offrir un contenu librement réutilisable, objectif et vérifiable, que chacun peut modifier et améliorer.
                Wikipédia est définie par des principes fondateurs. Son contenu est sous licence Creative Commons BY-SA. Il peut être copié et réutilisé sous la même licence, sous réserve d'en respecter les conditions. Wikipédia fournit tous ses contenus gratuitement, sans publicité, et sans recourir à l'exploitation des données personnelles de ses utilisateurs.
            `,
            featuredImage: blogImg1,
            tags: [],
            published: true,
            featuredImageType: 'file',
            postLang: 'en',
        },
        comments: []
    }
]