import { Link, Outlet, useNavigate } from "react-router-dom";

import {
  ImAddressBook, 
  ImDelicious, 
  ImMail2, 
  ImExit, 
  ImCog, 
  ImUser,
  ImUsers,
  ImDrawer,
  ImMagicWand,
  ImCart
} from "react-icons/im";

import appLanguages from 'src/utils/language';
import generalFxs from 'src/utils/general_fx';
import appDecl from 'src/utils/declarations';

import alumniImg from 'src/assets/images/alumniimg.png';
import colmobImg from 'src/assets/images/colombiere_img_transparent.png';
import { useEffect, useState } from "react";
import { IManagtUser, ISelectOptionType } from "src/models/smarttypes";
import { defaultManagtUser } from "src/utils/general_data";
import WiseDropDown from "src/components/wsedropdown/WiseDropDown";

const languages = appLanguages();
const appFxs = generalFxs();

const menuTypes:ISelectOptionType[] = [
  {id: '0', label: languages.dashboard},
  {id: '1', label: languages.blog},
  {id: '2', label: languages.socialevents},
  {id: '3', label: languages.messages},
  {id: '4', label: languages.calendar},
  {id: '5', label: languages.settings},
];

const AppMainLayout = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [connectedUser, setConnectedUser] = useState<IManagtUser>(defaultManagtUser);
    const [profilePic, setProfilePic] = useState<string | null>(null);

    const navigate = useNavigate();

    const onLogOff = async () => {
        const bAsk = await appFxs.showConfirm(languages.signOut, languages.signOutMsg);
        if(!bAsk.isConfirmed)return;

        appFxs.setLocalStorageByKey(appDecl.connectionStatus, '0');
        appFxs.setLocalStorageByKey(appDecl.userAccessKey, '');
        appFxs.setLocalStorageByKey(appDecl.connectedUser, '');

        navigate('/')
    }
    const onSelectMenu = (e: ISelectOptionType) => {
      switch (e.id) {
        case "0":
          navigate("/app/main/dashboard");
          break;
        case "1":
          navigate("/app/main/blog");
          break;
        case "2":
          navigate("/app/main/socialevents");
          break;
        case "3":
          navigate("/app/main/messages");
          break;
        case "4":
          navigate("/app/main/events");
          break;
        case "5":
          navigate("/app/main/appsettings");
          break;

        default:
          break;
      }
    }

    useEffect(() => {
        const f = appFxs.getLocalStorageByKey(appDecl.connectedUser, '');
        if(!f) {
            setConnectedUser(defaultManagtUser);
            setProfilePic(null);
        }else{
            const userF = JSON.parse(f) as IManagtUser;
            setConnectedUser(userF);
            if(userF.profilePicturePath){
                setProfilePic(`${appDecl.apiPath}/cloud_data/managt_profiles/${userF.profilePicturePath!}`)
            }
        }
        setIsLoading(false);
    }, [])

  return (
    <div className="flex flex-col">
      <div className="main-window">
        <nav className="flex w-full items-center bg-white border-b border-emerald-950 shadow-lg fixed top-0 left-0 right-0 z-20">
          <div className="max-w-screen-xl flex items-center justify-between flex-wrap p-4 w-full mx-auto">
            <Link className="flex items-center flex-shrink-0 mr-6" to={"/"}>
              <img src={alumniImg} alt="" className="w-8 h-8 mr-2" />
              <span className="text-[#0b5b13] font-semibold text-xl tracking-tight">
                Management
              </span>
            </Link>
            <div className="hidden md:block">
              <a
                href="https://lacolombiere.ac.rw"
                className="w-fit flex flex-col items-center"
                target="_blank"
                rel="noreferrer"
              >
                <img src={colmobImg} className="w-8 h-8 mr-2" alt="logo" />
                <span className="inline-block">{languages.schoolNameOnly}</span>
              </a>
            </div>
            <div className="flex gap-2 items-center">
              <Link to={`/app/main/myprofile`} className="flex items-center space-x-2">
                <img
                  src={profilePic ?? alumniImg}
                  className="inline-block w-6 h-6 rounded-full border border-yellow-950 object-cover object-top"
                  alt="Profile"
                />
                <p>{isLoading ? `${languages.loading}...` : `${connectedUser.firstName} ${connectedUser.lastName}`}</p>
              </Link>
              <button type="button" 
                className="px-3 py-[10px] bg-slate-500 text-white rounded-md flex items-center hover:bg-slate-700"
                onClick={onLogOff}>
                <ImExit />
              </button>
            </div>
          </div>
        </nav>
        <div className="mt-[88px] flex">
          <div className="hidden w-[252px] bg-white border border-r-gray-300 md:flex md:flex-col">
            <div className="border border-b-gray-300">
                <Link to={'/'} className="w-full grow-0">
                    <div className="w-full flex items-center justify-center py-8">
                        <img className="h-20 inline-block" alt="" src={alumniImg} />
                    </div>
                </Link>
            </div>
            <div className="grow bg-white px-6 py-4">
                <Link to={'/app/main/dashboard'} className="flex items-center px-2 py-3 rounded-md  hover:text-lime-500 hover:bg-gray-50 text-gray-500">
                    <ImDelicious />
                    <span className="ml-2">{languages.dashboard}</span>
                </Link>
                <Link to={'/app/main/blog'} className="flex items-center px-2 py-3 rounded-md  hover:text-lime-500 hover:bg-gray-50 text-gray-500">
                    <ImAddressBook />
                    <span className="ml-2">{languages.blog}</span>
                </Link>
                <Link to={'/app/main/alumni'} className="flex items-center px-2 py-3 rounded-md  hover:text-lime-500 hover:bg-gray-50 text-gray-500">
                    <ImUsers />
                    <span className="ml-2">{languages.alumni}</span>
                </Link>
                <Link to={'/app/main/mailinglist'} className="flex items-center px-2 py-3 rounded-md  hover:text-lime-500 hover:bg-gray-50 text-gray-500">
                    <ImMail2 />
                    <span className="ml-2">{languages.mailingListShort}</span>
                </Link>
                <Link to={'/app/main/contactmessages'} className="flex items-center px-2 py-3 rounded-md  hover:text-lime-500 hover:bg-gray-50 text-gray-500">
                    <ImDrawer />
                    <span className="ml-2">{languages.messages}</span>
                </Link>
                <Link to={'/app/main/products'} className="flex items-center px-2 py-3 rounded-md  hover:text-lime-500 hover:bg-gray-50 text-gray-500">
                    <ImMagicWand />
                    <span className="ml-2">{languages.products}</span>
                </Link>
                <Link to={'/app/main/shopopers'} className="flex items-center px-2 py-3 rounded-md  hover:text-lime-500 hover:bg-gray-50 text-gray-500">
                    <ImCart />
                    <span className="ml-2">{languages.sales_ops}</span>
                </Link>
                <Link to={'/app/main/appsettings'} className="flex items-center px-2 py-3 rounded-md  hover:text-lime-500 hover:bg-gray-50 text-gray-500">
                    <ImCog />
                    <span className="ml-2">{languages.settings}</span>
                </Link>
                {connectedUser?.mainAdmin &&
                    <Link to={'/app/main/users'} className="flex items-center px-2 py-3 rounded-md  hover:text-lime-500 hover:bg-gray-50 text-gray-500">
                        <ImUser />
                        <span className="ml-2">{languages.users}</span>
                    </Link>
                }
            </div>
            <div className="w-full grow-0 py-3 flex items-center justify-center ">
                <button type="button"
                    className="flex items-center px-2 py-3 rounded-md font-semibold hover:text-white bg-gray-300 hover:bg-gray-400"
                    onClick={onLogOff}>
                    <ImExit />
                </button>
            </div>
          </div>
          <div className="grow-1 w-full bg-slate-300 text-gray-700 hover:text-green-800">
            <div className="max-w-4xl w-full  mx-auto md:py-6 flex flex-col">
                <div className="flex justify-end py-3 md:hidden mr-3">
                    <WiseDropDown 
                      position="right" 
                      hasDropdown={false} 
                      options={menuTypes}
                      onSelected={onSelectMenu}>
                        <div className="flex items-center gap-2">
                            <i className="fa fa-list-alt text-blue-600"></i>
                            <span className="text-blue-600">{languages.menu}</span>
                        </div>
                    </WiseDropDown>
                </div>
                <div className="grow-1 p-4 md:p-0">
                    <div className="flex flex-col gap-3 px-2">
                        {/* Content */}
                        <Outlet />
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default AppMainLayout